import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HiComputerDesktop } from "react-icons/hi2";
import { useChangePlanModalContext } from "../ModalContext";

const CurrentPlan = () => {
  const { contract } = useChangePlanModalContext();
  const iconBoxColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Stack>
      <Flex direction={{ base: "column", sm: "row" }}>
        <Stack mr="auto">
          <HStack>
            <Heading size="md">
              Plano Atual - {contract?.selectedPeriodicity}
            </Heading>
            {contract?.periodicity === "yearly" &&
              contract.orders.length > 1 && (
                <Badge rounded="full" p="2px 5px" color="gray.600">
                  Parcelado em {contract.installmentsNumber} vezes
                </Badge>
              )}

            {contract?.status === 0 && (
              <Badge rounded="full" p="2px 5px" colorScheme="red">
                Contrato inativo
              </Badge>
            )}
          </HStack>
          <Text variant="label">
            Renovação dia {contract?.expiration_date?.format("DD/MM/YYYY")}
          </Text>
        </Stack>
      </Flex>

      {contract?.products
        .filter(({ plan }) =>
          [1, 2, 3, 4, 5, 6, 7, 8].includes(plan?.id as number)
        )
        .map(({ id, plan, priceString }) => (
          <HStack key={id}>
            <Box p="0.5rem" bg={iconBoxColor} rounded="5px">
              <HiComputerDesktop size="1.5rem" />
            </Box>

            <Text mr="auto" fontWeight="500">
              {plan?.name}
            </Text>
            <Text>{priceString}</Text>
          </HStack>
        ))}
    </Stack>
  );
};

export default CurrentPlan;
