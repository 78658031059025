import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFeatureHistoryContext } from "../FeatureHistoryContext";
import Template from "./Template";

interface FeatureHistoryModalProps {
  isOpen: boolean;
  onClose(): void;
}

const FeatureHistoryModal: React.FC<FeatureHistoryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { updates } = useFeatureHistoryContext();
  const bg = useColorModeValue("gray.50", "gray.600");

  const sortedUpdates = updates.sort(({ date: dateA }, { date: dateB }) =>
    dateA.isBefore(dateB) ? 1 : -1
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={bg} maxW={{ base: "90vw", md: "32em" }}>
        <ModalCloseButton />
        <ModalHeader>Atualizações</ModalHeader>
        <ModalBody pb="2rem" maxH="70vh" overflowY="scroll">
          <Stack gap="1rem">
            {sortedUpdates.map((update, idx) => (
              <Template key={idx} update={update} />
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FeatureHistoryModal;
