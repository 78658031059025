import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { FaCreditCard, FaFileInvoice } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import ProtectedButton from "shared/components/ProtectedButton";
import { useFinancialContext } from "../FinancialContext";

const PaymentMethod = () => {
  const iconBoxColor = useColorModeValue("gray.200", "gray.600");
  const {
    customer: { contract, hasMarvin },
    toggleMarvin,
    marvinLoading,
  } = useCustomerContext();

  const { openChangePaymentMethodModal } = useFinancialContext();

  return (
    <Card>
      <CardHeader>
        <Flex
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "1rem", sm: "5rem" }}
        >
          <Heading size="md" flex="1">
            Método de pagamento
          </Heading>

          <HStack>
            <ProtectedButton
              permission="customer-marvin"
              variant="filled"
              size="sm"
              py="1.5rem"
              bg="orange"
              isLoading={marvinLoading}
              onClick={toggleMarvin}
            >
              {hasMarvin ? "Marvin Ativo" : "Marvin Desativado"}
            </ProtectedButton>

            <ProtectedButton
              permission="customer-bank-update"
              leftIcon={<FiEdit />}
              variant="ghost"
              size="sm"
              py="1.5rem"
              onClick={openChangePaymentMethodModal}
            >
              Alterar
            </ProtectedButton>
          </HStack>
        </Flex>
      </CardHeader>
      <CardBody as={Stack}>
        <HStack>
          <Box p="0.5rem" bg={iconBoxColor} rounded="5px">
            {contract?.bankId === 1 ? (
              <FaCreditCard size="1.5rem" />
            ) : (
              <FaFileInvoice size="1.5rem" />
            )}
          </Box>

          <Stack gap="0" mr="auto" ml="0.5rem">
            <Text fontWeight="500">{contract?.paymentMethodName}</Text>
          </Stack>

          <Text>Vencimento dia {contract?.due_date?.format("DD")}</Text>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default PaymentMethod;
