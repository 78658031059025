import moment, { Moment } from "moment";
import { capitalizeFirstLetter } from "shared/helpers/string";
import { OrderInvoice } from "shared/types/orderInvoice";
import { ICustomer } from "./customer";

const nestCustomers = process.env.REACT_APP_NEST_CUSTOMERS;
const paymentCustomer = process.env.REACT_APP_CUSTOMER_PAYMENT;

export enum orderStatus {
  EM_ABERTO = 1,
  PAGO = 2,
  ACORDO = 3,
  CANCELADO = 4,
}

export interface IOrder {
  id?: number;
  bank_id: 1 | 2 | 3;
  invoices?: Array<OrderInvoice> | OrderInvoice;
  cycle?: number;
  complete_at?: string;
  codigo?: number;
  status_id?: orderStatus;
  token?: string;
  price?: number;
  due_date?: string;
  customer?: ICustomer;
  desc?: string;
}

export class Order {
  id?: number;
  bankId: 1 | 2 | 3;
  invoices: Array<OrderInvoice> = [];
  invoice?: OrderInvoice;
  cycle?: number;
  complete_at?: Moment;
  codigo: number;
  statusId?: orderStatus;
  token: string;
  price: number;
  due_date?: Moment;
  description?: string;

  constructor(data: IOrder) {
    this.id = data.id;
    this.bankId = data.bank_id;
    this.cycle = data.cycle;
    this.complete_at = moment(data.complete_at);
    this.codigo = data.codigo ?? 0;
    this.statusId = data.status_id;
    this.token = data.token ?? "";
    this.price = Number(data.price) ?? 0;
    this.due_date = moment(data.due_date);
    this.description = data.desc;

    if (Array.isArray(data.invoices))
      data.invoices.forEach((invoice) => this.invoices.push(invoice));
    else if (!!data.invoices) this.invoice = data.invoices;
  }

  get paymentLink(): string {
    if (!this.isInvoice) return `${paymentCustomer}contratacao/${this.token}`;
    return "";
  }

  get invoiceLink(): string {
    if (
      this.statusId === 1 &&
      (this.invoices.some((invoice) => invoice.is_active) ||
        this.invoice?.is_active)
    ) {
      return `${nestCustomers}v1/api/yooga/forecasts/invoices/${this.id}`;
    }
    return "";
  }

  get isInvoice(): boolean {
    return this.bankId === 2;
  }

  get canGenerateInvoice(): boolean {
    return (
      this.statusId === 1 &&
      this.isInvoice &&
      !!this.due_date &&
      this.due_date.diff(moment(), "days") < 10 &&
      !this.invoiceLink
    );
  }

  get dueDateMonth() {
    return capitalizeFirstLetter(this.due_date?.format("MMMM") as string);
  }

  get priceString() {
    return `R$ ${this.price.toFixed(2).replace(".", ",")}`;
  }
}
