import {
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
} from "@chakra-ui/react";
import { useOrderModalContext } from "../ModalContext";
import { FaCheck, FaXmark } from "react-icons/fa6";

const OrderLogHistory = () => {
  const { transactions, editing } = useOrderModalContext();

  return transactions.length && !editing ? (
    <Stack mt="2rem">
      <Heading size="md">Histórico de atividades</Heading>
      <TableContainer whiteSpace="break-spaces">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Tipo</Th>
              <Th>Quantidade</Th>
              <Th>Sucesso</Th>
              <Th>Mensagem</Th>
              <Th>Data</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((transaction) => (
              <Tr key={transaction.id}>
                <Td>{transaction.typeString}</Td>
                <Td>{transaction.priceString}</Td>
                <Td>
                  <Icon
                    as={transaction.success ? FaCheck : FaXmark}
                    mx="auto"
                    display="block"
                    color={transaction.success ? "yooga.600" : "red.500"}
                  />
                </Td>
                <Td>{transaction.message}</Td>
                <Td>{transaction.date.format("DD/MM/YYYY H:mm:ss")}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  ) : (
    <></>
  );
};

export default OrderLogHistory;
