import { validateCNPJ, validateCPF } from "shared/helpers/document";

export interface IErrors {
  cnpj?: string;
  businessModel?: string;
  password?: string;
  confirmPassword?: string;
}

const validateInstallationForm = ({ cnpj, businessModel, password, confirmPassword }: IErrors) => {
  const errors: IErrors = {};

  if (!cnpj) {
    errors.cnpj = "Documento é obrigatório";
  } else if (!validateCNPJ(cnpj) && !validateCPF(cnpj)) {
    errors.cnpj = "Insira um documento válido";
  }

  if (!businessModel) {
    errors.businessModel = "Escolha um modelo de negócio";
  }

  if (!password) {
    errors.password = "Digite uma senha";
  }

  if (password && password !== confirmPassword) {
    errors.confirmPassword = "As senhas não conferem";
  } else if (!confirmPassword) {
    errors.confirmPassword = "Confirme a senha";
  }

  return errors;
};

export default validateInstallationForm;
