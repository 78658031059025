import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import moment from "moment";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Mixpanel } from "services/mixpanel";
import { createOrder } from "services/order";
import { load } from "services/product";
import { R$ } from "shared/helpers/currency";
import { Plan } from "shared/models/plan";
import { Product } from "shared/models/product";

interface CreateOrderModalProps {
  isOpen: boolean;
  onClose(): void;
}

const CreateOrderModal: React.FC<CreateOrderModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { confirm } = useGlobalContext();
  const { customer, loadCustomer } = useCustomerContext();

  const [loading, setLoading] = useState(false);

  const [bankId, setBankId] = useState<1 | 2>(1);
  const [desc, setDesc] = useState("");
  const [dueDate, setDueDate] = useState(moment().format("YYYY-MM-DD"));
  const [price, setPrice] = useState("");
  const [installments, setInstallments] = useState<number>(1);

  const [services, setServices] = useState<Plan[]>([]);
  const [contractId, setContractId] = useState<number>(-1);
  const [serviceId, setServiceId] = useState<number>(-1);

  const priceNumber = useMemo(() => Number(price.replace(",", ".")), [price]);

  const selectedContract = useMemo(
    () => customer.contracts?.find((c) => c.id === contractId),
    [customer, contractId]
  );

  const filteredServices: Plan[] = useMemo(
    () =>
      services.filter(
        (service) => service.periodicity === selectedContract?.periodicity
      ),
    [selectedContract, services]
  );

  const setPriceState = (value: number | string) => {
    const v =
      typeof value === "string"
        ? Number(String(value).replace(/\D/g, "")) / 100
        : value;
    setPrice(v.toFixed(2).replace(".", ","));
  };

  const submit = () => {
    if (priceNumber <= 0 || serviceId < 0) return;

    confirm("Criar nova cobrança", "Deseja continuar?", () => {
      Mixpanel.track("customers-financial-new-order-submitted-form");
      setLoading(true);
      createOrder({
        customer_id: customer.id as number,
        contract_id: contractId as number,
        service_id: serviceId as number,
        bank_id: bankId,
        due_date: dueDate,
        price: priceNumber,
        installments:
          selectedContract?.periodicity === "yearly" ? installments : undefined,
        desc,
        status_id: 1,
      })
        .then(() => {
          toast.success("Cobrança criada com sucesso");
          loadCustomer();
          onClose();
        })
        .catch(() => toast.error("Falha ao criar cobrança"));
    });
  };

  useEffect(() => {
    setLoading(true);
    load()
      .then((response) => {
        const data = response.data.map((product) => new Product(product));
        if (data.length) setServices(data[0].plans);
        else throw Error("No products retrieved");
      })
      .catch(() => toast.error("Não foi possível carregar os planos"))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setServiceId(filteredServices[0]?.id ?? -1);
  }, [filteredServices]);

  useEffect(() => {
    setLoading(false);
    setBankId(1);
    setDesc("");
    setDueDate(moment().format("YYYY-MM-DD"));
    setPriceState(1);
    setInstallments(1);
    setContractId(customer.contract?.id ?? -1);
  }, [isOpen, customer]);

  return (
    <Drawer
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton isDisabled={loading} />
        <DrawerHeader>Nova Cobrança</DrawerHeader>

        <DrawerBody as={Stack} gap="1rem">
          <HStack gap="1rem">
            <FormControl>
              <FormLabel fontSize="0.8rem" color="gray.500">
                Contrato
              </FormLabel>
              <Select
                value={contractId}
                onChange={(e) => setContractId(Number(e.target.value))}
              >
                {customer.contracts?.map((contract) => (
                  <option key={contract.id} value={contract.id}>
                    {contract.periodicity === "monthly" ? "Mensal" : "Anual"}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="0.8rem" color="gray.500">
                Serviço
              </FormLabel>
              <Select
                value={serviceId}
                onChange={(e) => setServiceId(Number(e.target.value))}
              >
                <option disabled hidden value="-1">
                  Selecione um serviço
                </option>
                {filteredServices.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel fontSize="0.8rem" color="gray.500">
              Método de pagamento
            </FormLabel>
            <Select
              value={bankId}
              onChange={(e) => setBankId(Number(e.target.value) as 1 | 2)}
            >
              <option value={1}>Cartão de Crédito</option>
              <option value={2}>Boleto Bancário</option>
            </Select>
          </FormControl>

          <HStack gap="1rem" align="flex-start">
            <FormControl>
              <FormLabel fontSize="0.8rem" color="gray.500">
                Vencimento
              </FormLabel>
              <Input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </FormControl>

            <FormControl isInvalid={priceNumber <= 0}>
              <FormLabel fontSize="0.8rem" color="gray.500">
                Preço
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>R$</InputLeftAddon>
                <Input
                  value={price}
                  onChange={(e) => setPriceState(e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>O valor nao pode ser zero</FormErrorMessage>
            </FormControl>
          </HStack>

          {selectedContract?.periodicity === "yearly" && (
            <FormControl>
              <FormLabel fontSize="0.8rem" color="gray.500">
                Parcelas
              </FormLabel>
              <Select
                value={installments}
                onChange={(e) => setInstallments(Number(e.target.value))}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
                  <option key={n} value={n}>
                    {n} x {R$(Number(price.replace(",", ".")) / n)}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl>
            <FormLabel fontSize="0.8rem" color="gray.500">
              Descrição
            </FormLabel>
            <Textarea
              resize="none"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <Button
            isDisabled={serviceId < 0 || priceNumber <= 0}
            isLoading={loading}
            onClick={submit}
            colorScheme="yooga"
            w="100%"
          >
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateOrderModal;
