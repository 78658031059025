import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export type ConfirmModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose(): void;
  title?: string;
  content?: string | JSX.Element;
  buttonText?: string;
  onConfirm?(): void;
  showCloseButton?: boolean;
  confirmByText?: boolean;
}>;

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onConfirm,
  title = "Deseja continuar?",
  content,
  buttonText = "Confirmar",
  onClose,
  children,
  showCloseButton = true,
  confirmByText = false,
}) => {
  const [text, setText] = useState("");

  useEffect(() => {
    setText("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {content}
          {children}

          {confirmByText && (
            <Input
              mt="0.5rem"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Digite CONFIRMAR"
            />
          )}
        </ModalBody>

        <ModalFooter>
          {showCloseButton && (
            <Button variant="outlined" mr={3} onClick={onClose}>
              Fechar
            </Button>
          )}

          <Button
            variant="filled"
            onClick={onConfirm}
            isDisabled={confirmByText && text !== "CONFIRMAR"}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
