import { useCustomerContext } from "pages/Customer/CustomerContext";
import { Mixpanel } from "services/mixpanel";
import ClipboardBanner from "shared/components/ClipboardBanner";

const NoCardBanner = () => {
  const { customer } = useCustomerContext();

  const onCopy = () =>
    Mixpanel.track("customers-principal-clicked-copy-payment-link");

  return customer.contract &&
    customer.contract.isCreditCard &&
    !customer?.cards.length ? (
    <ClipboardBanner
      content="Cliente sem cartão cadastrado"
      subContent="Mande o link de pagamento para o cliente:"
      colorScheme="yellow"
      copyText={customer?.firstOrder?.paymentLink}
      successCopyMessage="Link copiado"
      onCopy={onCopy}
    />
  ) : (
    <></>
  );
};

export default NoCardBanner;
