import {
  Flex,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  TabPanel,
  Text,
  Icon,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { IoCopyOutline } from "react-icons/io5";
import { MdInfoOutline } from "react-icons/md";
import { Mixpanel } from "services/mixpanel";
import ProtectedButton from "shared/components/ProtectedButton";
import { copyToClipboard } from "shared/helpers/clipboard";
import { useDeliveryContext } from "../DeliveryContext";
import Address from "./Address";
import RangeTable from "./RangeTable";

const Info = () => {
  const { delivery, openEditModal } = useDeliveryContext();

  const openMenu = () => {
    Mixpanel.track("customers-app-delivery-clicked-menu");
    window.open(`https://${delivery?.url}`, "_blank");
  };

  const copyMenuLink = () => {
    Mixpanel.track("customers-app-delivery-clicked-copy-menu");
    copyToClipboard(delivery?.url!, "Link copiado");
  };
  const copyRedirectLink = () =>
    copyToClipboard(delivery?.redirect!, "Link copiado");

  return (
    <TabPanel as={Stack} divider={<StackDivider />} gap="0.5rem">
      <Stack>
        <HStack justify="space-between">
          <Stack>
            <Text variant="label">Endereço do delivery por:</Text>
            <Text fontSize="0.8rem" fontWeight="300">
              {delivery?.hasNeighborhood
                ? "Bairro"
                : delivery?.latitude
                ? "Raios"
                : delivery?.onlyWithdrawal
                ? "Somente retirada"
                : ""}
            </Text>
          </Stack>

          <ProtectedButton
            permission="installation-card-delivery-update"
            leftIcon={<FiEdit />}
            variant="ghost"
            size="sm"
            py="1.5rem"
            onClick={openEditModal}
          >
            Editar
          </ProtectedButton>
        </HStack>

        {!delivery?.onlyWithdrawal && <RangeTable />}
      </Stack>

      <Flex wrap="wrap" rowGap="1.5rem">
        <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
          <Text variant="label">Nome</Text>
          <Text fontSize="0.8rem" fontWeight="300">
            {delivery?.name || "-"}
          </Text>
        </Stack>

        <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
          <Text variant="label">Descrição</Text>
          <Text fontSize="0.8rem" fontWeight="300">
            {delivery?.description || "-"}
          </Text>
        </Stack>

        <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
          <HStack>
            <Text variant="label" mr="2rem">
              Link do cardápio digital
            </Text>

            {delivery?.url && (
              <Button
                variant="ghost"
                size="xs"
                colorScheme="yooga"
                leftIcon={<FaExternalLinkAlt />}
                onClick={openMenu}
              >
                Abrir cardápio
              </Button>
            )}
          </HStack>

          <HStack>
            <Text fontSize="0.8rem" fontWeight="300">
              {delivery?.url || "-"}
            </Text>
            {delivery?.url && (
              <IconButton
                aria-label="Copy menu link"
                size="sm"
                variant="link"
                onClick={copyMenuLink}
                icon={<IoCopyOutline />}
              />
            )}
          </HStack>
        </Stack>

        <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
          <HStack>
            <Text variant="label">Redirecionamento</Text>
            <Tooltip label="Link do cardápio no domínio do cliente">
              <HStack>
                <Icon aria-label="Info" as={MdInfoOutline} />
              </HStack>
            </Tooltip>
          </HStack>

          <HStack>
            <Text fontSize="0.8rem" fontWeight="300">
              {delivery?.redirect || "-"}
            </Text>
            {delivery?.url && (
              <IconButton
                aria-label="Copy redirect link"
                size="sm"
                variant="link"
                onClick={copyRedirectLink}
                icon={<IoCopyOutline />}
              />
            )}
          </HStack>
        </Stack>

        <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
          <HStack>
            <Text variant="label">Pixel do Facebook</Text>
            <Tooltip label="Info pixel do facebook">
              <HStack>
                <Icon aria-label="Info" as={MdInfoOutline} />
              </HStack>
            </Tooltip>
          </HStack>

          <Text fontSize="0.8rem" fontWeight="300">
            {delivery?.pixel || "-"}
          </Text>
        </Stack>

        <Stack flexBasis={{ base: "100%", md: "50%", lg: "33.33%" }}>
          <HStack>
            <Text variant="label">Token do Pagamento Online</Text>
            <Tooltip label="Info token do pagamento online">
              <HStack>
                <Icon aria-label="Info" as={MdInfoOutline} />
              </HStack>
            </Tooltip>
          </HStack>

          <Text fontSize="0.8rem" fontWeight="300">
            {delivery?.zoopSellerId || "-"}
          </Text>
        </Stack>
      </Flex>

      <Address />
    </TabPanel>
  );
};

export default Info;
