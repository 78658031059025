import { R$ } from "shared/helpers/currency";
import { Periodicity } from "shared/types/periodicity";

export interface IPlan {
  id?: number;
  name?: string;
  base_price?: string;
  periodicity?: Periodicity;
}

export class Plan implements IPlan {
  id?: number;
  name?: string;
  value?: number;
  periodicity?: Periodicity;

  constructor(data?: IPlan) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.value = Number(data.base_price);
      this.periodicity = data.periodicity;
    }
  }

  get selectedPeriodicity() {
    return this.periodicity === "monthly" ? "Mensal" : "Anual";
  }

  get priceString() {
    return R$(this.value);
  }
}
