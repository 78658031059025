import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { BsBoxSeam } from "react-icons/bs";
import Info from "./components/Info";
import OrderHistory from "./components/OrderHistory";
import { useDeliveryContext } from "./DeliveryContext";

const Delivery = () => {
  const { delivery } = useDeliveryContext();

  return (
    <Card>
      <CardHeader>
        <HStack align="center">
          <Icon as={BsBoxSeam} />
          <Heading size="sm">Delivery</Heading>
        </HStack>
      </CardHeader>

      <CardBody pt="0">
        {delivery ? (
          <Tabs>
            <TabList>
              <Tab>Detalhes</Tab>
              <Tab>Histórico de Pedidos</Tab>
            </TabList>

            <TabPanels>
              <Info />
              <OrderHistory />
            </TabPanels>
          </Tabs>
        ) : (
          <Text>
            A primeira configuração do delivery é feita dentro do APP.
          </Text>
        )}
      </CardBody>
    </Card>
  );
};

export default Delivery;
