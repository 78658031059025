import {
  CircularProgress,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";
import Confirmation from "./components/Confirmation";
import Services from "./components/Services";
import { useContractModalContext } from "./ModalContext";
import Payment from "./components/Payment";
import ContractModalHeader from "./components/ContractModalHeader";

interface ContractModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ContractModal: React.FC<ContractModalProps> = ({ isOpen, onClose }) => {
  const { step, loading, contract } = useContractModalContext();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />

      <DrawerContent pos="relative">
        <DrawerCloseButton />
        <ContractModalHeader />

        {step === 0 && <Services />}
        {!contract && step === 1 && <Payment />}
        {!!contract && step === 1 && <Confirmation />}
        {!contract && step === 2 && <Confirmation />}

        {loading && (
          <Flex
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="gray.100"
            zIndex="1"
            align="center"
            justify="center"
          >
            <CircularProgress isIndeterminate color="yooga.400" />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ContractModal;
