import { Config } from "shared/models/config";

export function paginateConfigs(
  configs: Config[],
  page: number = 1,
  pageSize: number = 5
) {
  const upperLimit = pageSize * page - 1;
  const lowerlimit = pageSize * (page - 1);

  return configs.filter((_, idx) => idx >= lowerlimit && idx <= upperLimit);
}
