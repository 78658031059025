import { AxiosResponse } from "axios";
import { IDelivery } from "shared/models/delivery";
import { IDeliveryOrder } from "shared/models/deliveryOrder";
import { apiNestNode } from "./http";
import { DeliveryOrderUpdateRequestData } from "./interfaces/delivery";

export function load(idi: number): Promise<AxiosResponse<IDelivery>> {
  return apiNestNode.get<IDelivery>(`installation/${idi}/delivery/store`);
}

export function updateDelivery(payload: IDelivery): Promise<AxiosResponse<IDelivery>> {
  return apiNestNode.post<IDelivery>(`/installation/${payload.idi}/delivery/store`, payload);
}

export function loadDeliveryOrders(idi: number): Promise<AxiosResponse<IDeliveryOrder[]>> {
  return apiNestNode.get<IDeliveryOrder[]>(`/installation/${idi}/delivery/orders`);
}

export function updateDeliveryOrder(
  idi: number,
  orderId: number,
  payload: DeliveryOrderUpdateRequestData
): Promise<AxiosResponse> {
  return apiNestNode.post(`/installation/${idi}/delivery/${orderId}`, payload);
}
