import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
  Textarea,
  FormErrorMessage,
  FormHelperText,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import moment from "moment";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { pauseAutomation } from "services/installation";
import { useAppTabContext } from "../AppContext";

interface PauseModalProps {
  isOpen: boolean;
  onClose(): void;
}

const JUSTIFICATIONS = [
  "Cliente enviou o comprovante de pagamento",
  "Cliente em negociação",
];

const PauseModal: React.FC<PauseModalProps> = ({ isOpen, onClose }) => {
  const { confirm } = useGlobalContext();
  const { app } = useAppTabContext();
  const { customer, loadApps } = useCustomerContext();

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [justify, setJustify] = useState("0");
  const [customJustify, setCustomJustify] = useState("");
  const [loading, setLoading] = useState(false);

  const dateMoment = useMemo(() => moment(date), [date]);

  const dateYearError = useMemo(
    () => !!dateMoment.diff(moment(), "year"),
    [dateMoment]
  );

  const dateNegativeError = useMemo(
    () => dateMoment.diff(moment(), "days") < 0,
    [dateMoment]
  );

  const submit = () => {
    confirm("Pausar automação", "Deseja continuar?", () => {
      setLoading(true);
      pauseAutomation(app.idi, {
        customer_id: customer.id,
        justification:
          justify !== "-1"
            ? JUSTIFICATIONS[Number(justify)]
            : customJustify.trim(),
        date: dateMoment.format("YYYY-MM-DD hh:mm:ss"),
      })
        .then(() => {
          toast.success("App atualizado");
          loadApps();
          onClose();
        })
        .catch(() => toast.error("Falha ao atualizar App"))
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    setDate(moment().format("YYYY-MM-DD"));
    setJustify("0");
    setCustomJustify("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Pausa na automação</ModalHeader>
        <ModalBody>
          <FormControl
            isRequired
            isInvalid={dateYearError || dateNegativeError}
          >
            <Text variant="label">
              <FormLabel>Data da pausa</FormLabel>
            </Text>
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />

            {dateYearError && (
              <FormErrorMessage>
                Não são permitidas datas acima de 1 ano no futuro
              </FormErrorMessage>
            )}

            {dateNegativeError && (
              <FormErrorMessage>
                Não são permitidas datas no passado
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl mt="1rem" isRequired>
            <Text variant="label">
              <FormLabel>Justificativa</FormLabel>
            </Text>
            <RadioGroup onChange={setJustify} value={justify}>
              <Stack>
                {JUSTIFICATIONS.map((label, idx) => (
                  <Radio key={idx} value={String(idx)}>
                    {label}
                  </Radio>
                ))}
                <Radio value="-1">Outra</Radio>
              </Stack>
            </RadioGroup>
            {justify === "-1" && (
              <>
                <Textarea
                  resize="none"
                  value={customJustify}
                  placeholder="Justificativa..."
                  mt="1rem"
                  onChange={(e) => setCustomJustify(e.target.value)}
                />
                <FormHelperText>Pelo menos 6 caracteres</FormHelperText>
              </>
            )}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="outlined" mr={3} onClick={onClose}>
            Fechar
          </Button>

          <Button
            variant="filled"
            onClick={submit}
            isLoading={loading}
            isDisabled={
              !date ||
              dateNegativeError ||
              dateYearError ||
              (justify === "-1" && customJustify.trim().length < 6)
            }
          >
            Confirmar pausa
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PauseModal;
