import {
  Button,
  Card,
  CardBody,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Stack,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { changeUserPassword } from "services/app";
import { AppUser, IAppUser } from "shared/models/appUser";
import { MdInfoOutline } from "react-icons/md";
import { AccessLevelType } from "shared/types/accessLevel";
import { useAppTabContext } from "../AppContext";
import { validateCNPJ, validateCPF } from "shared/helpers/document";
import MaskedInput from "react-input-mask";
import { documentMask } from "shared/helpers/mask";
import { validateEmail } from "shared/helpers/email";
import { Mixpanel } from "services/mixpanel";

export type NewPasswordProps = React.PropsWithChildren<{
  user?: AppUser;
  onClose(): void;
}>;

const NewPasswordModal: React.FC<NewPasswordProps> = ({
  user,
  onClose: onCloseFromProps,
}) => {
  const { loadUsers, isEmissor } = useAppTabContext();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [accessLevel, setAccessLevel] = useState<0 | 25 | 50 | 75 | 100 | 125>(
    0
  );

  const loginError: boolean = useMemo(
    () =>
      isEmissor
        ? login.length > 5 && !validateEmail(login)
        : login.length >= 14 && !validateCPF(login) && !validateCNPJ(login),
    [login, isEmissor]
  );

  const passwordError: boolean = useMemo(
    () => !!password && password.length < 4,
    [password]
  );

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    onCloseFromProps();
    setLogin("");
    setPassword("");
    setLoading(false);
  };

  const save = () => {
    setLoading(true);

    const payload: IAppUser = {
      ...user!.originalPayload,
      login,
      nivel_acesso: {
        Int32: accessLevel,
        Valid: accessLevel !== 0,
      },
    };

    if (password) payload.token = password;

    Mixpanel.track("customers-app-users-submitted-edit-user");
    changeUserPassword(payload)
      .then(() => {
        toast.success("Nova senha salva");
        loadUsers();
        onClose();
      })
      .catch(() => toast.error("Falha ao salvar nova senha"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user) Mixpanel.track("customers-app-users-opened-edit-user");
  }, [user]);

  useEffect(() => {
    if (user) {
      setLogin(user.login);
      setAccessLevel(user.accessLevel.Int32);
    }
  }, [user]);

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={!!user}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt="3rem">
          <Card bg="yellow.100">
            <CardBody>
              <HStack gap="1rem">
                <Icon as={MdInfoOutline} color="yellow.500" />
                <Text fontSize="0.8rem" fontWeight="400" color="gray.600">
                  <strong>Aviso:</strong> Ao gerar nova senha e confirmar, o
                  usuário será deslogado automaticamente.
                </Text>
              </HStack>
            </CardBody>
          </Card>
          <Text mt="1.5rem" mb="0.5rem">
            Gerar nova senha
          </Text>
          <Text fontSize="0.8rem" fontWeight="400">
            Usuário selecionado: <strong>{user?.fullName}</strong>
          </Text>
        </ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody as={Stack} gap="1.5rem">
          <FormControl isRequired isInvalid={loginError}>
            <FormLabel fontSize="0.8rem" color="gray.500">
              Login
            </FormLabel>
            {isEmissor ? (
              <Input
                placeholder="Ex: contato@yooga.com.br"
                value={login}
                disabled={loading}
                onChange={(e) => setLogin(e.target.value)}
              />
            ) : (
              <Input
                as={MaskedInput}
                mask={documentMask(login)}
                maskChar=""
                placeholder="Ex: 12.345.678/0000-90"
                value={login}
                disabled={loading}
                onChange={(e) => setLogin(e.target.value)}
              />
            )}

            {isEmissor && <FormHelperText>Insira um e-mail</FormHelperText>}
            <FormErrorMessage>
              {isEmissor ? "E-mail inválido" : "Insira um documento válido"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={passwordError}>
            <FormLabel fontSize="0.8rem" color="gray.500">
              Nova senha
            </FormLabel>
            <Input
              placeholder="Digite a nova senha"
              value={password}
              disabled={loading}
              onChange={(e) => setPassword(e.target.value)}
            />

            {!password && (
              <FormHelperText>
                Deixe vazia para alterar apenas o nível de acesso
              </FormHelperText>
            )}

            <FormErrorMessage>
              A senha precisa ter pelo menos 4 caracteres
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="0.8rem" color="gray.500">
              Permissão de acesso
            </FormLabel>
            <Select
              disabled={loading}
              value={accessLevel}
              onChange={(e) =>
                setAccessLevel(Number(e.target.value) as typeof accessLevel)
              }
            >
              {Object.keys(AccessLevelType).map((key) => (
                <option key={key} value={key}>
                  {AccessLevelType[Number(key) as typeof accessLevel]}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            w="100%"
            onClick={save}
            variant="filled"
            isDisabled={
              (!isEmissor && login.length < 14) ||
              (isEmissor && login.length < 6) ||
              passwordError ||
              loginError
            }
            isLoading={loading}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewPasswordModal;
