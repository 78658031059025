import { useCustomerContext } from "pages/Customer/CustomerContext";
import Banner from "shared/components/Banner";
import { FaUserPlus } from "react-icons/fa6";
import { useGlobalContext } from "GlobalContext";
import { Mixpanel } from "services/mixpanel";

const NoCustomerBanner = () => {
  const { customer, info } = useCustomerContext();
  const { setCreateCustomerModalOpen, setCreateCustomerModalData } =
    useGlobalContext();

  const action = () => {
    Mixpanel.track("customers-principal-opened-customer-modal");
    setCreateCustomerModalData(info);
    setCreateCustomerModalOpen(true);
  };

  return !customer.contract || !customer.contract.id ? (
    <Banner
      colorScheme="red"
      content="Cliente sem Customers"
      subContent="Esse cliente ainda está sem um cadastro no Customers"
      action={action}
      Icon={FaUserPlus}
    />
  ) : (
    <></>
  );
};

export default NoCustomerBanner;
