import {
  Box,
  DrawerHeader,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useChangePlanModalContext } from "../ModalContext";

const ChangePlanModalHeader = () => {
  const { step, contract, loading, setStep } = useChangePlanModalContext();

  const orientation = useBreakpointValue({
    base: "vertical",
    sm: contract ? "horizontal" : "vertical",
    md: "horizontal",
  }) as "vertical" | "horizontal";

  const onClickPlan = () => !loading && setStep(0);

  return (
    <DrawerHeader>
      <Stepper index={step} mt="1.5rem" orientation={orientation}>
        <Step>
          <StepIndicator
            cursor={step > 0 ? "pointer" : "default"}
            onClick={onClickPlan}
          >
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box
            flexShrink="0"
            cursor={step > 0 ? "pointer" : "default"}
            onClick={onClickPlan}
          >
            <StepTitle>Plano</StepTitle>
            <StepDescription>Selecione os novo plano</StepDescription>
          </Box>
          <StepSeparator />
        </Step>

        <Step>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>Resumo</StepTitle>
            <StepDescription>Confirme as informações</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      </Stepper>
    </DrawerHeader>
  );
};

export default ChangePlanModalHeader;
