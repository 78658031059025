import { ConfigDomain, ConfigType } from "shared/types/configs";

export class Config {
  code?: number;
  createdAt?: string;
  updatedAt?: string;

  name: string;
  description: string;
  type: "boolean" | "token";
  existInApp: boolean;
  domain: ConfigDomain;

  trueValue: string;
  trueType: "boolean" | "number" | "token";

  constructor(data: ConfigType) {
    this.name = data.name;
    this.description = data.description;
    this.type = data.type === "token" ? "token" : "boolean";
    this.trueType = data.type;
    this.existInApp = data.existInApp;
    this.domain = data.domain;

    if (data.type === "boolean") this.trueValue = "false";
    else if (data.type === "number") this.trueValue = "0";
    else this.trueValue = "";
  }

  get booleanValue(): boolean {
    return this.trueValue === "true" || this.trueValue === "1";
  }

  set booleanValue(value: boolean) {
    if (this.trueType === "boolean") this.trueValue = value ? "true" : "false";
    else if (this.trueType === "number") this.trueValue = value ? "1" : "0";
  }

  getTrueTypeOf(bool: boolean) {
    if (this.type === "boolean") return bool ? "true" : "false";
    return bool ? "1" : "0";
  }
}
