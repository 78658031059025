import {
  Image,
  Stack,
  Text,
  chakra,
  Skeleton,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { failedSearchPNG, searchPNG } from "assets/images";
import { useGlobalContext } from "GlobalContext";
import { useHomeContext } from "../HomeContext";
import Result from "./Result";
import SearchHistory from "./SearchHistory";

const Results = () => {
  const { results, loading, failedSearch } = useHomeContext();
  const { setCreateCustomerModalOpen } = useGlobalContext();
  const bg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return loading ? (
    <Stack width="100%" align="stretch" mt="0.5rem">
      <Skeleton height="4.5rem" />
      <Skeleton height="4.5rem" />
      <Skeleton height="4.5rem" />
      <Skeleton height="4.5rem" />
      <Skeleton height="4.5rem" />
    </Stack>
  ) : results.length ? (
    <Stack
      w="100%"
      align="stretch"
      borderColor={borderColor}
      borderWidth="0 1px"
      bg={bg}
      paddingTop="0.5rem"
      gap="0"
    >
      {results.slice(0, 5).map((result, idx) => (
        <Result key={idx} result={result} />
      ))}
    </Stack>
  ) : (
    <Stack align="center" w="100%" pt="4rem" position="relative">
      <SearchHistory />

      <Image src={!failedSearch ? searchPNG : failedSearchPNG} w="160px" />
      <Text fontSize="32px" fontWeight="300" textAlign="center">
        {!failedSearch ? "Pesquise um " : "Ops! Não encontramos este "}
        <chakra.span fontWeight="700" color="yooga.500">
          cliente
        </chakra.span>
      </Text>
      {failedSearch && (
        <Button
          variant="ghost"
          textDecoration="underline"
          onClick={() => setCreateCustomerModalOpen(true)}
          colorScheme="yooga"
        >
          Cadastrar novo cliente
        </Button>
      )}
    </Stack>
  );
};

export default Results;
