import {
  Button,
  Collapse,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { R$ } from "shared/helpers/currency";
import { useDeliveryContext } from "../DeliveryContext";

const RangeTable = () => {
  const [show, setShow] = useState(false);
  const { delivery } = useDeliveryContext();

  return (
    <Stack>
      <Button
        pl="0"
        onClick={() => setShow(!show)}
        variant="link"
        colorScheme="yooga"
        rightIcon={show ? <FaChevronUp /> : <FaChevronDown />}
        alignSelf="flex-start"
        size="sm"
      >
        Mostrar tabela de {delivery?.hasNeighborhood ? "Bairros" : "Raios"}
      </Button>
      <Collapse in={show}>
        <TableContainer whiteSpace="break-spaces">
          <Table size="sm">
            <Thead>
              <Tr h="3rem">
                {delivery?.hasNeighborhood ? (
                  <>
                    <Th>Nome do Bairro</Th>
                    <Th>Tempo estimado</Th>
                  </>
                ) : (
                  <>
                    <Th>Alcance do estabelecimento</Th>
                  </>
                )}
                <Th isNumeric>Valor da taxa</Th>
              </Tr>
            </Thead>
            <Tbody>
              {delivery?.hasNeighborhood
                ? delivery?.neighborhoods.map((neighborhood, idx) => (
                    <Tr key={idx} h="3rem">
                      <Td>{neighborhood.name}</Td>
                      <Td>{neighborhood.delivery_time} minutos</Td>
                      <Td isNumeric>{R$(neighborhood.value)}</Td>
                    </Tr>
                  ))
                  : (
                    delivery?.radius && delivery.radius.length > 0 ? (
                      delivery.radius.map((radius, idx) => (
                        <Tr key={idx} h="3rem">
                          <Td>{String(radius.radius / 1000).replace(".", ",")} km</Td>
                          <Td isNumeric>{`R$${radius.valor}`}</Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={3}>Nenhum raio configurado</Td>
                      </Tr>
                    )
                  )}
            </Tbody>
          </Table>
        </TableContainer>
      </Collapse>
    </Stack>
  );
};

export default RangeTable;
