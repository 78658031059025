import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import moment from "moment";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { appReset } from "services/app";
import { Mixpanel } from "services/mixpanel";
import { formatDocument } from "shared/helpers/document";
import { useAppTabContext } from "../AppContext";

interface QuickConfigsModalProps {
  isOpen: boolean;
  onClose(): void;
}

const QuickConfigsModal: React.FC<QuickConfigsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { confirm } = useGlobalContext();
  const { app } = useAppTabContext();
  const { info, loadApps } = useCustomerContext();

  const [loading, setLoading] = useState(false);

  const [financial, setFinancial] = useState(false);
  const [sales, setSales] = useState(false);
  const [cashier, setCashier] = useState(false);
  const [storage, setStorage] = useState(false);
  const [orders, setOrders] = useState(false);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const submit = () => {
    confirm("Salvar configurações rápidas", "Deseja continuar?", () => {
      Mixpanel.track("customers-app-danger-zone-submitted-reset");
      setLoading(true);
      appReset({
        cnpj: formatDocument(info.document),
        idi: app.idi,
        caixa: cashier,
        estoque: storage,
        financeiro: financial,
        pedidos: orders,
        venda: sales,
        data_ini: startDate,
        data_fim: endDate,
      })
        .then(() => {
          toast.success("Configurações rápidas salvas");
          loadApps();
          onClose();
        })
        .catch(() => toast.error("Falha ao salvar configs"))
        .finally(() => setLoading(false));
    });
  };

  useEffect(() => {
    setLoading(false);
    setFinancial(false);
    setSales(false);
    setCashier(false);
    setStorage(false);
    setOrders(false);
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  }, [isOpen]);

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton isDisabled={loading} />
        <ModalHeader>Reset</ModalHeader>

        <ModalBody>
          <FormControl as={HStack}>
            <Switch
              id="financial-switch"
              isDisabled={loading}
              isChecked={financial}
              onChange={() => setFinancial(!financial)}
            />
            <FormLabel
              fontSize="0.8rem"
              color="gray.500"
              htmlFor="financial-switch"
            >
              Financeiro
            </FormLabel>
          </FormControl>

          <FormControl mt="1rem" as={HStack}>
            <Switch
              id="sales-switch"
              isDisabled={loading}
              isChecked={sales}
              onChange={() => setSales(!sales)}
            />
            <FormLabel
              fontSize="0.8rem"
              color="gray.500"
              htmlFor="sales-switch"
            >
              Venda
            </FormLabel>
          </FormControl>

          <FormControl mt="1rem" as={HStack}>
            <Switch
              id="cashier-switch"
              isDisabled={loading}
              isChecked={cashier}
              onChange={() => setCashier(!cashier)}
            />
            <FormLabel
              fontSize="0.8rem"
              color="gray.500"
              htmlFor="cashier-switch"
            >
              Caixa
            </FormLabel>
          </FormControl>

          <FormControl mt="1rem" as={HStack}>
            <Switch
              id="storage-switch"
              isDisabled={loading}
              isChecked={storage}
              onChange={() => setStorage(!storage)}
            />
            <FormLabel
              fontSize="0.8rem"
              color="gray.500"
              htmlFor="storage-switch"
            >
              Estoque
            </FormLabel>
          </FormControl>

          <FormControl mt="1rem" as={HStack}>
            <Switch
              id="orders-switch"
              isDisabled={loading}
              isChecked={orders}
              onChange={() => setOrders(!orders)}
            />
            <FormLabel
              fontSize="0.8rem"
              color="gray.500"
              htmlFor="orders-switch"
            >
              Pedidos
            </FormLabel>
          </FormControl>

          <Divider my="1rem" />

          <HStack gap="5%">
            <FormControl flexBasis="47.5%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                Data início
              </FormLabel>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>

            <FormControl flexBasis="47.5%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                Data fim
              </FormLabel>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            onClick={submit}
            colorScheme="yooga"
            w="100%"
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuickConfigsModal;
