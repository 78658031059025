import { Box, Flex, Image } from "@chakra-ui/react";
import { loginBannerPNG } from "assets/images";
import Form from "./Form";

const Login = () => {
  return (
    <Flex h="100vh">
      <Box flex="1" display={{ base: "none", md: "block" }}>
        <Image src={loginBannerPNG} fit="cover" w="100%" h="100%" />
      </Box>
      <Box flex="1" shadow="rgba(0, 0, 0, 0.35) -5px 0px 15px">
        <Form />
      </Box>
    </Flex>
  );
};

export default Login;
