import { useCallback, useEffect, useState } from "react";
import {
  Heading,
  Stack,
  chakra,
  Input,
  Button,
  InputGroup,
  HStack,
  Icon,
  Text,
  CircularProgress,
  Divider,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import Log, { LogType } from "shared/models/log";
import { createComment, getLogs } from "services/log";
import {
  BiSolidCommentDetail,
  BiSolidCommentCheck,
  BiSolidCommentX,
  BiSolidCommentEdit,
} from "react-icons/bi";

import { toast } from "react-toastify";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { Mixpanel } from "services/mixpanel";

const ICONS = {
  [LogType.COMMENT]: BiSolidCommentDetail,
  [LogType.MISC]: BiSolidCommentDetail,
  [LogType.ADD]: BiSolidCommentCheck,
  [LogType.REMOVE]: BiSolidCommentX,
  [LogType.EDIT]: BiSolidCommentEdit,
};

const LogHistory = () => {
  const {
    customer: { id },
  } = useCustomerContext();
  const [text, setText] = useState("");
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [loadingNewComment, setLoadingNewComment] = useState(false);
  const [logs, setLogs] = useState<Log[]>([]);

  const iconBoxColor = useColorModeValue("gray.200", "gray.600");
  const contentColor = useColorModeValue("gray.600", "gray.400");
  const timeColor = useColorModeValue("gray.500", "gray.300");

  const submit: React.FormEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (!text) return;
    Mixpanel.track("customers-principal-submitted-message");
    setLoadingNewComment(true);
    createComment(text, String(id))
      .then((response) => {
        setLogs([new Log(response.data.log), ...logs]);
        setText("");
      })
      .catch(() => toast.error("Não foi possível enviar o comentário"))
      .finally(() => setLoadingNewComment(false));
  };

  const fetch = useCallback(() => {
    if (!id) return;

    setLoadingLogs(true);
    getLogs(String(id))
      .then((response) => {
        setLogs(
          response.data
            .map((logData) => new Log(logData))
            .sort((a, b) => (a.createdAt.isAfter(b.createdAt) ? -1 : 1))
        );
      })
      .catch(() =>
        console.error(`Could not load activity history for client ${id}`)
      )
      .finally(() => setLoadingLogs(false));
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Stack as={chakra.form} onSubmit={submit}>
      <Heading my="1rem" size="md">
        Histórico de Atividades
      </Heading>
      <InputGroup>
        <Input
          variant="filled"
          placeholder="Escreva seu comentário"
          rounded="5px 0 0 5px"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Button
          type="submit"
          minW="fit-content"
          variant="filled"
          px="1.5rem"
          rounded="0 5px 5px 0"
          isLoading={loadingLogs || loadingNewComment}
        >
          Enviar
        </Button>
      </InputGroup>

      {loadingLogs ? (
        <CircularProgress isIndeterminate color="yooga.500" m="1rem auto" />
      ) : (
        <Stack align="stretch" w="100%" mt="1rem" gap="1rem">
          {logs.map((log) => (
            <HStack align="flex-start" key={log.id}>
              <Box p="0.5rem" bg={iconBoxColor} display="flex" rounded="5px">
                <Icon
                  as={ICONS[log.type]}
                  w="1.5rem"
                  h="1.5rem"
                  aria-label="Comentário"
                />
              </Box>
              <Stack flex="1">
                <HStack>
                  <Text fontWeight="600">{log.user}</Text>
                  <Text color={timeColor} fontSize="0.8rem">
                    {"\uFF0A "}
                    {log.createdAtString}
                  </Text>
                </HStack>

                <Text color={contentColor}>{log.content}</Text>
                <Divider mt="0.5rem" />
              </Stack>
            </HStack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default LogHistory;
