import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  chakra,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { yoogaPNG } from "assets/images";
import { authenticate } from "services/auth";
import { setStorageItem } from "services/localStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "GlobalContext";

const Form = () => {
  const navigate = useNavigate();
  const { setLoggedUser } = useGlobalContext();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      authenticate({ username, password })
        .then((response) => {
          setStorageItem("token", response.data.token);
          setStorageItem("user", JSON.stringify(response.data.user));
          setLoggedUser(response.data.user);
          navigate("/");
        })
        .catch(() => toast.error("Usuário ou senha incorretos"))
        .finally(() => setLoading(false));
    },
    [username, password, navigate, setLoggedUser]
  );

  return (
    <Stack w="100%" h="100%" align="stretch" justify="center" p="4rem">
      <Stack align="center">
        <Image src={yoogaPNG} w="50%" />
      </Stack>
      <chakra.form
        onSubmit={submit}
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap="1rem"
      >
        <FormControl maxW="360px">
          <FormLabel>Usuário</FormLabel>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>

        <FormControl maxW="360px">
          <FormLabel>Senha</FormLabel>
          <InputGroup>
            <Input
              pr="4.5rem"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <IconButton
                variant="ghost"
                size="sm"
                icon={showPassword ? <IoEye /> : <IoEyeOff />}
                aria-label={showPassword ? "Hide password" : "Show password"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Button
          type="submit"
          isLoading={loading}
          variant="filled"
          w="100%"
          maxW="360px"
        >
          Entrar
        </Button>
      </chakra.form>
    </Stack>
  );
};

export default Form;
