import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { R$ } from "shared/helpers/currency";
import { DeliveryOrder } from "shared/models/deliveryOrder";

interface PaymentDetailsModalProps {
  order?: DeliveryOrder;
  onClose(): void;
}

const PaymentDetailsModal: React.FC<PaymentDetailsModalProps> = ({
  order,
  onClose,
}) => {
  return (
    <Modal isOpen={!!order} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="fit-content">
        <ModalCloseButton />

        <ModalHeader>
          <Heading size="md">Pedido {order?.id}</Heading>
        </ModalHeader>

        <ModalBody w="fit-content">
          <Card>
            <CardHeader as={HStack}>
              <Heading size="md">Informações do Pagamento</Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Text fontSize="sm">
                    <strong>Nome do método: </strong>
                    {order?.payments && order?.payments[0]
                      ? order.payments[0].method_name
                      : "-"}
                  </Text>
                  <Text fontSize="sm">
                    <strong>Tipo do método: </strong>
                    {order?.payments && order?.payments[0]
                      ? order.payments[0].method_type
                      : "-"}
                  </Text>
                  <Text fontSize="sm">
                    <strong>Valor: </strong>
                    {order?.payments && order?.payments[0]
                      ? R$(order.payments[0].value)
                      : "-"}
                  </Text>
                  <Text fontSize="sm">
                    <strong>Tipo: </strong>
                    {order?.payments && order?.payments[0]
                      ? order.payments[0].type
                      : "-"}
                  </Text>
                  <Text fontSize="sm">
                    <strong>Driver: </strong>
                    {order?.payments && order?.payments[0]
                      ? order.payments[0].driver
                      : "-"}
                  </Text>
                </Box>

                {order?.payments && order?.payments[0].type === "ONLINE" ? (
                  <Flex direction={{ base: "column", md: "row" }}>
                    <Box flex="1">
                      <Heading size="sm" mb="0.5rem">
                        Detalhes do pagamento online
                      </Heading>
                      <Text fontSize="sm">
                        <strong>Id: </strong>
                        {order?.onlinePayments &&
                        order?.onlinePayments[0].id !== null
                          ? order.onlinePayments[0].id
                          : "-"}
                      </Text>
                      <Text fontSize="sm">
                        <strong>Valor: </strong>
                        {order?.onlinePayments &&
                        order?.onlinePayments[0].amount !== null
                          ? R$(order.onlinePayments[0].amount)
                          : "-"}
                      </Text>
                      <Text fontSize="sm">
                        <strong>Status: </strong>
                        {order?.onlinePayments &&
                        order?.onlinePayments[0].status !== null
                          ? order.onlinePaymentsStatus
                          : "-"}
                      </Text>
                      <Text fontSize="sm">
                        <strong>Tipo de pagamento: </strong>
                        {order?.onlinePayments &&
                        order?.onlinePayments[0].payment_type !== null
                          ? order.onlinePaymentsType
                          : "-"}
                      </Text>
                    </Box>

                    <Show below="md">
                      <Divider my="1rem" />
                    </Show>

                    <Box flex="1">
                      <Heading size="sm" mb="0.5rem">
                        Detalhes da transação
                      </Heading>
                      <Text fontSize="sm">
                        <strong>Status: </strong>
                        {order?.onlinePayments &&
                        order?.onlinePayments[0].transaction_json.metadata
                          ? order.metaDataStatus
                          : "-"}
                      </Text>
                      <Text fontSize="sm">
                        <strong>Retorno: </strong>
                        {order?.onlinePayments &&
                        order?.onlinePayments[0].transaction_json?.metadata
                          ?.last_transaction?.acquirer_message
                          ? order.onlinePayments[0].transaction_json.metadata
                              .last_transaction.acquirer_message
                          : "-"}
                      </Text>
                      {order?.onlinePayments &&
                      order?.onlinePayments[0].transaction_json &&
                      order?.onlinePayments[0].transaction_json?.method ===
                        "CREDIT" &&
                      order?.onlinePayments[0].transaction_json?.metadata &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction?.card &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction?.card.last_four_digits !== "" ? (
                        <>
                          <Text fontSize="sm">
                            <strong>Bandeira do cartão: </strong>
                            {
                              order.onlinePayments[0].transaction_json.metadata
                                .last_transaction.card.brand
                            }
                          </Text>
                          <Text fontSize="sm">
                            <strong>Últimos 4 números do cartão: </strong>
                            {
                              order.onlinePayments[0].transaction_json.metadata
                                .last_transaction.card.last_four_digits
                            }
                          </Text>
                        </>
                      ) : (
                        ""
                      )}
                      {order?.onlinePayments &&
                      order?.onlinePayments[0].transaction_json &&
                      order?.onlinePayments[0].transaction_json?.method ===
                        "CREDIT" &&
                      order?.onlinePayments[0].transaction_json?.metadata &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction.gateway_response &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction.gateway_response.errors &&
                      order?.onlinePayments[0].transaction_json?.metadata
                        ?.last_transaction.gateway_response.errors.length ? (
                        <Text fontSize="sm">
                          <strong>Motivo da falha: </strong>
                          {
                            order.onlinePayments[0].transaction_json.metadata
                              .last_transaction.gateway_response.errors[0]
                              .message
                          }
                        </Text>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Flex>
                ) : (
                  ""
                )}
              </Stack>
            </CardBody>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentDetailsModal;
