import { useGlobalContext } from "GlobalContext";
import moment from "moment";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addServices, create } from "services/contract";
import { CreateContractRequestData } from "services/interfaces/contract";
import { Mixpanel } from "services/mixpanel";
import { load } from "services/product";
import { getClusterPricing } from "shared/helpers/cluster";
import { R$ } from "shared/helpers/currency";
import { Contract } from "shared/models/contract";
import { Discount } from "shared/models/discount";
import { Plan } from "shared/models/plan";
import { Product } from "shared/models/product";
import { PaymentTag } from "shared/types/payment";
import { Periodicity } from "shared/types/periodicity";
import DiscountModal from "./components/DiscountModal";
import ContractModal from "./ContractModal";

interface ContractModalProps {
  isOpen: boolean;
  onClose(): void;
  contract?: Contract;
}

interface ContractModalContextData {
  step: number;
  nextStep(): void;
  previousStep(): void;
  product?: Product;
  selectedPlan?: Plan;
  setSelectedPlan(p?: Plan): void;
  addedProducts: Product[];
  addPlan(p: Plan): void;
  removePlan(id: number): void;
  contract?: Contract;
  paymentMethod: 1 | 2;
  setPaymentMethod(m: 1 | 2): void;
  periodicity: Periodicity;
  setPeriodicity(p: Periodicity): void;
  getUpsellPrice(): string;
  getPlanPricesSum(): number;
  paymentDay: number;
  setPaymentDay(n: number): void;
  loading: boolean;
  setLoading(b: boolean): void;
  applyDiscount(n: number, d: Discount): void;
  handleSubmit(): void;
  setDiscountProduct(p: Product): void;
  setStep(n: number): void;
}

const Context = createContext<ContractModalContextData>(
  {} as ContractModalContextData
);

export const useContractModalContext = () => useContext(Context);

const ContractModalContext: React.FC<ContractModalProps> = ({
  contract,
  isOpen,
  onClose,
}) => {
  const { loadCustomer, customer } = useCustomerContext();
  const { confirm } = useGlobalContext();

  const [step, setStep] = useState<number>(0);
  const [product, setProduct] = useState<Product>();
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [addedProducts, setAddedProducts] = useState<Product[]>([]);
  const [periodicity, setPeriodicity] = useState<Periodicity>("monthly");
  const [paymentMethod, setPaymentMethod] = useState<1 | 2>(1);
  const [paymentDay, setPaymentDay] = useState<number>(moment().date());
  const [discountProduct, setDiscountProduct] = useState<Product>();

  const [loading, setLoading] = useState(false);

  const nextStep = () => setStep(step + 1);
  const previousStep = () => setStep(step - 1);

  const addPlan = (plan: Plan) => {
    const newProduct = new Product({ name: product?.name });
    newProduct.plan = plan;
    setAddedProducts([...addedProducts, newProduct]);
    setSelectedPlan(undefined);
  };

  const removePlan = (planId: number) => {
    setAddedProducts(
      addedProducts.filter((product) => product.plan?.id !== planId)
    );
  };

  const applyDiscount = (id: number, discount: Discount) => {
    setAddedProducts(
      addedProducts.map((product) => {
        if (product.plan?.id === id) product.discount = discount;
        return product;
      })
    );
  };

  const getPlanPricesSum = () => {
    let sum = 0;

    addedProducts.forEach((product) => {
      if (product.plan?.value) sum += product.plan?.value;
    });

    return sum;
  };

  const getUpsellPrice = () => {
    const pricesSum = getPlanPricesSum();

    const clusterPrice = getClusterPricing(pricesSum);

    return R$(clusterPrice) as string;
  };

  const getValidPaymentDay = (paymentDay: number) => {
    const validDays = [5, 10, 15, 20];
    for (let day of validDays) {
      if (paymentDay <= day) {
        return day;
      }
    }
    return 5;
  };

  const handleSubmit = () => {
    if (loading || !addedProducts.length) return;

    const services = addedProducts.map((product) => {
      const service: any = {
        service_id: product.plan?.id as number,
      };
      if (product.discount)
        service.discounts = {
          cycles: product.discount.cycles,
          type: product.discount.discountTypeName,
          value: product.discount.discount_value,
        };

      return service;
    });

    if (contract && contract.id) {
      confirm("Adicionar serviços", "Deseja continuar?", () => {
        Mixpanel.track(
          "customers-financial-add-services-submitted-new-services"
        );
        setLoading(true);
        addServices(contract.id as number, { services })
          .then(() => {
            onClose();
            toast.success("Serviços adicionados");
            loadCustomer();
          })
          .catch(() =>
            toast.error("Não foi possível adicionar os serviços agora")
          )
          .finally(() => setLoading(false));
      });
    } else {
      const dueDate = moment().set("date", getValidPaymentDay(paymentDay));
      if (moment().get("date") > dueDate.get("date")) dueDate.add(1, "M");

      const payload: CreateContractRequestData = {
        customer_id: customer.id,
        due_date: dueDate.format("YYYY-MM-DD"),
        software_id: 1,
        payment_method:
          paymentMethod === 1 ? PaymentTag.CREDIT_CARD : PaymentTag.INVOICE,
        periodicity,
        services,
      };

      confirm("Criar contrato", "Deseja continuar?", () => {
        Mixpanel.track("customers-principal-submitted-contract-modal");
        setLoading(true);
        create(payload)
          .then(() => {
            onClose();
            toast.success("Contrato criado");
            loadCustomer();
          })
          .catch(() => toast.error("Não foi possível criar o contrato agora"))
          .finally(() => setLoading(false));
      });
    }
  };

  const handleAddDiscount = (product: Product, discount: Discount) => {
    const productWithDiscount: Product = Object.assign({}, product);
    Object.setPrototypeOf(productWithDiscount, Product.prototype);

    productWithDiscount.discount = discount;

    setAddedProducts(
      addedProducts.map((p) =>
        p.plan!.id === product.plan!.id ? productWithDiscount : p
      )
    );
  };

  useEffect(() => {
    setLoading(true);
    load()
      .then((response) => {
        const data = response.data.map((product) => new Product(product));
        if (data.length) setProduct(data[0]);
        else throw Error("No products retrieved");
      })
      .catch(() => toast.error("Não foi possível carregar os planos"))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setSelectedPlan(undefined);
  }, [periodicity, setSelectedPlan]);

  useEffect(() => {
    setStep(0);
    setSelectedPlan(undefined);
    setAddedProducts([]);
    setPeriodicity("monthly");
    setPaymentMethod(1);
    setPaymentDay(getValidPaymentDay(moment().date()));
    setDiscountProduct(undefined);
  }, [isOpen]);

  const data: ContractModalContextData = {
    step,
    nextStep,
    previousStep,
    product,
    selectedPlan,
    setSelectedPlan,
    addedProducts,
    addPlan,
    removePlan,
    contract,
    paymentMethod,
    setPaymentMethod,
    periodicity,
    setPeriodicity,
    getUpsellPrice,
    getPlanPricesSum,
    paymentDay,
    setPaymentDay,
    loading,
    setLoading,
    applyDiscount,
    handleSubmit,
    setDiscountProduct,
    setStep,
  };

  return (
    <Context.Provider value={data}>
      <ContractModal isOpen={isOpen} onClose={onClose} />
      <DiscountModal
        product={discountProduct}
        onClose={() => setDiscountProduct(undefined)}
        onSuccess={handleAddDiscount}
      />
    </Context.Provider>
  );
};

export default ContractModalContext;
