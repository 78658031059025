import { useGlobalContext } from "GlobalContext";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { update } from "services/customer";
import { Mixpanel } from "services/mixpanel";
import { searchViaCep } from "services/zipCode";
import EditCustomerModal from "./EditCustomerModal";
import validateCustomerForm, { IErrors } from "./validations";

interface EditCustomerModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface EditCustomerContextData {
  loading: boolean;
  setLoading(b: boolean): void;
  cnpj: string;
  name: string;
  contact_email: string;
  trade_name: string;
  state_registration: string;
  city_registration: string;
  postal_code: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  telephone: string;
  setCnpj(s: string): void;
  setName(s: string): void;
  setContactEmail(s: string): void;
  setTradeName(s: string): void;
  setState(s: string): void;
  setCity(s: string): void;
  setPostalCode(s: string): void;
  setAddress(s: string): void;
  setNumber(s: string): void;
  setComplement(s: string): void;
  setNeighborhood(s: string): void;
  setPhone(s: string): void;
  errors: IErrors;
  handleSubmit(): void;
}

const Context = createContext<EditCustomerContextData>({} as EditCustomerContextData);

export const useEditCustomerModalContext = () => useContext(Context);

const EditCustomerContext: React.FC<EditCustomerModalProps> = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { customer, loadCustomer } = useCustomerContext();
  const { confirm } = useGlobalContext();

  const [cnpj, setCnpj] = useState("");
  const [name, setName] = useState("");
  const [contact_email, setContactEmail] = useState("");
  const [trade_name, setTradeName] = useState("");
  const [state_registration, setState] = useState("");
  const [city_registration, setCity] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [logradouro, setAddress] = useState("");
  const [numero, setNumber] = useState("");
  const [complemento, setComplement] = useState("");
  const [bairro, setNeighborhood] = useState("");
  const [telephone, setPhone] = useState("");

  const [errors, setErrors] = useState<IErrors>({});

  const handleSubmit = () => {
    const payload = {
      cnpj: cnpj.replace(/\D/g, ""),
      name,
      contact_email,
      trade_name,
      state_registration,
      city_registration,
      postal_code,
      logradouro,
      numero,
      bairro,
      telephone: telephone.replace(/\D/g, ""),
      id: customer.id,
      complemento,
    };

    const err = validateCustomerForm(payload);

    setErrors(err);

    if (Object.values(err).length) return;

    confirm("Atualizar dados do cliente", "Deseja continuar?", () => {
      Mixpanel.track("customers-client-data-submitted-edit-customer-modal");
      setLoading(true);
      update(customer.id, payload)
        .then(() => {
          toast.success("Cliente salvo com sucesso");
          loadCustomer();
          onClose();
        })
        .catch(() => toast.error("Falha ao salvar o cliente"))
        .finally(() => setLoading(false));
    });
  };

  const searchZipCode = useCallback(() => {
    if (!postal_code) return;

    if (postal_code.length === 9) {
      Mixpanel.track("customers-new-customer-searched-viacep");
      searchViaCep(postal_code)
        .then(({ data }) => {
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          setState(data.uf);
        })
        .catch(() => console.error(`Could not load data from ViaCep for CEP ${postal_code}`));
    }
  }, [postal_code]);

  useEffect(() => {
    if (isOpen) {
      Mixpanel.track("customers-client-data-opened-edit-customer-modal");
    }
  }, [isOpen]);

  useEffect(() => {
    searchZipCode();
  }, [searchZipCode]);

  useEffect(() => {
    setCnpj(customer.document);
    setName(customer.name);
    setContactEmail(customer.email);
    customer.tradeName && setTradeName(customer.tradeName);
    customer.state && setState(customer.state);
    customer.city && setCity(customer.city);
    customer.zipcode && setPostalCode(customer.zipcode);
    customer.address && setAddress(customer.address);
    customer.number && setNumber(customer.number);
    customer.complement && setComplement(customer.complement);
    customer.neighborhood && setNeighborhood(customer.neighborhood);
    setPhone(customer.phone);
    setErrors({});
    setLoading(false);
  }, [customer, isOpen]);

  const data: EditCustomerContextData = {
    loading,
    setLoading,
    cnpj,
    name,
    contact_email,
    trade_name,
    state_registration,
    city_registration,
    postal_code,
    logradouro,
    numero,
    complemento,
    bairro,
    telephone,
    setCnpj,
    setName,
    setContactEmail,
    setTradeName,
    setState,
    setCity,
    setPostalCode,
    setAddress,
    setNumber,
    setComplement,
    setNeighborhood,
    setPhone,
    errors,
    handleSubmit,
  };

  return (
    <Context.Provider value={data}>
      <EditCustomerModal isOpen={isOpen} onClose={onClose} />
    </Context.Provider>
  );
};

export default EditCustomerContext;
