import moment from "moment";
import { FeatureUpdate } from "shared/types/featureUpdate";

import appEmissorPNG from "./images/appEmissor.png";
import desbloqueioConfiancaPNG from "./images/desbloqueioConfianca.png";
import metodoPagamentoPNG from "./images/metodoPagamento.png";
import criarCobrancaPNG from "./images/criarCobranca.png";
import aplicarDescontoPNG from "./images/aplicarDesconto.png";
import temaEscuroPNG from "./images/temaEscuro.png";
import bloquearAppPNG from "./images/bloquearApp.png";
import ltvPNG from "./images/ltv.png";
import historicoPedidosPNG from "./images/historicoPedidos.png";

const updates: FeatureUpdate[] = [
  {
    date: moment("2024-03-04T09"),
    title: "Customers 2.0",
    content: "Bem vindo à nova plataforma Customers da Yooga.",
    type: "new",
  },
  {
    date: moment("2024-03-04T10"),
    title: "App e Emissor",
    content:
      "Agora pelo Customers você gerencia as funções do Licenciamento. Pesquise por um Customer ou por um IDI e acesse as abas App e Emissor.",
    highlight: ["App", "Emissor"],
    type: "new",
    version: "1.0.0",
    imageSrc: appEmissorPNG,
  },
  {
    date: moment("2024-03-04T11"),
    title: "Ativar e desativar contrato",
    content: "Foi corrigido o erro ao ativar ou desativar um contrato.",
    type: "bug",
    version: "1.0.1",
  },
  {
    date: moment("2024-03-07"),
    title: "Último desbloqueio de confiança do App",
    content:
      "Agora pelo Customers você gerencia as funções do Licenciamento. Pesquise por um Customer ou por um IDI e acesse as abas App e Emissor.",
    highlight: ["último desbloqueio de confiança"],
    type: "feature",
    version: "1.0.8",
    imageSrc: desbloqueioConfiancaPNG,
  },
  {
    date: moment("2024-03-05"),
    title: "Mudança de método de pagamento",
    content:
      "Foi adicionada a opção de alterar o método de pagamento pela aba Financeiro.",
    highlight: ["Financeiro"],
    type: "feature",
    version: "1.0.3",
    imageSrc: metodoPagamentoPNG,
  },
  {
    date: moment("2024-03-04T10:30"),
    title: "Criar cobrança avulsa",
    content:
      "É possível criar uma cobrança avulsa na seção de pagamentos da aba Financeiro.",
    highlight: ["Financeiro"],
    type: "feature",
    version: "1.0.0",
    imageSrc: criarCobrancaPNG,
  },
  {
    date: moment("2024-03-05T19"),
    title: "Aplicar desconto",
    content:
      "Foi corrigido o erro que não permitia aplicar desconto ao valor de um serviço na criação de contrato.",
    highlight: ["aplicar desconto"],
    type: "bug",
    version: "1.0.4",
    imageSrc: aplicarDescontoPNG,
  },
  {
    date: moment("2024-03-04T18"),
    title: "Link do boleto",
    content:
      "Foi corrigido o erro que gerava o link errado para pagamento do boleto.",
    type: "bug",
    version: "1.0.2",
  },
  {
    date: moment("2024-03-15"),
    title: "Tema Escuro",
    content:
      "Agora o Customers possui Tema Escuro! A funcionalidade é experimental e pode não ter a melhor visualização em alguns momentos, porém estamos trabalhando para melhorar o tema.",
    highlight: ["Tema Escuro", "experimental"],
    type: "new",
    version: "1.1.0",
    imageSrc: temaEscuroPNG,
  },

  {
    date: moment("2024-03-21"),
    title: "Bloquear App",
    content: "Agora o Customers tem a opção de bloquear um App do cliente.",
    highlight: ["bloquear", "app"],
    type: "feature",
    version: "1.1.10",
    imageSrc: bloquearAppPNG,
  },
  {
    date: moment("2024-04-11T11"),
    title: "Valor total das cobranças",
    content:
      "Na seção de pagamentos agora é possível ver o valor total e segmentado das cobranças.",
    highlight: ["valor total", "pagamentos"],
    type: "feature",
    version: "1.1.20",
    imageSrc: ltvPNG,
  },
  {
    date: moment("2024-05-8"),
    title: "Novo delivery",
    content:
      "A aba de delivery foi atualizada, com informações sobre o endereço e uma lista dos bairros/raios de entrega. O histórico de pedidos também foi adicionado, nele é possível visualizar os últimos pedidos daquele App, ver os detalhes do pagamento e atualizar o status do pedido.",
    highlight: [
      "aba de delivery",
      "informações sobre o endereço",
      "lista dos bairros/raios",
      "histórico de pedidos",
      "atualizar o status do pedido.",
    ],
    type: "feature",
    version: "1.3.0",
    imageSrc: historicoPedidosPNG,
  },
];

export default updates;
