import { redirect } from "react-router-dom";
import { getStorageItem, removeStorageItem } from "services/localStorage";

export const rootLoader = () => {
  const token = getStorageItem("token");
  const user = getStorageItem("user");

  if (!token || !user) {
    removeStorageItem("token");
    removeStorageItem("user");
    return redirect("/login");
  }

  return null;
};

export const loginLoader = () => {
  const token = getStorageItem("token");
  const user = getStorageItem("user");

  if (token && user) return redirect("/");

  removeStorageItem("token");
  removeStorageItem("user");
  return null;
};
