import { onlyNumbers } from "./string";

export function documentMask(document?: string) {
  return document && onlyNumbers(document).length < 12
    ? "999.999.999-999"
    : "99.999.999/9999-99";
}

export function phoneMask(phone?: string) {
  return phone && onlyNumbers(phone).length < 11
    ? "(99) 9999-99999"
    : "(99) 99999-9999";
}
