import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Contract } from "shared/models/contract";
import { Plan } from "shared/models/plan";
import { Periodicity } from "shared/types/periodicity";
import ChangePlanModal from "./ChangePlanModal";

interface ChangePlanModalProps {
  isOpen: boolean;
  onClose(): void;
  contract?: Contract;
}

interface ChangePlanModalContextData {
  step: number;
  nextStep(): void;
  previousStep(): void;
  selectedPlan: -1 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  setSelectedPlan(p: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8): void;
  contract?: Contract;
  plan?: Plan;
  periodicity: Periodicity;
  setPeriodicity(p: Periodicity): void;
  loading: boolean;
  setLoading(b: boolean): void;
  handleSubmit(): void;
  setStep(n: number): void;
}

const Context = createContext<ChangePlanModalContextData>(
  {} as ChangePlanModalContextData
);

export const useChangePlanModalContext = () => useContext(Context);

const ChangePlanModalContext: React.FC<ChangePlanModalProps> = ({
  contract,
  isOpen,
  onClose,
}) => {
  const [step, setStep] = useState<number>(0);
  const [selectedPlan, setSelectedPlan] = useState<
    -1 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  >(-1);

  const [loading, setLoading] = useState(false);

  const [periodicity, setPeriodicity] = useState<Periodicity>("monthly");

  const nextStep = () => setStep(step + 1);
  const previousStep = () => setStep(step - 1);

  const plan = useMemo(
    () =>
      contract?.products.find(({ plan }) =>
        [1, 2, 3, 4, 5, 6, 7, 8].includes(plan?.id as number)
      )?.plan,
    [contract]
  );

  const handleSubmit = () => {};

  useEffect(() => {
    setSelectedPlan(-1);
  }, [periodicity]);

  useEffect(() => {
    setStep(0);
    setSelectedPlan(-1);
    setPeriodicity(plan?.periodicity ?? "monthly");
  }, [plan]);

  const data: ChangePlanModalContextData = {
    step,
    nextStep,
    previousStep,
    selectedPlan,
    setSelectedPlan,
    contract,
    plan,
    periodicity,
    setPeriodicity,
    loading,
    setLoading,
    handleSubmit,
    setStep,
  };

  return (
    <Context.Provider value={data}>
      <ChangePlanModal isOpen={isOpen} onClose={onClose} />
    </Context.Provider>
  );
};

export default ChangePlanModalContext;
