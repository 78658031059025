import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useInstabilityContext } from "../InstabilityContext";
import Template from "./Template";

interface InstabilityModalProps {
  isOpen: boolean;
  onClose(): void;
}

const InstabilityModal: React.FC<InstabilityModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { instabilities } = useInstabilityContext();
  const [openOlder, setOpenOlder] = useState(false);
  const bg = useColorModeValue("gray.50", "gray.600");

  const activeInstabilities = instabilities
    .filter((i) => !i.dateSolved)
    .sort(({ date: dateA }, { date: dateB }) =>
      dateA.isBefore(dateB) ? 1 : -1
    );

  const olderInstabilities = instabilities
    .filter((i) => !!i.dateSolved)
    .sort(({ dateSolved: dateA }, { dateSolved: dateB }) =>
      dateA!.isBefore(dateB) ? 1 : -1
    );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={bg} maxW="90vw">
        <ModalCloseButton />
        <ModalHeader>Instabilidades</ModalHeader>
        <ModalBody pb="2rem" maxH="70vh" overflowY="scroll">
          {activeInstabilities.length ? (
            <Stack gap="1rem">
              {activeInstabilities.map((instability, idx) => (
                <Template key={idx} instability={instability} />
              ))}
            </Stack>
          ) : (
            <Text>Nenhuma instabilidade registrada no momento.</Text>
          )}
          <Button
            mt="2rem"
            mb="1rem"
            variant="ghost"
            color={openOlder ? "yooga.500" : undefined}
            rightIcon={openOlder ? <BiChevronUp /> : <BiChevronDown />}
            onClick={() => setOpenOlder(!openOlder)}
          >
            Instabilidades Anteriores
          </Button>
          <Collapse in={openOlder} unmountOnExit>
            <Stack gap="1rem">
              {olderInstabilities.map((instability, idx) => (
                <Template key={idx} instability={instability} />
              ))}
            </Stack>
          </Collapse>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InstabilityModal;
