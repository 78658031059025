import moment from "moment";

export function getClusterPricing(value: number) {
  const dayOfMonth = moment().date();
  let discountPercentage = 0;

  if (dayOfMonth <= 8) {
    discountPercentage = 1;
  } else if (dayOfMonth <= 16) {
    discountPercentage = 0.75;
  } else if (dayOfMonth <= 24) {
    discountPercentage = 0.5;
  } else {
    discountPercentage = 0.25;
  }

  return value * discountPercentage;
}
