import axios, { AxiosResponse } from "axios";
import { ZipCodeResponseData } from "./interfaces/zipCode";

const instance = axios.create({
  baseURL: "https://viacep.com.br",
});

export const searchViaCep = (
  zip_code: string
): Promise<AxiosResponse<ZipCodeResponseData>> =>
  instance.get<ZipCodeResponseData>(`/ws/${zip_code}/json`);
