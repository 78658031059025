const timezones: { [key: string]: string[] } = {
  Brasil: [
    "America/Araguaina",
    "America/Bahia",
    "America/Belem",
    "America/Boa_Vista",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Eirunepe",
    "America/Fortaleza",
    "America/Maceio",
    "America/Porto_Velho",
    "America/Noronha",
    "America/Recife",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Manaus",
    "America/Sao_Paulo",
  ].sort(),
  Portugal: ["Atlantic/Azores", "Atlantic/Madeira", "Europe/Lisbon"].sort(),
  "Estados Unidos": [
    "America/Adak",
    "America/Anchorage",
    "America/Boise",
    "America/Chicago",
    "America/Denver",
    "America/Detroit",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Los_Angeles",
    "America/Menominee",
    "America/Metlakatla",
    "America/New_York",
    "America/Nome",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Phoenix",
    "America/Sitka",
    "America/Yakutat",
    "Pacific/Honolulu",
  ].sort(),
  Argentina: [
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
  ].sort(),
  Uruguai: ["America/Montevideo"].sort(),
  Paraguai: ["America/Asuncion"].sort(),
};

export default timezones;
