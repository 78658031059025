import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as authService from "services/auth";
import { AuthUser } from "services/interfaces/auth";
import { SearchResponse } from "services/interfaces/search";
import { getStorageItem } from "services/localStorage";
import { Mixpanel } from "services/mixpanel";
import ConfirmModal from "shared/components/ConfirmModal";
import { Permission } from "shared/types/permission";

interface GlobalContextData {
  confirm(title?: string, content?: string, onConfirm?: () => void): void;
  confirmByText(title?: string, content?: string, onConfirm?: () => void): void;
  loggedUser?: AuthUser;
  setLoggedUser(u: AuthUser): void;
  createCustomerModalOpen: boolean;
  setCreateCustomerModalOpen(b: boolean): void;
  searchText: string;
  setSearchText(s: string): void;
  createCustomerModalData?: SearchResponse;
  setCreateCustomerModalData(s?: SearchResponse): void;
  permissions: Permission[];
}

const Context = createContext<GlobalContextData>({} as GlobalContextData);

export const useGlobalContext = () => useContext(Context);

interface ConfirmFunctionData {
  title?: string;
  content?: string;
  onConfirm?(): void;
  showCloseButton?: boolean;
  confirmByText?: boolean;
}

const GlobalContext: React.FC<PropsWithChildren> = ({ children }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmData, setConfirmData] = useState<ConfirmFunctionData>({});
  const [searchText, setSearchText] = useState("");
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
  const [createCustomerModalData, setCreateCustomerModalData] =
    useState<SearchResponse>();

  const [loggedUser, setLoggedUser] = useState<AuthUser>();

  const onCloseConfirmModal = () => {
    setConfirmModalOpen(false);
    setConfirmData({});
  };

  const confirm = useCallback(
    (title?: string, content?: string, onConfirm?: () => void) => {
      setConfirmData({
        title,
        content,
        onConfirm: () => {
          onConfirm?.();
          onCloseConfirmModal();
        },
        showCloseButton: !!onConfirm,
      });
      setConfirmModalOpen(true);
    },
    []
  );

  const confirmByText = useCallback(
    (title?: string, content?: string, onConfirm?: () => void) => {
      setConfirmData({
        title,
        content,
        confirmByText: true,
        onConfirm: () => {
          onConfirm?.();
          onCloseConfirmModal();
        },
        showCloseButton: !!onConfirm,
      });
      setConfirmModalOpen(true);
    },
    []
  );

  useEffect(() => {
    const storageUser = getStorageItem("user");
    if (storageUser) {
      try {
        const user: AuthUser = JSON.parse(storageUser);
        if (user.name) setLoggedUser(user);
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    if (loggedUser) {
      Mixpanel.identify(String(loggedUser.id));
      authService
        .permissions()
        .then((response) => setPermissions(response.data.permissions));
    }
  }, [loggedUser]);

  const data: GlobalContextData = useMemo(
    () => ({
      confirm,
      confirmByText,
      loggedUser,
      setLoggedUser,
      createCustomerModalOpen,
      setCreateCustomerModalOpen,
      searchText,
      setSearchText,
      createCustomerModalData,
      setCreateCustomerModalData,
      permissions,
    }),
    [
      confirm,
      confirmByText,
      loggedUser,
      createCustomerModalOpen,
      searchText,
      createCustomerModalData,
      permissions,
    ]
  );

  return (
    <Context.Provider value={data}>
      {children}
      <ConfirmModal
        {...confirmData}
        isOpen={confirmModalOpen}
        onClose={onCloseConfirmModal}
      />
    </Context.Provider>
  );
};

export default GlobalContext;
