import moment, { Moment } from "moment";
import {
  DeliveryOrderOnlinePayments,
  DeliveryOrderPayment,
  DeliveryOrderStatus,
} from "shared/types/deliveryOrder";

export interface IDeliveryOrder {
  id: number;
  customer_name: string;
  customer_phone: string;
  created_at: string;
  updated_at: string;
  price: number;
  origin: string;
  status: DeliveryOrderStatus;
  payments: DeliveryOrderPayment[];
  online_payments: DeliveryOrderOnlinePayments[];
}

export class DeliveryOrder {
  id: number;
  customerName: string;
  customerPhone: string;
  createdAt: Moment;
  updatedAt: Moment;
  price: number;
  origin: string;
  status: DeliveryOrderStatus;
  payments: DeliveryOrderPayment[];
  onlinePayments: DeliveryOrderOnlinePayments[];

  constructor(data: IDeliveryOrder) {
    this.id = data.id;
    this.customerName = data.customer_name;
    this.customerPhone = data.customer_phone;
    this.createdAt = moment(data.created_at);
    this.updatedAt = moment(data.updated_at);
    this.price = data.price;
    this.origin = data.origin;
    this.status = data.status;
    this.payments = data.payments;
    this.onlinePayments = data.online_payments;
  }

  // eslint-disable-next-line getter-return
  get onlinePaymentsStatus() {
    if (this.onlinePayments) {
      if (this.onlinePayments[0].status === "PENDING") {
        return (this.onlinePayments[0].status = "Pendente");
      } else if (this.onlinePayments[0].status === "SUCCEEDED") {
        return (this.onlinePayments[0].status = "Sucesso");
      } else if (this.onlinePayments[0].status === "REFUNDED") {
        return (this.onlinePayments[0].status = "Reembolsado");
      } else {
        return this.onlinePayments[0].status;
      }
    }
  }

  // eslint-disable-next-line getter-return
  get onlinePaymentsType() {
    if (this.onlinePayments) {
      if (this.onlinePayments[0].payment_type === "CREDIT") {
        return (this.onlinePayments[0].payment_type = "Crédito");
      } else if (this.onlinePayments[0].payment_type === "PIX") {
        return (this.onlinePayments[0].payment_type = "Pix");
      } else if (this.onlinePayments[0].payment_type === null) {
        return (this.onlinePayments[0].payment_type = "-");
      } else {
        return this.onlinePayments[0].payment_type;
      }
    }
  }

  // eslint-disable-next-line getter-return
  get metaDataStatus() {
    if (
      this.onlinePayments &&
      this.onlinePayments[0].transaction_json &&
      this.onlinePayments[0].transaction_json.metadata
    ) {
      if (this.onlinePayments[0].transaction_json.metadata.status === "paid") {
        return (this.onlinePayments[0].transaction_json.metadata.status =
          "Pago");
      } else if (
        this.onlinePayments[0].transaction_json.metadata.status === "ATIVA"
      ) {
        return (this.onlinePayments[0].transaction_json.metadata.status = "-");
      } else {
        return this.onlinePayments[0].transaction_json.metadata.status;
      }
    }
  }
}
