import { orderStatus } from "shared/models/order";

const COLORS = {
  [orderStatus.EM_ABERTO]: "yooga.400",
  [orderStatus.CANCELADO]: "red.400",
  [orderStatus.PAGO]: "green.400",
  [orderStatus.ACORDO]: "teal.300",
};

const LABELS = {
  [orderStatus.EM_ABERTO]: "Em Aberto",
  [orderStatus.CANCELADO]: "Cancelado",
  [orderStatus.PAGO]: "Pago",
  [orderStatus.ACORDO]: "Acordo",
};

export function getStatusColor(status: orderStatus) {
  return COLORS[status] ?? "";
}

export function getStatusLabel(status: orderStatus) {
  return LABELS[status] ?? "";
}
