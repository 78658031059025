import { useCustomerContext } from "pages/Customer/CustomerContext";
import Banner from "shared/components/Banner";
import { FiPrinter } from "react-icons/fi";
import { useMemo } from "react";
import { Mixpanel } from "services/mixpanel";

const IncomingInvoiceBanner = () => {
  const { customer } = useCustomerContext();
  const order = useMemo(
    () => customer?.contract?.nextOrderWithInvoice,
    [customer]
  );

  const action = () => {
    Mixpanel.track("customers-principal-downloaded-invoice");
    window.open(order?.invoiceLink);
  };

  return customer.contract && customer.contract.isInvoice && order ? (
    <Banner
      content="Baixe aqui o boleto do cliente"
      subContent="Confira o vencimento e envie em PDF para o cliente"
      Icon={FiPrinter}
      action={action}
      colorScheme="yellow"
    />
  ) : (
    <></>
  );
};

export default IncomingInvoiceBanner;
