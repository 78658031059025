import { formatDocument } from "shared/helpers/document";
import { Card, ICard } from "./card";
import { Contract, IContract } from "./contract";
import { Order } from "./order";

export interface ICustomer {
  id: number;
  corporate_name?: string | undefined;
  name: string;
  trade_name?: string | undefined;
  cnpj: string;
  contract?: IContract;
  contracts?: IContract[];
  postal_code?: string | undefined;
  logradouro?: string | undefined;
  numero?: string | undefined;
  bairro?: string | undefined;
  complemento?: string | undefined;
  reference?: string | undefined;
  city_registration?: string | undefined;
  state_registration?: string | undefined;
  migrated?: boolean | undefined;
  cards?: ICard[];
  contact_email: string;
  telephone: string;
  customer_type: "new" | "partial" | "active";
  has_marvin?: 0 | 1 | undefined;
  total_amount?: number | undefined;
  total_cancelled_amount?: number | undefined;
  total_open_amount?: number | undefined;
  total_paid_amount?: number | undefined;
  yooga_customer_id: string | null;
}

export class Customer {
  id: number;
  document: string;
  corporateName: string | undefined;
  name: string;
  tradeName: string | undefined;
  contracts: Contract[] = [];
  zipcode: string | undefined;
  address: string | undefined;
  number: string | undefined;
  neighborhood: string | undefined;
  complement: string | undefined;
  reference: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  migrated: boolean | undefined;
  cards: Card[] = [];
  email: string;
  phone: string;
  customerType: "new" | "partial" | "active";
  hasMarvin: boolean | undefined;
  totalAmount: number | undefined;
  totalCancelledAmount: number | undefined;
  totalOpenAmount: number | undefined;
  totalPaidAmount: number | undefined;
  yoogaCustomerId: string | null | undefined;
  protected _contract?: Contract;

  constructor(data: ICustomer) {
    this.id = data.id;
    this.document = data.cnpj;
    this.corporateName = data.corporate_name ?? undefined;
    this.name = data.name;
    this.tradeName = data.trade_name ?? undefined;
    this.zipcode = data.postal_code ?? undefined;
    this.address = data.logradouro ?? undefined;
    this.number = data.numero ?? undefined;
    this.neighborhood = data.bairro ?? undefined;
    this.complement = data.complemento ?? undefined;
    this.reference = data.reference ?? undefined;
    this.city = data.city_registration ?? undefined;
    this.state = data.state_registration ?? undefined;
    this.migrated = data.migrated ?? undefined;
    this.email = data.contact_email;
    this.phone = data.telephone;
    this.customerType = data.customer_type;
    this._contract = data.contract ? new Contract(data.contract) : undefined;
    this.hasMarvin = !!data.has_marvin;
    this.totalAmount = data.total_amount ?? undefined;
    this.totalCancelledAmount = data.total_cancelled_amount ?? undefined;
    this.totalOpenAmount = data.total_open_amount ?? undefined;
    this.totalPaidAmount = data.total_paid_amount ?? undefined;
    this.yoogaCustomerId = data.yooga_customer_id ?? undefined;

    data.contracts?.forEach((contract) => this.contracts.push(new Contract(contract)));

    data.cards?.forEach((card) => this.cards.push(new Card(card)));
  }

  get formattedDocument(): string {
    return formatDocument(this.document);
  }

  get contract(): Contract | undefined {
    return this._contract ?? this.contracts[0];
  }

  get firstOrder(): Order | null {
    if (this.contract && this.contract.orders.length) {
      return this.contract.orders[0];
    }

    return null;
  }

  get checkPaidFirstOrder(): boolean {
    const order = this.firstOrder;
    if (order) {
      return Boolean(order.statusId !== 2 && !(this.contract && this.contract.migrated));
    }

    return false;
  }

  get isRecordIncomplete(): boolean {
    return (!this.email && !this.phone) || this.customerType === "partial";
  }
}
