import { Driver } from "shared/types/marketplace";

export default function getPixDriver(drivers: Driver[]): Driver | null {
  for (const driver of drivers) {
    if (driver.name === "ITAU" && driver.methods.length > 0) {
      for (const method of driver.methods) {
        if (method.name === "PIX") {
          return driver;
        }
      }
    }
  }

  return null;
}
