import {
  IconButton,
  useColorModeValue,
  Stack,
  StackProps,
  StackDivider,
} from "@chakra-ui/react";
import React from "react";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import modules from "routes/modules";
import NavItem from "./NavItem";
import { useSidebarContext } from "./SidebarContext";

interface SidebarProps extends StackProps {}

const SidebarContent: React.FC<React.PropsWithChildren<SidebarProps>> = ({
  ...rest
}) => {
  const { isExpanded, toggleExpanded, expandedSize, shrinkedSize } =
    useSidebarContext();

  return (
    <Stack
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={isExpanded ? expandedSize : shrinkedSize}
      transition="all .5s ease"
      h="100%"
      pos="absolute"
      left="0"
      bottom="0"
      divider={<StackDivider />}
      {...rest}
    >
      <IconButton
        aria-label="Expandir barra lateral"
        icon={isExpanded ? <FaAnglesLeft /> : <FaAnglesRight />}
        alignSelf="flex-end"
        mt="auto"
        variant="link"
        w="100%"
        onClick={toggleExpanded}
      />
      <Stack>
        {modules.map((module) => (
          <NavItem key={module.name} appModule={module} />
        ))}
      </Stack>
    </Stack>
  );
};

export default SidebarContent;
