import { Button, DrawerBody, Stack } from "@chakra-ui/react";
import { useChangePlanModalContext } from "../ModalContext";
import PlansDetails from "./PlansDetails";

const Confirmation = () => {
  const { loading, handleSubmit } = useChangePlanModalContext();

  return (
    <DrawerBody scrollBehavior="auto">
      <Stack py="1rem" minH="100%">
        <PlansDetails />

        <Button
          mt="auto"
          colorScheme="yooga"
          isLoading={loading}
          onClick={handleSubmit}
        >
          Confirmar Mudanças
        </Button>
      </Stack>
    </DrawerBody>
  );
};

export default Confirmation;
