import {
  Button,
  ButtonGroup,
  Divider,
  DrawerBody,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Plan } from "shared/models/plan";
import { useContractModalContext } from "../ModalContext";
import AddedServicesList from "./AddedServicesList";

interface ServicesProps {}

const Services: React.FC<ServicesProps> = () => {
  const {
    nextStep,
    product,
    selectedPlan,
    setSelectedPlan,
    addedProducts,
    addPlan,
    contract,
    periodicity,
    setPeriodicity,
  } = useContractModalContext();

  return (
    <DrawerBody scrollBehavior="auto">
      <Stack py="1rem" minH="100%">
        <Heading mb="0.5rem" size="sm">
          Selecione uma periodicidade
        </Heading>

        <ButtonGroup isAttached>
          <Button
            onClick={() => setPeriodicity("monthly")}
            colorScheme={periodicity === "monthly" ? "yooga" : "gray"}
            isDisabled={!!addedProducts.length}
            w="6rem"
          >
            Mensal
          </Button>
          <Button
            onClick={() => setPeriodicity("yearly")}
            colorScheme={periodicity === "yearly" ? "yooga" : "gray"}
            isDisabled={!!addedProducts.length}
            w="6rem"
          >
            Anual
          </Button>
        </ButtonGroup>

        <Text
          fontSize="0.9rem"
          color="gray.500"
          visibility={!!addedProducts.length ? "visible" : "hidden"}
        >
          Para trocar de periodicidade retire os produtos da lista.
        </Text>

        <Divider m="0.5rem 0 1rem" />

        <HStack align="flex-end">
          <FormControl>
            <FormLabel>Plano</FormLabel>
            <Select
              value={selectedPlan ? selectedPlan.id : "-1"}
              onChange={(e) =>
                setSelectedPlan(
                  product?.plans.find(
                    (plan) => plan.id === Number(e.target.value)
                  )
                )
              }
            >
              <option value="-1">Selecione um plano</option>
              {product?.plans
                .filter((plan) => plan.periodicity === periodicity)
                .map((plan) => (
                  <option
                    key={plan.id}
                    value={plan.id}
                    disabled={addedProducts.some(
                      (product) => product.plan?.id === plan.id
                    )}
                  >
                    {plan.name} - {plan.priceString}
                  </option>
                ))}
            </Select>
          </FormControl>

          <Button
            colorScheme="yooga"
            isDisabled={!selectedPlan}
            minW="fit-content"
            onClick={() => addPlan(selectedPlan as Plan)}
          >
            Adicionar
          </Button>
        </HStack>

        <AddedServicesList showRemove showDiscount={!contract} />

        <Button
          mt="auto"
          colorScheme="yooga"
          onClick={nextStep}
          isDisabled={!addedProducts.length}
        >
          Continuar
        </Button>
      </Stack>
    </DrawerBody>
  );
};

export default Services;
