import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Highlight,
  HStack,
  Text,
  Icon,
} from "@chakra-ui/react";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineWarningAmber } from "react-icons/md";
import { Instability } from "shared/types/instability";
import VersionBadge from "./VersionBadge";

interface TemplateProps {
  instability: Instability;
}

const Template: React.FC<TemplateProps> = ({ instability }) => {
  return (
    <Card>
      <CardHeader>
        <HStack>
          <Icon
            color="red"
            aria-label="instability"
            as={MdOutlineWarningAmber}
          />
          <Text fontSize="sm">{instability.date.format("DD/MM/YYYY")}</Text>
        </HStack>

        {instability.dateSolved && (
          <HStack>
            <Icon color="green" aria-label="instability" as={GrStatusGood} />
            <Text fontSize="sm">
              {instability.dateSolved.format("DD/MM/YYYY")}
            </Text>
          </HStack>
        )}

        {/* TITLE */}
        <Heading size="md">{instability.title}</Heading>

        {/* place the badges here */}
        <HStack mt="0.5rem">
          {instability.version && (
            <VersionBadge version={instability.version} />
          )}
        </HStack>
      </CardHeader>

      {/* THE BODY CAN BE TEXT OR ANY COMPONENT */}
      <CardBody>
        <Highlight
          query={instability.highlight ?? []}
          styles={{ px: "0.5", py: "0.5", bg: "yooga.50" }}
        >
          {instability.content}
        </Highlight>
      </CardBody>
    </Card>
  );
};

export default Template;
