import { useGlobalContext } from "GlobalContext";
import moment from "moment";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PatchOrderRequestData } from "services/interfaces/order";
import { Mixpanel } from "services/mixpanel";
import { cancel, generateInvoice, load, patch, payWithCard, payWithMarvin } from "services/order";
import { load as loadTransactions } from "services/transaction";
import { Order } from "shared/models/order";
import { PagarmeTransaction } from "shared/models/transaction";
import OrderModal from "./OrderModal";

interface OrderModalProps {
  isOpen: boolean;
  onClose(): void;
  token?: string;
}

interface OrderModalContextData {
  loading: boolean;
  setLoading(b: boolean): void;
  loadOrder(): void;
  order?: Order;
  editing: boolean;
  startEditing(): void;
  abortEditing(): void;
  transactions: PagarmeTransaction[];
  inputDueDate: string;
  setInputDueDate(s: string): void;
  inputPrice: string;
  setInputPriceState(n: number | string): void;
  inputMethod: 1 | 2;
  setInputMethod(n: 1 | 2): void;
  submitForm(): void;
  processPayment(): void;
  processMarvinPayment(): void;
  cancelOrder(): void;
  generateOrderInvoice(): void;
}

const Context = createContext<OrderModalContextData>({} as OrderModalContextData);

export const useOrderModalContext = () => useContext(Context);

const AddServicesContext: React.FC<OrderModalProps> = ({ token, isOpen, onClose: onCloseProp }) => {
  const { confirm } = useGlobalContext();
  const { loadCustomer } = useCustomerContext();

  const [order, setOrder] = useState<Order>();
  const [transactions, setTransactions] = useState<PagarmeTransaction[]>([]);

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [inputDueDate, setInputDueDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [inputPrice, setInputPrice] = useState<string>("");
  const [inputMethod, setInputMethod] = useState<1 | 2>(1);

  const onClose = useCallback(() => {
    setLoading(false);
    setEditing(false);

    setOrder(undefined);
    onCloseProp();
  }, [onCloseProp]);

  const loadOrder = useCallback(() => {
    if (!token) return;
    setLoading(true);
    load(token)
      .then((response) => {
        setOrder(new Order(response.data));

        if (response?.data?.bank_id === 1) {
          loadTransactions(token)
            .then((res) => setTransactions(res.data.map((data) => new PagarmeTransaction(data))))
            .catch(() => console.error(`Could not load activity history for order ${token}`));
        }
      })
      .catch(() => {
        toast.error("Não foi possível carregar a cobrança");
        onClose();
      })
      .finally(() => setLoading(false));
  }, [token, onClose]);

  const abortEditing = () => {
    setEditing(false);
    setInputDueDate(moment().format("YYYY-MM-DD"));
    setInputPriceState("");
    setInputMethod(1);
  };

  const startEditing = () => {
    setInputDueDate(order!.due_date!.format("YYYY-MM-DD"));
    setInputPriceState(order!.price!);
    setInputMethod(order?.bankId === 3 ? 1 : order?.bankId!);
    setEditing(true);
  };

  const setInputPriceState = (value: number | string) => {
    const v = typeof value === "string" ? Number(String(value).replace(/\D/g, "")) / 100 : value;
    setInputPrice(v.toFixed(2).replace(".", ","));
  };

  const submitForm = () => {
    const payload: PatchOrderRequestData = {
      id: order?.id!,
      bank_id: inputMethod,
      due_date: inputDueDate,
      price: Number(inputPrice.replace(",", ".")),
    };

    Mixpanel.track("customers-financial-order-submitted-form");

    setLoading(true);
    patch(payload)
      .then(() => {
        loadOrder();
        abortEditing();
        loadCustomer();
        toast.success("Edição salva com sucesso");
      })
      .catch(() => {
        toast.error("Não foi possivel salvar as alterações");
        setLoading(false);
      });
  };

  const processPayment = () => {
    confirm("Retentar cobrança", "Deseja continuar?", () => {
      Mixpanel.track("customers-financial-order-clicked-try-pay");
      setLoading(true);
      payWithCard(order?.token!)
        .then(() => {
          setTimeout(() => {
            toast.success("Nova tentativa realizada");
            loadOrder();
          }, 2000);
        })
        .catch(() => {
          toast.error("Não foi possível retentar o pagamento");
          setLoading(false);
        });
    });
  };

  const cancelOrder = () => {
    confirm("Cancelar cobrança", "Deseja continuar?", () => {
      Mixpanel.track("customers-financial-order-clicked-cancel");
      setLoading(true);
      cancel(order?.id!)
        .then(() => {
          toast.success("Cobrança cancelada");
          loadOrder();
        })
        .catch(() => {
          toast.error("Não foi possível cancelar a cobrança");
          setLoading(false);
        });
    });
  };

  const generateOrderInvoice = () => {
    Mixpanel.track("customers-financial-order-clicked-generate-invoice");
    setLoading(true);
    generateInvoice(order?.token!)
      .then(() => {
        setTimeout(() => {
          toast.success("Boleto gerado");
          loadOrder();
        }, 3000);
      })
      .catch(() => {
        toast.error("Falha ao gerar o boleto");
        setLoading(false);
      });
  };

  const processMarvinPayment = () => {
    confirm("Cobrar com Marvin", "Deseja continuar?", () => {
      setLoading(true);
      payWithMarvin(order?.token!)
        .then(() => {
          setTimeout(() => {
            toast.success("Pagamento Marvin enviado");
            loadOrder();
          }, 2000);
        })
        .catch(() => {
          toast.error("Falha ao realizar pagamento");
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  const data: OrderModalContextData = {
    loading,
    setLoading,
    loadOrder,
    order,
    editing,
    startEditing,
    abortEditing,
    transactions,
    inputDueDate,
    setInputDueDate,
    inputPrice,
    setInputPriceState,
    inputMethod,
    setInputMethod,
    submitForm,
    processPayment,
    cancelOrder,
    generateOrderInvoice,
    processMarvinPayment,
  };

  return (
    <Context.Provider value={data}>
      <OrderModal isOpen={isOpen} onClose={onClose} />
    </Context.Provider>
  );
};

export default AddServicesContext;
