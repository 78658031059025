import { defineStyleConfig } from "@chakra-ui/react";

export default defineStyleConfig({
  baseStyle: {
    borderRadius: "5px",
    padding: "16px 24px",
    gap: "8px",
    fontWeight: 600,
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
  },
  variants: {
    filled: {
      bg: "yooga.500",
      color: "white",
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "default",
        },
      },
    },
    outlined: {
      border: "1px",
      bg: "transparent",
      borderColor: "yooga.500",
      color: "yooga.600",

      _hover: {
        bg: "yooga.100",
      },
    },
  },
});
