import moment, { Moment } from "moment";
import { PaymentTag } from "shared/types/payment";
import { Periodicity } from "shared/types/periodicity";
import { IOrder, Order } from "./order";
import { IProduct, Product } from "./product";

export interface IContract {
  id?: number;
  services?: IProduct[];
  customer_name?: string;
  customer_document?: string;
  customer_id?: number;
  expiration_date?: string;
  payment_method?: PaymentTag;
  orders: IOrder[];
  migrated: boolean;
  periodicity?: Periodicity;
  bank_id: 1 | 2;
  status?: number;
  due_date?: string;
}

export class Contract {
  id?: number;

  products: Product[] = [];

  orders: Order[] = [];

  customer_name?: string;

  customer_document?: string;

  customer_id?: number;

  expiration_date?: Moment;

  payment_method?: PaymentTag;

  migrated = false;

  periodicity: Periodicity;

  bankId: 1 | 2;

  status?: number;

  due_date?: Moment;

  constructor(data: IContract) {
    this.id = data.id;
    this.customer_name = data.customer_name;
    this.customer_document = data.customer_document;
    this.customer_id = data.customer_id;
    this.expiration_date = moment(data.expiration_date);
    this.payment_method = data.payment_method;
    this.migrated = data.migrated ?? this.migrated;
    this.periodicity = data.periodicity ?? "monthly";
    this.bankId = data.bank_id;
    this.status = data.status;
    this.due_date = moment(data.due_date);

    data.orders?.forEach((order) => this.orders.push(new Order(order)));
    data.services?.forEach((product) =>
      this.products.push(new Product(product))
    );
  }

  get isCreditCard(): boolean {
    return this.bankId === 1;
  }

  get isInvoice(): boolean {
    return this.bankId === 2;
  }

  get selectedPeriodicity() {
    return this.periodicity === "monthly" ? "Mensal" : "Anual";
  }

  get paymentMethodName() {
    return this.bankId === 1 ? "Cartão de crédito" : "Boleto Bancário";
  }

  get nextOrderWithInvoice(): Order | undefined {
    if (this.orders) {
      for (const order of this.orders) {
        if (order.statusId !== 2 && order.isInvoice && order.invoiceLink) {
          return order;
        }
      }
    }

    return undefined;
  }

  get installmentsNumber(): number | undefined {
    if (this.periodicity === "monthly") return undefined;
    if (this.orders.length < 1) return 0;

    return this.orders[this.orders.length - 1]?.cycle;
  }
}
