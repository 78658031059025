import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useEffect, useRef } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import { useDebounce } from "shared/hooks/useDebounce";
import { useHomeContext } from "../HomeContext";

const SearchBar = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchText, setSearchText } = useGlobalContext();
  const { search, setIsBarFocused } = useHomeContext();
  const debouncedSearch = useDebounce(search, 300);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  };

  const onFocus = () => {
    debouncedSearch(searchText);
    setIsBarFocused(true);
  };

  const onBlur = () => {
    setIsBarFocused(false);
  };

  const toggleIDISearch = (b: boolean) => {
    let newSearchText = searchText;
    if (!b && searchText.startsWith("idi:"))
      newSearchText = newSearchText.slice(4);
    else if (b && !searchText.startsWith("idi:"))
      newSearchText = `idi:${newSearchText}`;

    setSearchText(newSearchText);
    search(newSearchText);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <InputGroup
      zIndex="2"
      display="flex"
      alignItems="stretch"
      shadow="0 6px 12px #00000022"
      rounded="5px"
    >
      <InputLeftElement top="50%" transform="translateY(-50%)">
        <IoSearchOutline />
      </InputLeftElement>

      <Input
        pt="2rem"
        pb="2rem"
        rounded="5px"
        variant="default"
        placeholder="Pesquisar cliente..."
        value={searchText}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputRef}
      />

      <InputRightAddon p="0" h="100%">
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<FaChevronDown />}
            rounded="0 5px 5px 0"
            h="100%"
            size="sm"
          >
            {searchText.startsWith("idi:") ? "IDI" : "Geral"}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => toggleIDISearch(false)}>Geral</MenuItem>
            <MenuItem onClick={() => toggleIDISearch(true)}>
              Apenas IDI
            </MenuItem>
          </MenuList>
        </Menu>
      </InputRightAddon>
    </InputGroup>
  );
};

export default SearchBar;
