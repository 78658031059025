import {
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FaPercent } from "react-icons/fa6";
import { R$ } from "shared/helpers/currency";
import { onlyNumbers } from "shared/helpers/string";
import { Discount } from "shared/models/discount";
import { Product } from "shared/models/product";
import { useContractModalContext } from "../ModalContext";

interface DiscountModalProps {
  product?: Product;
  onClose(): void;
  onSuccess(p: Product, d: Discount): void;
}

const DiscountModal: React.FC<DiscountModalProps> = ({
  product,
  onClose: onCloseFromProps,
  onSuccess,
}) => {
  const { periodicity } = useContractModalContext();

  const [discountValue, setDiscountValue] = useState(0);
  const [type, setType] = useState<"percentage" | "flat">("percentage");
  const [cycles, setCycles] = useState("");

  const priceWithDiscount = useMemo(() => {
    const discount =
      type === "percentage" ? (discountValue * product?.plan?.value!) / 100 : discountValue;

    return product?.plan?.value! - discount;
  }, [product, type, discountValue]);

  const setDiscountValueState = (value: number | string, overrideType?: "percentage" | "flat") => {
    const t = overrideType ?? type;
    const hasDecimal = typeof value === "string" && value.includes(",");
    let v = typeof value === "number" ? value : Number(onlyNumbers(value));

    if (hasDecimal) v = v / 100;

    if (t === "percentage" && v > 100) v = 100;
    if (t === "flat" && v > product?.plan?.value!) v = product?.plan?.value!;

    setDiscountValue(v);
  };

  const submit = () => {
    const discount = new Discount({
      product_total_value: product?.plan?.value,
      product_value_with_discount: priceWithDiscount,
      cycles: !!cycles ? Number(cycles) : 1,
      discount_value: Number(discountValue),
      discount_type: type === "percentage" ? "%" : "R$",
    });

    onSuccess(product!, discount);
    onClose();
  };

  const onClose = () => {
    setDiscountValue(0);
    setType("percentage");
    setCycles("");
    onCloseFromProps();
  };

  useEffect(() => {
    setDiscountValueState(discountValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (product?.discount) {
      setDiscountValueState(
        product?.discount?.discount_value!,
        product?.discount?.discountTypeName
      );
      setType(product?.discount?.discountTypeName);
      setCycles(String(product?.discount?.cycles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <Modal isOpen={!!product} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader pb="0">
          <Stack align="center">
            <Heading size="md">Aplicar desconto</Heading>
            <Text fontSize="0.9rem" color="gray.500">
              Você selecionou o produto:
            </Text>
            <HStack w="100%" justify="space-between">
              <Text fontSize="0.9rem">
                {product?.plan?.name} - {product?.plan?.name}
              </Text>
              <Text fontSize="0.9rem" color="gray.500">
                Valor sem desconto: {R$(product?.plan?.value)}
              </Text>
            </HStack>
          </Stack>

          <Divider mt="1rem" />
        </ModalHeader>

        <ModalBody>
          <HStack align="flex-end">
            <FormControl>
              <FormLabel fontSize="0.9rem" color="gray.500">
                Valor do Desconto
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>{type === "percentage" ? "%" : "R$"}</InputLeftAddon>
                <Input
                  value={
                    type === "flat" ? discountValue.toFixed(2).replace(".", ",") : discountValue
                  }
                  onChange={(e) => {
                    setDiscountValueState(e.target.value);
                  }}
                />
              </InputGroup>
            </FormControl>

            <ButtonGroup isAttached>
              <IconButton
                variant={type === "percentage" ? "filled" : "outline"}
                onClick={() => setType("percentage")}
                aria-label="Porcentagem"
                icon={<FaPercent />}
              />
              <Button
                variant={type === "flat" ? "filled" : "outline"}
                onClick={() => setType("flat")}
                px="0"
              >
                R$
              </Button>
            </ButtonGroup>
          </HStack>

          <Collapse in={!!discountValue}>
            {periodicity === "yearly" ? (
              <Text mt="1rem" fontSize="0.9rem">
                Os ciclos estão desabilitados para a opção anual
              </Text>
            ) : (
              <FormControl mt="1rem">
                <FormLabel fontSize="0.9rem" color="gray.500">
                  Ciclos
                </FormLabel>
                <Input
                  value={cycles}
                  onChange={(e) => setCycles(e.target.value.replace(/\D/g, ""))}
                />
              </FormControl>
            )}

            <Divider mt="1.5rem" mb="1rem" />

            <Stack align="center">
              <Text fontWeight="bold">Valor com desconto aplicado:</Text>
              <Text>{R$(priceWithDiscount)}</Text>
            </Stack>
          </Collapse>
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={!discountValue} onClick={submit} variant="filled" w="100%">
            Aplicar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DiscountModal;
