import {
  Button,
  DrawerBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useContractModalContext } from "../ModalContext";

const Payment = () => {
  const {
    nextStep,
    paymentDay,
    setPaymentDay,
    paymentMethod,
    setPaymentMethod,
  } = useContractModalContext();
  const [dayTip, setDayTip] = useState("");

  const paymentDays = [5, 10, 15, 20];

  const getValidPaymentDay = (paymentDay: number) => {
    const validDays = [5, 10, 15, 20];
    for (let day of validDays) {
      if (paymentDay <= day) {
        return day;
      }
    }
    return 5;
  };

  const updateDayTip = useCallback(() => {
    const today = new Date().getDate();
    const payDate = new Date();

    if (paymentDay) {
      payDate.setDate(getValidPaymentDay(paymentDay));

      if (Number(paymentDay) < today) {
        payDate.setMonth(payDate.getMonth() + 1);
      }
    }

    setDayTip(
      `Primeira cobrança dia ${payDate.toLocaleString("pt", {
        day: "numeric",
        month: "short",
      })}`
    );
  }, [paymentDay]);

  useEffect(() => {
    updateDayTip();
  }, [updateDayTip]);

  return (
    <DrawerBody scrollBehavior="auto">
      <Stack py="1rem" minH="100%">
        <FormControl mb="1rem">
          <FormLabel htmlFor="select-payment-method">
            <Text fontWeight="bold" fontSize="0.9rem">
              Forma de pagamento:
            </Text>
          </FormLabel>
          <Select
            id="select-payment-method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(Number(e.target.value) as 1 | 2)}
          >
            <option value="1">Cartão de Crédito</option>
            <option value="2">Boleto Bancário</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="select-due-date">
            <Text fontWeight="bold" fontSize="0.9rem">
              Dia do vencimento:
            </Text>
          </FormLabel>
          <Select
            id="select-due-date"
            value={paymentDay}
            onChange={(e) => setPaymentDay(Number(e.target.value))}
          >
            {paymentDays.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </Select>
          <FormHelperText>{dayTip}</FormHelperText>
        </FormControl>

        <Button mt="auto" colorScheme="yooga" onClick={nextStep}>
          Continuar
        </Button>
      </Stack>
    </DrawerBody>
  );
};

export default Payment;
