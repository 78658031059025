import moment, { Moment } from "moment";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getStorageItem, setStorageItem } from "services/localStorage";
import { Mixpanel } from "services/mixpanel";
import { FeatureUpdate } from "shared/types/featureUpdate";
import FeatureHistoryButton from "./components/FeatureHistoryButton";
import FeatureHistoryModal from "./components/FeatureHistoryModal";
import updates from "./updates";

interface FeatureHistoryContextData {
  updates: FeatureUpdate[];
  hasUnseenUpdates: boolean;
  openUpdatesModal(): void;
}

const Context = createContext<FeatureHistoryContextData>(
  {} as FeatureHistoryContextData
);

export const useFeatureHistoryContext = () => useContext(Context);

const FeatureHistoryContext = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [lastOpen, setLastOpen] = useState<Moment>();

  const hasUnseenUpdates = useMemo(() => {
    const lastUpdate = updates[updates.length - 1]?.date;
    return !lastOpen || lastOpen.isBefore(lastUpdate);
  }, [lastOpen]);

  const openUpdatesModal = () => {
    setLastOpen(moment());
    setStorageItem("lastOpenUpdates", moment().toISOString());
    setModalOpen(true);
  };

  const data = useMemo(
    () => ({
      updates,
      hasUnseenUpdates,
      openUpdatesModal,
    }),
    [hasUnseenUpdates]
  );

  useEffect(() => {
    if (modalOpen) Mixpanel.track("customers-feature-history-opened-modal");
  }, [modalOpen]);

  useEffect(() => {
    const lastDate = getStorageItem("lastOpenUpdates");
    if (lastDate) setLastOpen(moment(lastDate));
  }, []);

  return (
    <Context.Provider value={data}>
      <FeatureHistoryButton />
      <FeatureHistoryModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Context.Provider>
  );
};

export default FeatureHistoryContext;
