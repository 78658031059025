import { createContext, useContext, useMemo, useState } from "react";
import { Instability } from "shared/types/instability";
import InstabilityButton from "./components/InstabilityButton";
import InstabilityModal from "./components/InstabilityModal";
import instabilities from "./instabilities";

interface InstabilityContextData {
  instabilities: Instability[];
  hasActiveInstabilities: boolean;
  openInstabilityModal(): void;
}

const Context = createContext<InstabilityContextData>(
  {} as InstabilityContextData
);

export const useInstabilityContext = () => useContext(Context);

const InstabilityContext = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const hasActiveInstabilities = useMemo(
    () => instabilities.some((instability) => !instability.dateSolved),
    []
  );

  const openInstabilityModal = () => {
    setModalOpen(true);
  };

  const data = useMemo(
    () => ({
      instabilities,
      hasActiveInstabilities,
      openInstabilityModal,
    }),
    [hasActiveInstabilities]
  );

  return (
    <Context.Provider value={data}>
      <InstabilityButton />
      <InstabilityModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Context.Provider>
  );
};

export default InstabilityContext;
