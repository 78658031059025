import {
  Card,
  CardHeader,
  Heading,
  HStack,
  Icon,
  ListItem,
  Stack,
  Text,
  List,
  ListIcon,
  CardBody,
} from "@chakra-ui/react";
import { IoCopyOutline } from "react-icons/io5";
import { FaRegFileLines, FaCircleCheck } from "react-icons/fa6";
import { useAppTabContext } from "../AppContext";
import { copyToClipboard } from "shared/helpers/clipboard";
import { FiEdit } from "react-icons/fi";
import { Mixpanel } from "services/mixpanel";
import ProtectedButton from "shared/components/ProtectedButton";

const Fiscal = () => {
  const { app, openEditFiscalModal } = useAppTabContext();

  const copyToken = () => {
    Mixpanel.track("customers-app-fiscal-clicked-copy-token");
    copyToClipboard(app.fiscalToken, "Token copiado");
  };

  return (
    <Card>
      <CardHeader pb="0">
        <HStack justify="space-between">
          <HStack>
            <Icon as={FaRegFileLines} />
            <Heading size="sm">Fiscal</Heading>
          </HStack>
          <ProtectedButton
            permission="installation-card-fiscal-update"
            leftIcon={<FiEdit />}
            variant="ghost"
            size="sm"
            py="1.5rem"
            onClick={openEditFiscalModal}
          >
            Editar
          </ProtectedButton>
        </HStack>
      </CardHeader>

      <CardBody>
        {!!app.fiscal ? (
          <Stack>
            <Text fontWeight="500" color="gray.500">
              Módulos
            </Text>
            <List spacing="0.2rem">
              {!!app.fiscal && (
                <ListItem fontSize="0.9rem" fontWeight="300">
                  <ListIcon as={FaCircleCheck} color="green.500" />
                  Emissão de NF-e
                </ListItem>
              )}

              {!!app.fiscalToken && (
                <ListItem fontSize="0.9rem" fontWeight="300">
                  <ListIcon as={FaCircleCheck} color="green.500" />
                  Emissão de NFC-e
                </ListItem>
              )}
            </List>
          </Stack>
        ) : (
          <Text fontWeight="500" color="gray.500">
            App sem emissão de nota
          </Text>
        )}

        {!!app.fiscalToken && (
          <Stack mt="1rem">
            <Text fontWeight="500" color="gray.500">
              Token
            </Text>
            <HStack
              p="0.5rem"
              bg="blackAlpha.200"
              rounded="5px"
              cursor="pointer"
              justify="space-around"
              onClick={copyToken}
            >
              <Text w="90%" fontSize="0.9rem">
                {app.fiscalToken}
              </Text>
              <Icon aria-label="Copiar token" as={IoCopyOutline} />
            </HStack>
          </Stack>
        )}
      </CardBody>
    </Card>
  );
};

export default Fiscal;
