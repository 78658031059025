import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export default defineStyleConfig({
  variants: {
    label: (props) => ({
      fontWeight: "500",
      color: mode("gray.600", "gray.400")(props),
      fontSize: "0.8rem",
    }),
  },
});
