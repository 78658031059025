import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useState } from "react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { toast } from "react-toastify";
import { blockAllApps } from "services/app";
import { Mixpanel } from "services/mixpanel";
import { formatDocument } from "shared/helpers/document";
import { usePermissions } from "shared/hooks/usePermissions";
import { useCustomerContext } from "../CustomerContext";

const HeaderOptions = () => {
  const { e } = usePermissions();
  const { info, loadApps, customer } = useCustomerContext();
  const { confirmByText } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const blockApps = () => {
    confirmByText(
      `Bloquear TODOS os Apps do documento ${formatDocument(info.document)}`,
      "Digite CONFIRMAR para continuar",
      () => {
        Mixpanel.track("customers-customer-clicked-block-all-apps");
        setLoading(true);
        blockAllApps(info.document, customer.id)
          .then(() => {
            toast.success("Apps do documento bloqueados");
            loadApps();
          })
          .catch(() => {
            toast.error("Falha ao bloquear todos os apps");
          })
          .finally(() => setLoading(false));
      }
    );
  };

  return info.yooga_apps?.length > 0 &&
    e("installation-card-details-block-update") ? (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        aria-label="options"
        icon={<FaEllipsisVertical />}
      />
      <MenuList>
        <MenuItem
          as={Button}
          isLoading={loading}
          onClick={blockApps}
          closeOnSelect={false}
          fontWeight="500"
          size="sm"
        >
          Bloquear todos os Apps
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <></>
  );
};

export default HeaderOptions;
