import { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from "@chakra-ui/react";
import { Order } from "shared/models/order";
import { OrdersGroupedByYear } from "shared/types/orderGroups";
import OrderComponent from "./OrderComponent";
import moment from "moment";

interface OrdersProps {
  list: Order[];
}

const Orders: React.FC<OrdersProps> = ({ list }) => {
  const [groups, setGroups] = useState<OrdersGroupedByYear>([]);

  const indexes = useMemo(
    () =>
      groups
        .map((group, idx) => (group.expanded ? idx : -1))
        .filter((idx) => idx !== -1),
    [groups]
  );

  const onClickGroup = (indexes: number[]) => {
    setGroups(
      groups.map((group, idx) => ({
        ...group,
        expanded: indexes.includes(idx),
      }))
    );
  };

  useEffect(() => {
    const groupsAux: OrdersGroupedByYear = [];

    list.forEach((order) => {
      const year = order.due_date?.year() as number;
      groupsAux[year] = groupsAux[year] ?? {
        year,
        expanded: year === moment().year(),
        orders: [],
      };
      groupsAux[year].orders.push(order);
    });

    setGroups(Object.values(groupsAux));
  }, [list]);

  return (
    <Accordion allowMultiple index={indexes} onChange={onClickGroup}>
      {groups.map((group) => (
        <AccordionItem key={group.year}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {group.year}
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel
            pb={4}
            as={HStack}
            wrap="wrap"
            columnGap="1rem"
            rowGap="2rem"
            align="stretch"
          >
            {group.orders.map((order) => (
              <OrderComponent key={order.id} order={order} />
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default Orders;
