import {
  CardBody,
  Heading,
  HStack,
  Icon,
  Tag,
  TagLabel,
  chakra,
  TagLeftIcon,
  IconButton,
  Stack,
  Text,
  Divider,
  ListItem,
  UnorderedList,
  StackDivider,
  CardHeader,
  Card,
  Flex,
} from "@chakra-ui/react";
import { FaComputer, FaRegCircleUser, FaUnlock, FaCircle } from "react-icons/fa6";
import { IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "shared/helpers/clipboard";
import { FiEdit } from "react-icons/fi";
import { useAppTabContext } from "../AppContext";
import { useState } from "react";
import { blockApp, unlockTrustly, unlockTemporary } from "services/app";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { toast } from "react-toastify";
import { useGlobalContext } from "GlobalContext";
import axios from "axios";
import ProtectedButton from "shared/components/ProtectedButton";
import { Mixpanel } from "services/mixpanel";
import { FaLock } from "react-icons/fa";
import moment from "moment";

const AppDetails = () => {
  const { confirm } = useGlobalContext();
  const { loadApps, customer } = useCustomerContext();
  const { app, openEditAppDetailsModal } = useAppTabContext();
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { openAppStatusHistoryModal } = useAppTabContext();

  const copyIDI = () => {
    Mixpanel.track("customers-app-details-clicked-copy-idi");
    copyToClipboard(app.idi, "IDI Copiado");
  };

  const onClickUnlock48 = () => {
    confirm("Deseja desbloquer o app?", "Esta ação só pode ser executada uma vez ao mês", () => {
      Mixpanel.track("customers-app-details-clicked-unlock");
      setLoadingUnlock(true);
      unlockTrustly(app.idi)
        .then(() => {
          toast.success("App desbloqueado por 48 horas");
          loadApps();
        })
        .catch((err) => {
          let message = "Falha ao desbloquear por 48 horas";
          if (axios.isAxiosError(err) && err.response?.data?.message)
            message = err.response?.data?.message;

          toast.error(message);
        })
        .finally(() => setLoadingUnlock(false));
    });
  };

  const onClickUnlockTemporary = () => {
    confirm("Deseja desbloquer o app?", "O app será desbloqueado até a próxima automação", () => {
      Mixpanel.track("customers-app-details-clicked-unlock");
      setLoadingUnlock(true);
      unlockTemporary(app.idi, customer.id)
        .then(() => {
          toast.success("App desbloqueado temporariamente");
          loadApps();
        })
        .catch(() => toast.error("Falha ao desbloquear temporariamente"))
        .finally(() => setLoadingUnlock(false));
    });
  };

  const onClickBlock = () => {
    confirm(`Bloquear o App ${app.idi}`, "Deseja continuar?", () => {
      Mixpanel.track("customers-app-details-clicked-lock");
      setLoadingBlock(true);
      blockApp(app.idi, customer.id)
        .then(() => {
          toast.success("App bloqueado");
          loadApps();
        })
        .catch(() => {
          toast.error("Falha ao bloquear App");
        })
        .finally(() => setLoadingBlock(false));
    });
  };

  return (
    <Card>
      <CardHeader>
        <Flex
          justify="space-between"
          direction={{ base: "column", md: "row" }}
          gap={{ base: "1rem", md: "0.5rem" }}
        >
          <HStack align="center" wrap="wrap" gap="1rem">
            <HStack align="center">
              <Icon as={FaComputer} />
              <Heading size="sm">{app.nickname}</Heading>
            </HStack>
            <Tag variant="outline" rounded="full" color="yooga.400">
              <TagLeftIcon as={FaRegCircleUser} />
              <TagLabel>
                IDI APP: <chakra.span color="gray.500">{app.idi}</chakra.span>
              </TagLabel>
              <IconButton
                ml="0.5rem"
                aria-label="Copiar IDI"
                size="xs"
                variant="link"
                icon={<IoCopyOutline />}
                onClick={copyIDI}
              />
            </Tag>
          </HStack>

          <HStack wrap={{ base: "wrap", md: "nowrap" }}>
            <ProtectedButton
              permission="installation-card-details-update"
              leftIcon={<FiEdit />}
              variant="ghost"
              size="sm"
              py="1.5rem"
              onClick={openEditAppDetailsModal}
            >
              Editar
            </ProtectedButton>

            <ProtectedButton
              permission="installation-card-details-block-update"
              leftIcon={<FaLock />}
              variant="filled"
              bg="red.500"
              size="sm"
              py="1.5rem"
              isLoading={loadingBlock}
              isDisabled={
                app.billingStatusV2 === "BLOQUEADO" || app.billingStatusV2 === "BLOQUEADO_TESTE"
              }
              onClick={onClickBlock}
            >
              Bloquear
            </ProtectedButton>

            {moment().isAfter(app.billingUnlock) ? (
              <ProtectedButton
                permission="installation-card-details-unlock-update"
                leftIcon={<FaUnlock />}
                variant="filled"
                size="sm"
                py="1.5rem"
                isDisabled={
                  app.billingStatusV2 !== "BLOQUEADO" && app.billingStatusV2 !== "BLOQUEADO_TESTE"
                }
                isLoading={loadingUnlock}
                onClick={onClickUnlock48}
              >
                Desbloquear por 48h
              </ProtectedButton>
            ) : (
              <ProtectedButton
                permission="installation-card-details-unlock-update"
                leftIcon={<FaUnlock />}
                variant="filled"
                size="sm"
                py="1.5rem"
                isDisabled={
                  app.billingStatusV2 !== "BLOQUEADO" && app.billingStatusV2 !== "BLOQUEADO_TESTE"
                }
                isLoading={loadingUnlock}
                onClick={onClickUnlockTemporary}
              >
                Desbloqueio temporário
              </ProtectedButton>
            )}
          </HStack>
        </Flex>
      </CardHeader>

      <CardBody>
        <Flex align="center" w="100%">
          <Stack flex="1" align="flex-start">
            <Text variant="label">Status financeiro do sistema</Text>
            <Tag
              size="sm"
              rounded="full"
              color={
                app.billingStatusPriority === "LOW"
                  ? "green.400"
                  : app.billingStatusPriority === "MEDIUM"
                  ? "yellow.400"
                  : app.billingStatusPriority === "HIGH"
                  ? "orange.400"
                  : "red.400"
              }
            >
              <TagLeftIcon as={FaCircle} />
              <TagLabel fontWeight="600">{app.billingStatusLabel}</TagLabel>
            </Tag>
          </Stack>

          <ProtectedButton
            permission="installation-card-details-update"
            leftIcon={<FiEdit />}
            variant="ghost"
            size="sm"
            py="1.5rem"
            onClick={openAppStatusHistoryModal}
          >
            Histórico da automação
          </ProtectedButton>
          </Flex>
        <Divider my="1rem" />

        <HStack divider={<StackDivider />} spacing="4" wrap="wrap">
          <Stack flex="1" align="flex-start">
            <Text variant="label">Último desbloqueio de confiança</Text>
            <Text fontSize="0.8rem" fontWeight="300">
              {app.billingUnlock ? app.lastDateTrustUnlock.format("DD/MM/YYYY HH:MM") : "Nunca"}
            </Text>
          </Stack>

          <Stack flex="1" align="flex-start">
            <Text variant="label">Fuso horário</Text>
            <Text fontSize="0.8rem" fontWeight="300">
              {app.timezone}
            </Text>
          </Stack>
        </HStack>

        <Divider my="1rem" />

        <HStack divider={<StackDivider />} spacing="4">
          <Stack flex="1" align="flex-start">
            <Text variant="label">Ponto de venda</Text>
            <Text fontSize="0.8rem" fontWeight="300">
              {app.tableModule ? "Mesas" : "Balcão"}
            </Text>
          </Stack>

          {(!!app.botModule || !!app.emissorModule || !!app.fiscal) && (
            <Stack flex="1" align="flex-start">
              <Text variant="label">Módulos</Text>
              <UnorderedList>
                {!!app.botModule && (
                  <ListItem fontSize="0.8rem" fontWeight="300">
                    ChatBot
                  </ListItem>
                )}
                {!!app.emissorModule && (
                  <ListItem fontSize="0.8rem" fontWeight="300">
                    Emissor
                  </ListItem>
                )}

                {!!app.fiscal && (
                  <ListItem fontSize="0.8rem" fontWeight="300">
                    Fiscal
                  </ListItem>
                )}
              </UnorderedList>
            </Stack>
          )}
        </HStack>
      </CardBody>
    </Card>
  );
};

export default AppDetails;
