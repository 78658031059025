import Header from "shared/components/Header";
import CreatePartialForm from "./CreatePartialForm";
import CreatePartialCustomerContext from "./FormContext";

const CreatePartial = () => {
  return (
    <CreatePartialCustomerContext>
      <Header />
      <CreatePartialForm />
    </CreatePartialCustomerContext>
  );
};

export default CreatePartial;
