export function generatePassword(
  length: number = 10,
  useLowerCase: boolean = true,
  useUpperCase: boolean = true,
  useNumbers: boolean = true,
  useSymbols: boolean = true
) {
  let charset = "";
  let password = "";

  if (useSymbols) charset += "!@#$%^&*()";
  if (useNumbers) charset += "0123456789";
  if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";

  if (charset.length)
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }

  return password;
}
