import { HStack, IconButton, Select, Text, Button } from "@chakra-ui/react";
import { useMemo } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

interface PaginationFooterProps {
  totalLength: number;
  visibleLength: number;
  page: number;
  setPage(n: number): void;
  pageSize: number;
  setPageSize(n: number): void;
}

const PaginationFooter: React.FC<PaginationFooterProps> = ({
  totalLength,
  visibleLength,
  page,
  setPage,
  pageSize,
  setPageSize,
}) => {
  const pageCount = useMemo(
    () => Math.ceil(totalLength / pageSize),
    [totalLength, pageSize]
  );

  return (
    <HStack
      borderColor="gray.200"
      borderWidth="1px"
      justify="space-between"
      p="1rem"
      rounded="5px"
      wrap="wrap"
    >
      <Select
        w="fit-content"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        <option value={10}>Exibir 10 itens</option>
        {totalLength > 10 && <option value={15}>Exibir 15 itens</option>}
        {totalLength > 15 && <option value={20}>Exibir 20 itens</option>}
        {totalLength > 20 && <option value={25}>Exibir 25 itens</option>}
        {totalLength > 25 && <option value={30}>Exibir 30 itens</option>}
      </Select>

      <Text>
        {visibleLength} {visibleLength > 1 ? "itens" : "item"}
      </Text>

      <HStack wrap={{ base: "wrap", sm: "nowrap" }}>
        <IconButton
          variant="ghost"
          rounded="full"
          aria-label="Anterior"
          icon={<FaAngleLeft />}
          onClick={() => setPage(page - 1)}
          isDisabled={page === 1}
        />

        {Array(pageCount)
          .fill(0)
          .map((_, idx) => (
            <Button
              variant={page === idx + 1 ? "filled" : "ghost"}
              colorScheme="gray"
              rounded="full"
              key={idx}
              onClick={() => setPage(idx + 1)}
            >
              {idx + 1}
            </Button>
          ))}

        <IconButton
          variant="ghost"
          rounded="full"
          aria-label="Próximo"
          icon={<FaAngleRight />}
          onClick={() => setPage(page + 1)}
          isDisabled={page === pageCount}
        />
      </HStack>
    </HStack>
  );
};

export default PaginationFooter;
