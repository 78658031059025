import { validateCNPJ, validateCPF } from "shared/helpers/document";
import { validateEmail } from "shared/helpers/email";

export interface IErrors {
  cnpj?: string;
  name?: string;
  contact_email?: string;
  trade_name?: string;
  state_registration?: string;
  city_registration?: string;
  postal_code?: string;
  logradouro?: string;
  numero?: string;
  bairro?: string;
  telephone?: string;
}

export default function validateCustomerForm({
  cnpj,
  name,
  contact_email,
  trade_name,
  state_registration,
  city_registration,
  postal_code,
  logradouro,
  numero,
  bairro,
  telephone,
}: IErrors): IErrors {
  const errors: IErrors = {};

  if (!cnpj) errors.cnpj = "Documento é obrigatório";
  else if (!validateCNPJ(cnpj) && !validateCPF(cnpj))
    errors.cnpj = "Insira um documento válido";

  if (!name) errors.name = "Nome é obrigatório";

  if (!contact_email) errors.contact_email = "Email é obrigatório";
  else if (!validateEmail(contact_email))
    errors.contact_email = "Insira um email válido";

  if (!trade_name) errors.trade_name = "Razão social é obrigatório";

  if (!state_registration) errors.state_registration = "UF é obrigatório";
  else if (state_registration.length !== 2)
    errors.state_registration = "Insira uma UF válida";

  if (!city_registration) errors.city_registration = "Cidade é obrigatório";

  if (!postal_code) errors.postal_code = "CEP é obrigatório";
  else if (postal_code.length !== 9)
    errors.postal_code = "Insira um CEP válido";

  if (!logradouro) errors.logradouro = "Logradouro é obrigatório";

  if (!numero) errors.numero = "Número é obrigatório";

  if (!bairro) errors.bairro = "Bairro é obrigatório";

  if (!telephone) errors.telephone = "Telefone é obrigatório";
  else if (telephone.length !== 10 && telephone.length !== 11)
    errors.telephone = "Insira um telefone válido";

  return errors;
}
