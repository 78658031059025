import { useCustomerContext } from "pages/Customer/CustomerContext";
import { Mixpanel } from "services/mixpanel";
import Banner from "shared/components/Banner";

const IncompleteCustomerBanner = () => {
  const { customer, setEditCustomerModalOpen } = useCustomerContext();

  const action = () => {
    Mixpanel.track("customers-principal-clicked-fill-customer-info");
    setEditCustomerModalOpen(true);
  };

  return customer.isRecordIncomplete ? (
    <Banner
      colorScheme="red"
      content="Cadastro do cliente incompleto"
      subContent="Faltam alguns dados a serem cadastrados"
      action={action}
    />
  ) : (
    <></>
  );
};

export default IncompleteCustomerBanner;
