import { Tooltip } from "@chakra-ui/react";
import { usePermissions } from "shared/hooks/usePermissions";

interface ProtectedComponentProps {
  permission: string;
}

const ProtectedComponent: React.FC<
  React.PropsWithChildren<ProtectedComponentProps>
> = ({ permission, children }) => {
  const { e } = usePermissions();
  const permitted = e(permission);

  return (
    <Tooltip
      label={permitted ? undefined : "Não possui permissão para acessar"}
    >
      {children}
    </Tooltip>
  );
};

export default ProtectedComponent;
