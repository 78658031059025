import { createContext, useContext, useEffect, useState } from "react";
import { listInstallationLogs } from "services/installationLog";
import { InstallationLog } from "shared/models/installationLog";
import { IInstallationLog, InstallationLogType } from "shared/types/installationLog";
import { useAppTabContext } from "../../AppContext";
import Logs from "./Logs";

interface LogsContextData {
  logs: IInstallationLog[];
  isLoadingLogs: boolean;
  search: string;
  setSearch(s: string): void;
  type?: InstallationLogType | undefined;
  setType(c?: InstallationLogType): void;
  page: number;
  setPage(n: number): void;
  pageSize: number;
  setPageSize(n: number): void;
  logsLastPage: number;
}

const Context = createContext<LogsContextData>({} as LogsContextData);

export const useLogContext = () => useContext(Context);

const LogsContext = () => {
  const { app, isLoadingLogs, setIsLoadingLogs } = useAppTabContext();

  const [logs, setLogs] = useState<IInstallationLog[]>([]);
  const [logsLastPage, setLogsLastPage] = useState<number>(1);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(40);

  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<InstallationLogType>();

  useEffect(() => {
    setIsLoadingLogs(true);

    listInstallationLogs(String(app.idi), String(page))
      .then((response) => {
        const installationLog = new InstallationLog(response.data);

        setLogs(installationLog.logs);
        setLogsLastPage(installationLog.last_page);
      })
      .catch((error) => {
        console.error("Falha ao carregar os logs da instalação", error);
      })
      .finally(() => {
        setIsLoadingLogs(false);
      });
  }, [app.idi, page, setIsLoadingLogs]);

  const data: LogsContextData = {
    logs,
    isLoadingLogs,
    search,
    setSearch,
    page,
    setPage,
    pageSize,
    setPageSize,
    logsLastPage,
    type,
    setType,
  };

  return (
    <Context.Provider value={data}>
      <Logs />
    </Context.Provider>
  );
};

export default LogsContext;
