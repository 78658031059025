import {
  Card,
  CardBody,
  CardProps,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdEdit } from "react-icons/md";

interface BannerProps extends CardProps {
  content?: string;
  subContent?: string;
  Icon?: IconType;
  colorScheme?: string;
  action?(): void;
}

const Banner: React.FC<BannerProps> = ({
  content,
  subContent,
  Icon,
  colorScheme = "blue",
  action,
  ...props
}) => {
  return (
    <Card bg={`${colorScheme}.400`} color="white" {...props}>
      <CardBody flexWrap="nowrap">
        <HStack>
          <Stack flex="1">
            <Heading fontSize="1.2rem">{content}</Heading>
            <Text fontSize="0.9rem">{subContent}</Text>
          </Stack>

          <IconButton
            rounded="full"
            color="white"
            bg="blackAlpha.300"
            icon={Icon ? <Icon /> : <MdEdit />}
            aria-label="Banner action"
            onClick={action}
          />
        </HStack>
      </CardBody>
    </Card>
  );
};

export default Banner;
