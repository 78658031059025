import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateApp } from "services/app";
import { Mixpanel } from "services/mixpanel";
import { IApp, App } from "shared/models/app";
import { useAppTabContext } from "../AppContext";

interface EditFiscalModalProps {
  isOpen: boolean;
  onClose(): void;
}

const EditFiscalModal: React.FC<EditFiscalModalProps> = ({
  isOpen,
  onClose: onCloseFromProps,
}) => {
  const { app, setApp } = useAppTabContext();
  const [loading, setLoading] = useState<boolean>(false);

  const [nfeModule, setNfeModule] = useState<boolean>(!!app.fiscal);
  const [nfceModule, setNfceModule] = useState<boolean>(!!app.fiscalToken);
  const [token, setToken] = useState(app.fiscalToken || "");

  const onClose = () => {
    onCloseFromProps();
    setNfeModule(!!app.fiscal);
    setNfceModule(!!app.fiscalToken);
    setToken(app.fiscalToken || "");
  };

  const submit = () => {
    Mixpanel.track("customers-app-fiscal-submitted-edit");

    setLoading(true);

    const payload: IApp = {
      ...app.originalPayload,
      fiscal: nfeModule ? 1 : 0,
      fiscal_token: nfceModule ? token : "",
    };

    updateApp(payload)
      .then((response) => {
        toast.success("Módulo fiscal fiscais atualizado");
        setApp(new App(response.data));
        onClose();
      })
      .catch(() => toast.error("Falha ao atualizar módulo fiscal"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setNfeModule(!!app.fiscal);
    setNfceModule(!!app.fiscalToken);
    setToken(app.fiscalToken || "");
  }, [app]);

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton isDisabled={loading} />
        <ModalHeader>Editar Detalhes do App</ModalHeader>

        <ModalBody>
          <FormControl>
            <FormLabel>Módulos</FormLabel>
            <Stack spacing={1}>
              <Checkbox
                isDisabled={loading}
                isChecked={nfeModule}
                onChange={() => setNfeModule(!nfeModule)}
              >
                Emissão de NF-e
              </Checkbox>
              <Checkbox
                isDisabled={loading || !nfeModule}
                isChecked={nfceModule}
                onChange={() => setNfceModule(!nfceModule)}
              >
                Emissão de NFC-e
              </Checkbox>
            </Stack>
          </FormControl>

          {nfceModule && (
            <FormControl isRequired mt="1rem">
              <FormLabel>Token</FormLabel>
              <Textarea
                resize="none"
                value={token}
                isDisabled={loading}
                onChange={(e) => setToken(e.target.value)}
              />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={nfceModule && !token}
            onClick={submit}
            colorScheme="yooga"
            w="100%"
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditFiscalModal;
