import {
  Flex,
  HStack,
  Stack,
  StackDivider,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatDocument } from "shared/helpers/document";
import { useNavigate } from "react-router-dom";
import { SearchResponse } from "services/interfaces/search";
import { FaCircle } from "react-icons/fa";
import { BillingStatusPriority } from "shared/types/billingStatus";
import { addSearchHistoryItem } from "shared/helpers/searchHistory";
import { useGlobalContext } from "GlobalContext";
import { Mixpanel } from "services/mixpanel";

interface ResultProps {
  result: SearchResponse;
}

const Result: React.FC<ResultProps> = ({ result }) => {
  const stackDivider = useBreakpointValue({
    md: <StackDivider />,
  });
  const navigate = useNavigate();
  const { searchText } = useGlobalContext();
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverColor = useColorModeValue("gray.100", "gray.500");

  const apps = result.yooga_apps.filter((app) => app.type === "app");
  const emissor = result.yooga_apps.filter((app) => app.type === "emissor");

  const onClick = () => {
    Mixpanel.track("customers-home-result-clicked-customer", {
      customer: result.document,
    });
    addSearchHistoryItem(searchText);
    navigate(`/customer/${result.document}`, { state: { result } });
  };

  return (
    <Flex
      onClick={onClick}
      w="100%"
      borderBottom="1px solid"
      borderColor={borderColor}
      p="1rem"
      align="center"
      gap="1rem"
      transition="all .3s ease"
      cursor="pointer"
      wrap="nowrap"
      direction={{ base: "column", sm: "row" }}
      _hover={{
        bg: hoverColor,
      }}
    >
      <Stack gap="0" mr="auto">
        <Text fontWeight="500" fontSize="0.8rem">
          {result.name}
        </Text>
        <Text fontWeight="500" fontSize="0.7rem">
          {result.trade_name}
        </Text>
        <Text fontWeight="300" fontSize="0.7rem">
          {formatDocument(result.document)}
        </Text>
      </Stack>

      <HStack
        divider={<StackDivider />}
        alignSelf="stretch"
        py={{ md: "0.5rem" }}
      >
        <Stack
          divider={stackDivider}
          direction={{ base: "column", md: "row" }}
          alignSelf="stretch"
          align="center"
          justify="center"
        >
          {apps.length &&
            apps.map((item) => (
              <Stack key={item.idi} align="center" w="8rem">
                <Tag
                  size="sm"
                  rounded="full"
                  color={
                    BillingStatusPriority[item.billing_status_v2] === "LOW"
                      ? "green.400"
                      : BillingStatusPriority[item.billing_status_v2] ===
                        "MEDIUM"
                      ? "yellow.400"
                      : BillingStatusPriority[item.billing_status_v2] === "HIGH"
                      ? "orange.400"
                      : "red.400"
                  }
                >
                  <TagLeftIcon as={FaCircle} />
                  <TagLabel fontWeight="600">App {item.idi}</TagLabel>
                </Tag>
              </Stack>
            ))}

          {emissor.length &&
            emissor.map((item) => (
              <Stack key={item.idi} align="center" w="8rem">
                <Tag
                  size="sm"
                  rounded="full"
                  color={
                    BillingStatusPriority[item.billing_status_v2] === "LOW"
                      ? "green.400"
                      : BillingStatusPriority[item.billing_status_v2] ===
                        "MEDIUM"
                      ? "yellow.400"
                      : BillingStatusPriority[item.billing_status_v2] === "HIGH"
                      ? "orange.400"
                      : "red.400"
                  }
                >
                  <TagLeftIcon as={FaCircle} />
                  <TagLabel fontWeight="600">Emissor {item.idi}</TagLabel>
                </Tag>
              </Stack>
            ))}

          {!result.yooga_apps.length && (
            <Text
              fontWeight="600"
              fontSize="0.8rem"
              w="8rem"
              textAlign="center"
              color="gray.500"
            >
              Cliente sem app
            </Text>
          )}
        </Stack>
        divider
        {!result.customer_id && (
          <Text
            fontWeight="600"
            fontSize="0.8rem"
            w="6rem"
            textAlign="center"
            color="gray.500"
          >
            Cliente sem customer
          </Text>
        )}
        {result.customer_id &&
          (!result.has_contract ? (
            <Text
              fontWeight="600"
              fontSize="0.8rem"
              w="6rem"
              textAlign="center"
              color="red.400"
            >
              Cliente sem contrato
            </Text>
          ) : result.contracts.some((c) => c.active) ? (
            <Text
              fontWeight="600"
              fontSize="0.8rem"
              w="6rem"
              textAlign="center"
              color="yooga.500"
            >
              Contrato Ativo
            </Text>
          ) : (
            <Text
              fontWeight="600"
              fontSize="0.8rem"
              w="6rem"
              textAlign="center"
              color="yellow.500"
            >
              Contrato Inativo
            </Text>
          ))}
      </HStack>
    </Flex>
  );
};

export default Result;
