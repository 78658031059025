import { Badge, Flex, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { R$ } from "shared/helpers/currency";

const PlansDetails = () => {
  return (
    <Stack>
      <Heading size="sm">Resumo da troca</Heading>
      <Flex>
        <Stack p="2rem" border="1px gray solid">
          <Badge rounded="full" p="2px 5px">
            Plano atual
          </Badge>
          <Heading size="sm">Plano completo</Heading>
          <HStack>
            <Text>Mensal</Text>
            <Text>{R$(189)}</Text>
          </HStack>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default PlansDetails;
