import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CircularProgress,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { useMemo, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { RiHistoryFill } from "react-icons/ri";
import {
  IInstallationLog,
  InstallationLogType,
} from "shared/types/installationLog";
import { useLogContext } from "./LogsContext";
import FiltersHeader from "./components/FiltersHeader";
import InstallationLogModal from "./components/InstallationLogModal";
import PaginationFooter from "./components/PaginationFooter";
import filterLogs from "./helpers/filter";

const Logs = () => {
  const { isLoadingLogs, logs, page, setPage, logsLastPage } = useLogContext();

  const [openLogModal, setOpenLogModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState<IInstallationLog | null>(null);
  const [search, setSearch] = useState("");
  const [type, setType] = useState<InstallationLogType>();

  const openLog = (log: IInstallationLog) => {
    setSelectedLog(log);
    setOpenLogModal(true);
  };

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const formatDescription = (description: string) => {
    return description.replace(/,/g, "\n");
  };

  const filteredLogs: IInstallationLog[] = useMemo(
    () => filterLogs(logs, search, type),
    [logs, search, type]
  );

  return (
    <Card>
      <CardHeader>
        <HStack align="center">
          <Icon as={RiHistoryFill} />
          <Heading size="sm">Logs (Beta)</Heading>
        </HStack>
      </CardHeader>

      <CardBody as={Stack} gap="1rem">
        <FiltersHeader
          search={search}
          setSearch={setSearch}
          type={type}
          setType={setType}
        />

        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th textAlign="center">Categoria</Th>
                <Th textAlign="center">Descrição</Th>
                <Th textAlign="center">Login</Th>
                <Th textAlign="center">Versão</Th>
                <Th textAlign="center">Criado em</Th>
                <Th textAlign="center">Ver detalhes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoadingLogs ? (
                <Tr>
                  <Td colSpan={7}>
                    <Flex justify="center" py="1rem">
                      <CircularProgress isIndeterminate color="yooga.400" />
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                filteredLogs.map((log) => (
                  <Tr key={log.id}>
                    <Td textAlign="center">
                      {log.type === "Installation" ? "Instalação" : "Pedido"}
                    </Td>
                    <Td>
                      <Tooltip
                        label={
                          <Box maxHeight="600px" whiteSpace="pre-wrap">
                            {truncateString(
                              formatDescription(log.description),
                              490
                            )}
                          </Box>
                        }
                        aria-label="Full description"
                        hasArrow
                        placement="top"
                        bg="gray.300"
                        color="black"
                        maxW="400px"
                        whiteSpace="pre-wrap"
                      >
                        {truncateString(log.description, 58)}
                      </Tooltip>
                    </Td>
                    <Td textAlign="center">
                      {log.user.login ? log.user.login : "-"}
                    </Td>
                    <Td textAlign="center">
                      {log.version ? log.version : "-"}
                    </Td>
                    <Td>
                      {moment(log.created_at).format("DD/MM/YYYY HH:mm:ss")}
                    </Td>
                    <Td textAlign="center">
                      <IconButton
                        aria-label="Ver detalhes"
                        icon={<FaExternalLinkAlt />}
                        variant="ghost"
                        size="sm"
                        onClick={() => openLog(log)}
                      />
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <PaginationFooter
          visibleLength={logs.length}
          page={page}
          setPage={setPage}
          pageCount={logsLastPage}
        />
      </CardBody>

      {selectedLog && (
        <InstallationLogModal
          isOpen={openLogModal}
          onClose={() => setOpenLogModal(false)}
          log={selectedLog}
        />
      )}
    </Card>
  );
};

export default Logs;
