import { useCustomerContext } from "pages/Customer/CustomerContext";
import { Mixpanel } from "services/mixpanel";
import Banner from "shared/components/Banner";

const NoContractBanner = () => {
  const { customer, openContractModal } = useCustomerContext();

  const action = () => {
    Mixpanel.track("customers-principal-opened-contract-modal");
    openContractModal();
  };

  return !customer.isRecordIncomplete &&
    (!customer.contract || !customer.contract.id) ? (
    <Banner
      colorScheme="red"
      content="Cliente sem contrato"
      subContent="Esse cliente ainda está sem um contrato"
      action={action}
    />
  ) : (
    <></>
  );
};

export default NoContractBanner;
