import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Show,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { Mixpanel } from "services/mixpanel";
import { useCustomerContext } from "../CustomerContext";

const CustomerData = () => {
  const { customer, setEditCustomerModalOpen } = useCustomerContext();

  useEffect(() => {
    Mixpanel.track("customers-client-data-viewed-tab");
  }, []);

  return (
    <Card>
      <CardHeader as={HStack}>
        <Heading size="md">Dados do cliente</Heading>
        <IconButton
          variant="ghost"
          icon={<FiEdit />}
          aria-label="Editar customer"
          onClick={() => setEditCustomerModalOpen(true)}
        />
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Text fontSize="sm">
              <strong>Nome fantasia:</strong> {customer.name}
            </Text>
            <Text fontSize="sm">
              <strong>Razão social:</strong> {customer.tradeName}
            </Text>
            <Text fontSize="sm">
              <strong>Documento:</strong> {customer.document}
            </Text>
          </Box>

          <Flex direction={{ base: "column", md: "row" }}>
            <Box flex="1">
              <Heading size="sm" mb="0.5rem">
                CONTATO
              </Heading>
              <Text fontSize="sm">
                <strong>Email:</strong> {customer.email}
              </Text>
              <Text fontSize="sm">
                <strong>Telefone:</strong> {customer.phone}
              </Text>
            </Box>

            <Show below="md">
              <Divider my="1rem" />
            </Show>

            <Box flex="1">
              <Heading size="sm" mb="0.5rem">
                ENDEREÇO
              </Heading>
              <Text fontSize="sm">
                <strong>CEP:</strong> {customer.zipcode}
              </Text>
              <Text fontSize="sm">
                <strong>Logradouro:</strong> {customer.address}
              </Text>
              <Text fontSize="sm">
                <strong>Número:</strong> {customer.number}
              </Text>
              <Text fontSize="sm">
                <strong>Bairro:</strong> {customer.neighborhood}
              </Text>
              <Text fontSize="sm">
                <strong>Complemento:</strong> {customer.complement}
              </Text>
              {customer.reference && (
                <Text fontSize="sm">
                  <strong>Referencia:</strong> {customer.reference}
                </Text>
              )}
              <Text fontSize="sm">
                <strong>Localidade:</strong> {customer.city} - {customer.state}
              </Text>
            </Box>
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default CustomerData;
