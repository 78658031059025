import { AxiosResponse } from "axios";
import { apiV2 } from "./http";
import {
  AuthRequestData,
  AuthResponseData,
  PermissionResponseData,
} from "./interfaces/auth";

export function authenticate(
  data: AuthRequestData
): Promise<AxiosResponse<AuthResponseData>> {
  return apiV2.post<AuthResponseData>("/auth/login", data);
}

export function permissions(): Promise<AxiosResponse<PermissionResponseData>> {
  return apiV2.get<PermissionResponseData>("/auth/permissions");
}
