import moment, { Moment } from "moment";
import {
  BillingStatus,
  BillingStatusLabels,
  BillingStatusPriority,
} from "shared/types/billingStatus";

export interface IApp {
  idi: number;
  apelido: string;
  razao_social: string;
  cnpj: string;
  fiscal: 0 | 1;
  fiscal_token: string;
  modulo_mesa: 0 | 1;
  modulo_bot: 0 | 1;
  modulo_emissor: 0 | 1;
  billing_status: number;
  billing_status_v2: BillingStatus;
  billing_method: string;
  galaxpay_billing: number;
  billing_unlock: string;
  galaxpay_extend: string;
  timezone: string;
  yooga_customer_id: string;
}

export class App {
  idi: number;
  nickname: string;
  tradeName: string;
  document: string;
  fiscal: 0 | 1;
  fiscalToken: string;
  tableModule: 0 | 1;
  botModule: 0 | 1;
  emissorModule: 0 | 1;
  billingStatus: number;
  billingStatusV2: BillingStatus;
  billingMethod: string;
  galaxpayBilling: number;
  billingUnlock?: Moment;
  galaxpayExtend: string;
  timezone: string;
  yoogaCustomerId: string;

  constructor(data: IApp) {
    this.idi = data.idi;
    this.nickname = data.apelido;
    this.tradeName = data.razao_social;
    this.document = data.cnpj;
    this.fiscal = data.fiscal;
    this.fiscalToken = data.fiscal_token;
    this.tableModule = data.modulo_mesa;
    this.botModule = data.modulo_bot;
    this.emissorModule = data.modulo_emissor;
    this.billingStatus = data.billing_status;
    this.billingStatusV2 = data.billing_status_v2;
    this.billingMethod = data.billing_method;
    this.galaxpayBilling = data.galaxpay_billing;
    this.galaxpayExtend = data.galaxpay_extend;
    this.timezone = data.timezone;
    this.yoogaCustomerId = data.yooga_customer_id;

    if (data.billing_unlock && data.billing_unlock !== "0001-01-01T00:00:00Z")
      this.billingUnlock = moment(data.billing_unlock);
  }
  get billingStatusLabel(): string {
    return BillingStatusLabels[this.billingStatusV2];
  }

  get billingStatusPriority(): "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH" {
    return BillingStatusPriority[this.billingStatusV2];
  }

  get lastDateTrustUnlock(): Moment {
    return moment(this.billingUnlock).subtract(1, "months");
  }

  get originalPayload(): IApp {
    return {
      idi: this.idi,
      apelido: this.nickname,
      razao_social: this.tradeName,
      cnpj: this.document,
      fiscal: this.fiscal,
      fiscal_token: this.fiscalToken,
      modulo_mesa: this.tableModule,
      modulo_bot: this.botModule,
      modulo_emissor: this.emissorModule,
      billing_status: this.billingStatus,
      billing_status_v2: this.billingStatusV2,
      billing_method: this.billingMethod,
      galaxpay_billing: this.galaxpayBilling,
      billing_unlock: this.billingUnlock?.toISOString() ?? "0001-01-01T00:00:00Z",
      galaxpay_extend: this.galaxpayExtend,
      timezone: this.timezone,
      yooga_customer_id: this.yoogaCustomerId,
    };
  }
}
