import moment, { Moment } from "moment";
import { capitalizeFirstLetter } from "shared/helpers/string";

export interface ILog {
  id: number;
  action: string;
  content: string;
  context: string;
  created_at: string;
  customer_id: number;
  updated_at: string;
  user_id: number;
  username: string;
  uuid: string;
}

export enum LogType {
  EDIT = "EDIT",
  ADD = "ADD",
  REMOVE = "REMOVE",
  COMMENT = "COMMENT",
  MISC = "DIVERSO",
}

export default class Log {
  id: number;
  user: string;
  context: string;
  createdAt: Moment;
  content: string;
  action: string;

  constructor(data: ILog) {
    this.id = data.id;
    this.user = capitalizeFirstLetter(data.username);
    this.context = data.context;
    this.createdAt = moment.utc(data.created_at);
    this.content = data.content;
    this.action = data.action;
  }

  get createdAtString() {
    return this.createdAt.locale("pt").fromNow();
  }

  get type(): LogType {
    if (this.context === "history") {
      return LogType.COMMENT;
    }

    if (this.action === "update") {
      return LogType.EDIT;
    }

    if (this.action === "delete" || this.action === "remove") {
      return LogType.REMOVE;
    }

    return LogType.ADD;
  }
}
