import {
  Card,
  CardBody,
  CardProps,
  Heading,
  HStack,
  Text,
  Icon,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { BiSolidMessageSquareError } from "react-icons/bi";
import { IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "shared/helpers/clipboard";

interface ClipboardBannerProps extends CardProps {
  content?: string;
  subContent?: string;
  LeftIcon?: IconType;
  colorScheme?: string;
  copyText?: string;
  successCopyMessage?: string;
  onCopy?(): void;
}

const ClipboardBanner: React.FC<ClipboardBannerProps> = ({
  content,
  subContent,
  LeftIcon,
  colorScheme = "blue",
  copyText,
  successCopyMessage,
  onCopy,
  ...props
}) => {
  const handleCopy = () => {
    copyToClipboard(
      copyText ?? "",
      successCopyMessage ?? "Copiado para a área de transferência"
    );
    if (onCopy) onCopy();
  };

  return (
    <Card bg={`${colorScheme}.400`} color="white" {...props}>
      <CardBody>
        <HStack>
          <Icon as={LeftIcon ?? BiSolidMessageSquareError} />
          <Heading fontSize="1.2rem">{content}</Heading>
        </HStack>
        <Text fontSize="0.9rem">{subContent}</Text>

        <HStack
          mt="1rem"
          bg="blackAlpha.300"
          rounded="0.5rem"
          p="0.5rem"
          cursor="pointer"
          onClick={handleCopy}
        >
          <Icon as={IoCopyOutline} />
          <Text w="90%">{copyText}</Text>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default ClipboardBanner;
