import {
  Flex,
  Heading,
  Stack,
  Input,
  FormLabel,
  FormControl,
  InputLeftAddon,
  InputGroup,
  Select,
  Button,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useOrderModalContext } from "../ModalContext";

const OrderForm = () => {
  const { confirm } = useGlobalContext();

  const {
    order,
    inputDueDate,
    setInputDueDate,
    inputPrice,
    setInputPriceState,
    inputMethod,
    setInputMethod,
    submitForm,
  } = useOrderModalContext();

  const onSubmit = () =>
    confirm("Salvar alterações", "Deseja continuar?", submitForm);

  return (
    <Stack>
      <Heading size="sm">Editar Cobrança</Heading>
      <Flex wrap="wrap" rowGap="2rem" columnGap="5%" mt="1rem">
        <FormControl flexBasis="47.5%">
          <FormLabel fontSize="0.8rem" color="gray.500">
            Data de Vencimento
          </FormLabel>
          <Input
            type="date"
            value={inputDueDate}
            onChange={(e) => setInputDueDate(e.target.value)}
          />
        </FormControl>

        <FormControl flexBasis="47.5%">
          <FormLabel fontSize="0.8rem" color="gray.500">
            Preço
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>R$</InputLeftAddon>
            <Input
              value={inputPrice}
              onChange={(e) => setInputPriceState(e.target.value)}
            />
          </InputGroup>
        </FormControl>

        {order?.bankId && (
          <FormControl flexBasis="100%">
            <FormLabel fontSize="0.8rem" color="gray.500">
              Método de pagamento
            </FormLabel>
            <Select
              onChange={(e) => setInputMethod(Number(e.target.value) as 1 | 2)}
              defaultValue={inputMethod}
            >
              <option value="1">Cartão de Crédito</option>
              <option value="2">Boleto</option>
            </Select>
          </FormControl>
        )}
      </Flex>
      <Button
        isDisabled={!inputDueDate || !inputPrice}
        variant="filled"
        mt="2rem"
        onClick={onSubmit}
      >
        Salvar Edição
      </Button>
    </Stack>
  );
};

export default OrderForm;
