import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "services/localStorage";

export function getSearchHistory(): string[] {
  try {
    const data = getStorageItem("searchHistory");
    const history = data ? JSON.parse(data) : [];
    return history;
  } catch (err) {
    removeStorageItem("searchHistory");
    return [];
  }
}

export function addSearchHistoryItem(search: string) {
  if (!search) return;

  let history = getSearchHistory();
  history = history.filter((item) => item !== search);

  if (history.length >= 5) history = history.slice(0, 4);

  setStorageItem("searchHistory", JSON.stringify([search, ...history]));
}

export function removeSearchHistoryItem(search: string) {
  if (!search) return;

  let history = getSearchHistory();
  history = history.filter((item) => item !== search);

  setStorageItem("searchHistory", JSON.stringify(history));
}
