import {
  Card,
  CardHeader,
  Heading,
  HStack,
  Icon,
  CardBody,
  ButtonGroup,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { LuFileWarning } from "react-icons/lu";
import { useAppTabContext } from "../AppContext";
import ProtectedButton from "shared/components/ProtectedButton";
import { FaRegCirclePause } from "react-icons/fa6";

const DangerZone = () => {
  const { openResetModal, openPauseModal } = useAppTabContext();

  return (
    <Card border="dashed 1px red">
      <CardHeader>
        <HStack justify="space-between">
          <HStack>
            <Icon as={LuFileWarning} />
            <Heading size="sm">Operações de Risco</Heading>
          </HStack>
        </HStack>
      </CardHeader>

      <CardBody>
        <ButtonGroup>
          <ProtectedButton
            permission="installation-card-fiscal-update"
            leftIcon={<FiEdit />}
            variant="filled"
            bg="red.500"
            size="sm"
            py="1.5rem"
            onClick={openResetModal}
          >
            Reset
          </ProtectedButton>

          <ProtectedButton
            permission="installation-card-danger_zone-pause-automation"
            leftIcon={<FaRegCirclePause />}
            variant="filled"
            bg="red.500"
            size="sm"
            py="1.5rem"
            onClick={openPauseModal}
          >
            Pausar automação
          </ProtectedButton>
        </ButtonGroup>
      </CardBody>
    </Card>
  );
};

export default DangerZone;
