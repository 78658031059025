import {
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { IconType } from "react-icons";

interface CardManagerProps {
  tabs:
    | string[]
    | {
        label: string;
        icon?: IconType;
        disabled?: boolean;
        hidden?: boolean;
      }[];
  children?: React.ReactNode[];
  allTab?: boolean;
}

const Tab: React.FC<React.PropsWithChildren<{ disabled?: boolean }>> = ({
  disabled,
  children,
}) => {
  const bg = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.300");

  return (
    <ChakraTab
      _selected={{
        color: "yooga.500",
        borderWidth: "1px",
      }}
      bg={bg}
      borderColor="yooga.500"
      color={textColor}
      rounded="5px"
      display="flex"
      alignItems="center"
      gap="1rem"
      shadow="0 6px 12px #00000015"
      isDisabled={disabled}
    >
      {children}
    </ChakraTab>
  );
};

const CardManager: React.FC<CardManagerProps> = ({
  tabs,
  allTab = true,
  children = [],
}) => {
  const disabledIdx = useMemo(
    () => tabs.map((tab) => typeof tab !== "string" && tab.disabled),
    [tabs]
  );

  const hiddenIdx = useMemo(
    () => tabs.map((tab) => typeof tab !== "string" && tab.hidden),
    [tabs]
  );

  return (
    <Tabs variant="unstyled" flex="1">
      <TabList gap="1rem" flexWrap="wrap">
        {allTab && <Tab>Todos</Tab>}
        {tabs.map((tab, idx) =>
          typeof tab === "string" ? (
            <Tab key={idx}>{tab}</Tab>
          ) : tab.hidden ? (
            <></>
          ) : (
            <Tab key={idx} disabled={tab.disabled}>
              {tab.icon && <tab.icon />} {tab.label}
            </Tab>
          )
        )}
      </TabList>

      <TabPanels>
        {allTab && (
          <TabPanel px="0">
            <Stack>
              {children.filter(
                (_, idx) => !disabledIdx[idx] && !hiddenIdx[idx]
              )}
            </Stack>
          </TabPanel>
        )}

        {children
          .filter((_, idx) => !hiddenIdx[idx])
          .map((C, idx) => (
            <TabPanel key={idx} px="0">
              {C}
            </TabPanel>
          ))}
      </TabPanels>
    </Tabs>
  );
};

export default CardManager;
