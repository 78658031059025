import axios, { AxiosResponse } from "axios";
import { onlyNumbers } from "shared/helpers/string";
import { SearchMinhaReceitaResponseData } from "./interfaces/minhaReceita";

const instance = axios.create({
  baseURL: "https://minhareceita.org/",
});

export const searchDocument = (
  document: string
): Promise<AxiosResponse<SearchMinhaReceitaResponseData>> =>
  instance.get<SearchMinhaReceitaResponseData>(onlyNumbers(document));
