import { Card, CardBody, Text } from "@chakra-ui/react";
import { useDeliveryContext } from "../DeliveryContext";

const Address = () => {
  const { delivery } = useDeliveryContext();

  if (!delivery) return null;

  const {
    address,
    number,
    neighborhood,
    city,
    state,
    country,
    additionalAddress,
    cep,
  } = delivery.address || {};

  return (
    <Card w="100%" bg="blackAlpha.200">
      <CardBody>
        {delivery?.address ? (
          <><Text variant="label" mb="1rem">
            Endereço da Loja
          </Text><Text>
              {`${address}, n° ${number}${additionalAddress ? ` , ${additionalAddress}` : ""} , ${neighborhood},  ${city}, ${state} - ${country}`}
            </Text><Text>CEP: {cep}</Text></>
        ) : (
          <Text>Endereço não cadastrado.</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default Address;
