import { AxiosResponse } from "axios";
import { licenciamento } from "./http";
import { ConfigPayload } from "./interfaces/config";

export function searchConfigs(idi: number): Promise<AxiosResponse<ConfigPayload[]>> {
  return licenciamento.get<ConfigPayload[]>(`config/${idi}`);
}

export function editConfig(
  configId: number,
  payload: ConfigPayload
): Promise<AxiosResponse<ConfigPayload>> {
  return licenciamento.put<ConfigPayload>(`config/${configId}`, payload);
}

export function createConfig(
  idi: number,
  name: string,
  value: string
): Promise<AxiosResponse<ConfigPayload>> {
  return licenciamento.post<ConfigPayload>("config", {
    idi,
    codigo: null,
    nome: name,
    valor: value,
    created_at: null,
    updated_at: null,
  });
}
