import { IconButton } from "@chakra-ui/react";
import { MdNotificationsActive, MdNotificationsNone } from "react-icons/md";
import { useFeatureHistoryContext } from "../FeatureHistoryContext";

const FeatureHistoryButton = () => {
  const { openUpdatesModal, hasUnseenUpdates } = useFeatureHistoryContext();

  return (
    <IconButton
      variant="ghost"
      aria-label="Histórico de atualizações"
      onClick={openUpdatesModal}
      color={hasUnseenUpdates ? "yooga.500" : undefined}
      icon={
        hasUnseenUpdates ? <MdNotificationsActive /> : <MdNotificationsNone />
      }
    />
  );
};

export default FeatureHistoryButton;
