import { Outlet } from "react-router-dom";
import CreateCustomerModal from "shared/components/CreateCustomerModal";
import Header from "shared/components/Header";
import Sidebar from "shared/components/Sidebar";

function Layout() {
  return (
    <>
      <Header />
      <Sidebar>
        <Outlet />
      </Sidebar>
      <CreateCustomerModal />
    </>
  );
}

export default Layout;
