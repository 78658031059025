import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAppUsers } from "services/app";
import { searchConfigs } from "services/config";
import { ConfigPayload } from "services/interfaces/config";
import { Mixpanel } from "services/mixpanel";
import { App } from "shared/models/app";
import { AppUser } from "shared/models/appUser";
import { Config } from "shared/models/config";
import { ConfigsLibrary } from "shared/types/configs";
import AppTab from "./AppTab";
import AppStatusHistoryModal from "./components/AppStatusHistoryModal";
import CreateTempUserModal from "./components/CreateTempUserModal";
import EditAppDetailsModal from "./components/EditAppDetailsModal";
import EditFiscalModal from "./components/EditFiscalModal";
import PauseModal from "./components/PauseModal";
import ResetModal from "./components/ResetModal";

interface AppProps {
  app: App;
  isEmissor?: boolean;
}

interface AppContextData {
  app: App;
  setApp(app: App): void;
  openCreateTempUserModal(): void;
  openEditAppDetailsModal(): void;
  openEditFiscalModal(): void;
  users: AppUser[];
  loadingUsers: boolean;
  loadUsers(): void;
  rawConfigs: Config[];
  loadingConfigs: boolean;
  setLoadingConfigs(b: boolean): void;
  updateConfig(r: ConfigPayload): void;
  isEmissor: boolean;
  openResetModal(): void;
  openPauseModal(): void;
  openAppStatusHistoryModal(): void;
  setAppStatusHistory(b: boolean): void;
  isLoadingLogs: boolean;
  setIsLoadingLogs(b: boolean): void;
  isLoadingIntegratedPayment: boolean;
  setIsLoadingIntegratedPayment(b: boolean): void;
}

const Context = createContext<AppContextData>({} as AppContextData);

export const useAppTabContext = () => useContext(Context);

const AppContext: React.FC<AppProps> = ({ app: appFromProps, isEmissor = false }) => {
  const [app, setApp] = useState<App>(appFromProps);
  const [users, setUsers] = useState<AppUser[]>([]);
  const [rawConfigs, setRawConfigs] = useState<Config[]>([]);

  const [createUserModal, setCreateUserModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editFiscalModal, setEditFiscalModal] = useState(false);
  const [appStatusHistoryModal, setAppStatusHistory] = useState(false);

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingConfigs, setLoadingConfigs] = useState(false);

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  const [isLoadingIntegratedPayment, setIsLoadingIntegratedPayment] = useState(false);

  const openCreateTempUserModal = () => {
    Mixpanel.track("customers-app-users-opened-temp-user");
    setCreateUserModal(true);
  };

  const openEditAppDetailsModal = () => {
    Mixpanel.track("customers-app-details-opened-edit");
    setEditModal(true);
  };
  const openEditFiscalModal = () => {
    Mixpanel.track("customers-app-fiscal-opened-edit");
    setEditFiscalModal(true);
  };

  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [pauseModalOpen, setPauseModalOpen] = useState(false);

  const openResetModal = () => {
    Mixpanel.track("customers-app-danger-zone-opened-reset");
    setResetModalOpen(true);
  };

  const onCloseResetModal = () => setResetModalOpen(false);

  const openPauseModal = () => {
    setPauseModalOpen(true);
  };

  const onClosePauseModal = () => setPauseModalOpen(false);

  const openAppStatusHistoryModal = () => {
    setAppStatusHistory(true);
  };

  const updateConfig = (response: ConfigPayload) => {
    setRawConfigs(
      rawConfigs.map((config) => {
        if (config.name === response.nome) {
          config.trueValue = response.valor;
          config.code = response.codigo;
          config.createdAt = response.created_at;
          config.updatedAt = response.updated_at;
        }

        return config;
      })
    );
  };

  const loadUsers = useCallback(() => {
    setLoadingUsers(true);
    getAppUsers(app.idi)
      .then((response) => setUsers(response.data.map((appUserData) => new AppUser(appUserData))))
      .catch(() => toast.error("Falha ao buscar usuários"))
      .finally(() => setLoadingUsers(false));
  }, [app]);

  const loadConfigs = useCallback(() => {
    setLoadingConfigs(true);
    searchConfigs(app.idi)
      .then((response) => {
        let configsLib: Config[] = ConfigsLibrary.map((configData) => new Config(configData));

        response.data.forEach((configResponse) => {
          configsLib.map((config) => {
            if (config.name === configResponse.nome) {
              config.trueValue = configResponse.valor;
              config.code = configResponse.codigo;
              config.createdAt = configResponse.created_at;
              config.updatedAt = configResponse.updated_at;
            }

            return config;
          });
        });

        setRawConfigs(configsLib);
      })
      .catch(() => toast.error("Falha ao carregar as configurações"))
      .finally(() => setLoadingConfigs(false));
  }, [app]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    loadConfigs();
  }, [loadConfigs]);

  const data: AppContextData = {
    app,
    setApp,
    openCreateTempUserModal,
    openEditAppDetailsModal,
    openEditFiscalModal,
    users,
    loadingUsers,
    loadUsers,
    rawConfigs,
    loadingConfigs,
    setLoadingConfigs,
    updateConfig,
    isEmissor,
    openResetModal,
    openPauseModal,
    openAppStatusHistoryModal,
    setAppStatusHistory,
    isLoadingLogs,
    setIsLoadingLogs,
    isLoadingIntegratedPayment,
    setIsLoadingIntegratedPayment,
  };

  return (
    <Context.Provider value={data}>
      <AppTab />

      <CreateTempUserModal isOpen={createUserModal} onClose={() => setCreateUserModal(false)} />

      <EditAppDetailsModal isOpen={editModal} onClose={() => setEditModal(false)} />

      <EditFiscalModal isOpen={editFiscalModal} onClose={() => setEditFiscalModal(false)} />

      <AppStatusHistoryModal
        isOpen={appStatusHistoryModal}
        onClose={() => setAppStatusHistory(false)}
      />

      <ResetModal isOpen={resetModalOpen} onClose={onCloseResetModal} />
      <PauseModal isOpen={pauseModalOpen} onClose={onClosePauseModal} />
    </Context.Provider>
  );
};

export default AppContext;
