import { createContext, useContext, useState } from "react";
import Sidebar from "./Sidebar";

interface SidebarContextData {
  isExpanded: boolean;
  toggleExpanded(): void;
  expandedSize: string;
  shrinkedSize: string;
}

const Context = createContext<SidebarContextData>({} as SidebarContextData);

export const useSidebarContext = () => useContext(Context);

const SidebarContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandedSize = "10em";
  const shrinkedSize = "4em";

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const data: SidebarContextData = {
    isExpanded,
    toggleExpanded,
    expandedSize,
    shrinkedSize,
  };

  return (
    <Context.Provider value={data}>
      <Sidebar>{children}</Sidebar>
    </Context.Provider>
  );
};

export default SidebarContext;
