import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { FaCodeBranch, FaStar, FaBug } from "react-icons/fa6";
import { MdOutlineFeaturedVideo } from "react-icons/md";

interface UpdateBadgeProps {
  type: "bug" | "feature" | "new" | "version";
  version?: string;
}

const UpdateBadge: React.FC<UpdateBadgeProps> = ({ type, version }) => {
  if (version)
    return (
      <Tag size="sm" rounded="full">
        <TagLeftIcon as={FaCodeBranch} />
        <TagLabel>{version}</TagLabel>
      </Tag>
    );

  return (
    <Tag
      size="sm"
      rounded="full"
      colorScheme={
        type === "bug"
          ? "red"
          : type === "feature"
          ? "yooga"
          : type === "new"
          ? "green"
          : undefined
      }
    >
      {type === "bug" && <TagLeftIcon as={FaBug} />}
      {type === "feature" && <TagLeftIcon as={MdOutlineFeaturedVideo} />}
      {type === "new" && <TagLeftIcon as={FaStar} />}
      {type === "version" && <TagLeftIcon as={FaCodeBranch} />}

      <TagLabel fontWeight="600">
        {type === "bug" && "Correção"}
        {type === "feature" && "Nova Funcionalidade"}
        {type === "new" && "Lançamento"}
        {type === "version" && version}
      </TagLabel>
    </Tag>
  );
};

export default UpdateBadge;
