import { AxiosResponse } from "axios";
import { onlyNumbers } from "shared/helpers/string";
import { ICustomer } from "shared/models/customer";
import { apiV1, apiV2 } from "./http";
import { CreateCustomerRequestData, CreatePartialCustomerRequestData } from "./interfaces/customer";

export const searchCustomers = (query: string): Promise<AxiosResponse<ICustomer[]>> => {
  const regex = new RegExp(/^\d+([./-]\d+)*$/);

  if (regex.test(query)) query = onlyNumbers(query);
  else query = query.trim();

  return apiV2.get<ICustomer[]>("/customers", { params: { query } });
};

export const getCustomer = (id: string | number): Promise<AxiosResponse<ICustomer>> => {
  return apiV1.get<ICustomer>(`customers/${id}`);
};

export function update(
  customerId: number,
  payload: CreateCustomerRequestData
): Promise<AxiosResponse<ICustomer>> {
  return apiV2.patch<ICustomer>(`customers/update/${customerId}`, payload);
}

export function create(payload: CreateCustomerRequestData): Promise<AxiosResponse<ICustomer>> {
  return apiV2.post<ICustomer>("customers/create", payload);
}

export function marvin(document: string): Promise<AxiosResponse<any>> {
  return apiV2.post<any>(`marvin/mark/${document}`);
}

export function createPartial(
  payload: CreatePartialCustomerRequestData
): Promise<AxiosResponse<ICustomer>> {
  return apiV2.post<ICustomer>("customers/create-partial", payload);
}
