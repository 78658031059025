import moment, { Moment } from "moment";

export interface IAddress {
  id: number;
  idi: number;
  cep: string;
  address: string;
  number: string;
  additional_address: string | null;
  reference: string | null;
  neighbourhood: string;
  city: string;
  state: string;
  country: string;
  default: number;
  store_id: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export class Address {
  id: number;
  idi: number;
  cep: string;
  address: string;
  number: string;
  additionalAddress: string | null;
  reference: string | null;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  default: boolean;
  storeId: number;
  createdAt: Moment | null;
  updatedAt: Moment | null;
  deletedAt: Moment | null;

  constructor(data: IAddress) {
    this.id = data.id;
    this.idi = data.idi;
    this.cep = data.cep;
    this.address = data.address;
    this.number = data.number;
    this.additionalAddress = data.additional_address;
    this.reference = data.reference;
    this.neighborhood = data.neighbourhood;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.storeId = data.store_id;

    this.default = data.default === 1;
    this.createdAt = data.created_at ? moment(data.created_at) : null;
    this.updatedAt = data.updated_at ? moment(data.updated_at) : null;
    this.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
  }

  get rawData(): IAddress {
    return {
      id: this.id,
      idi: this.idi,
      cep: this.cep,
      address: this.address,
      number: this.number,
      additional_address: this.additionalAddress,
      reference: this.reference,
      neighbourhood: this.neighborhood,
      city: this.city,
      state: this.state,
      country: this.country,
      default: this.default ? 1 : 0,
      store_id: this.storeId,
      created_at: this.createdAt?.format("YYYY-MM-DD h:mm:ss") ?? null,
      updated_at: this.updatedAt?.format("YYYY-MM-DD h:mm:ss") ?? null,
      deleted_at: this.deletedAt?.format("YYYY-MM-DD h:mm:ss") ?? null,
    };
  }
}
