import { AxiosResponse } from "axios";
import { ILog } from "shared/models/log";
import { apiV2 } from "./http";

export function getLogs(
  customerId: number | string
): Promise<AxiosResponse<ILog[]>> {
  return apiV2.get<ILog[]>(`history/${customerId}`);
}

export function createComment(
  comment: string,
  customerId: number | string
): Promise<AxiosResponse<{ log: ILog }>> {
  return apiV2.post<{ log: ILog }>("history/", {
    comment,
    identifier: `${customerId}`,
  });
}
