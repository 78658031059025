export interface ICard {
  id: number;
}

export class Card {
  id: number;

  constructor(data: ICard) {
    this.id = data.id;
  }
}
