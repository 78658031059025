import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "shared/helpers/clipboard";
import { IInstallationLog } from "shared/types/installationLog";

interface InstallationLogModalProps {
  isOpen: boolean;
  onClose(): void;
  log: IInstallationLog;
}

const InstallationLogModal: React.FC<InstallationLogModalProps> = ({
  isOpen,
  onClose: onCloseFromProps,
  log,
}) => {
  const [loading, setLoading] = useState(false);

  const copyJsonLog = () => {
    copyToClipboard(JSON.stringify(log, null, 2), "Json copiado");
  };

  const onClose = () => {
    onCloseFromProps();
    setLoading(false);
  };

  return (
    <Drawer
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton isDisabled={loading} />
        <DrawerHeader>
          {log.type === "Installation" ? "Instalação" : "Pedido"} - Login{" "}
          {log.user.login}
        </DrawerHeader>
        <DrawerBody>
          <Box
            as="pre"
            whiteSpace="pre-wrap"
            p={4}
            bg="gray.100"
            borderRadius="md"
          >
            <code>{JSON.stringify(log, null, 2)}</code>
            <IconButton
              aria-label="Copy json log"
              size="sm"
              variant="link"
              onClick={copyJsonLog}
              icon={<IoCopyOutline />}
            />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default InstallationLogModal;
