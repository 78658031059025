import { Button, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useMemo } from "react";
import { usePermissions } from "shared/hooks/usePermissions";
import { useCustomerContext } from "../CustomerContext";
import App from "../tabs/App";
import CustomerData from "../tabs/CustomerData";
import Financial from "../tabs/Financial";
import Principal from "../tabs/Principal";

const TabManager = () => {
  const { loading, customer, apps, setInstallationModalOpen } = useCustomerContext();

  const { d } = usePermissions();

  const appsList = useMemo(() => apps?.filter((app) => !app.emissorModule), [apps]);
  const emissorList = useMemo(() => apps?.filter((app) => !!app.emissorModule), [apps]);

  return (
    <Tabs colorScheme="yooga" flex="1">
      <TabList as={Skeleton} isLoaded={!loading} flexWrap="wrap">
        <Tab>Principal</Tab>
        <Tab isDisabled={!customer.id}>Dados do cliente</Tab>
        <Tab isDisabled={!customer.contract || !customer.contract.id}>Financeiro</Tab>

        {appsList.length ? (
          appsList.map((app) => <Tab key={app.idi}>App ({app.idi})</Tab>)
        ) : (
          <Tab isDisabled>App</Tab>
        )}

        {emissorList.length ? (
          emissorList.map((emissor) => <Tab key={emissor.idi}>Emissor ({emissor.idi})</Tab>)
        ) : (
          <Tab isDisabled>Emissor</Tab>
        )}
        <Button
          onClick={() => {
            setInstallationModalOpen(true);
          }}
          fontWeight={"500"}
          hidden={d("create-installation")}
        >
          + Novo app
        </Button>
      </TabList>

      {loading ? (
        <Skeleton h="70%" w="100%" mt="0.5rem" />
      ) : (
        <TabPanels>
          <TabPanel px="0">
            <Principal />
          </TabPanel>
          <TabPanel px="0">{customer.id && <CustomerData />}</TabPanel>
          <TabPanel px="0">
            <Financial />
          </TabPanel>

          {appsList.length ? (
            appsList.map((app) => (
              <TabPanel key={app.idi} px="0">
                <App app={app} />
              </TabPanel>
            ))
          ) : (
            <TabPanel></TabPanel>
          )}

          {emissorList.length ? (
            emissorList.map((emissor) => (
              <TabPanel key={emissor.idi}>
                <App app={emissor} isEmissor />
              </TabPanel>
            ))
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabPanels>
      )}
    </Tabs>
  );
};

export default TabManager;
