import { Box } from "@chakra-ui/react";
import SidebarContent from "./SidebarContent";
import { useSidebarContext } from "./SidebarContext";

const SimpleSidebar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isExpanded, expandedSize, shrinkedSize } = useSidebarContext();

  return (
    <Box h="100%" pos="relative">
      <SidebarContent display={{ base: "none", md: "block" }} />
      <Box
        display="inline-block"
        w="100vw"
        pl={{ base: 0, md: isExpanded ? expandedSize : shrinkedSize }}
        transition="all .5s ease"
      >
        {children}
      </Box>
    </Box>
  );
};

export default SimpleSidebar;
