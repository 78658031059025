import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Fade,
  Button,
  Stack,
  HStack,
  StackDivider,
  Icon,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { FaPlus } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import modules from "routes/modules";
import { useLogout } from "shared/hooks/useLogout";
import { AppModule } from "shared/types/appModule";
import packageJSON from "../../../package.json";
import ColorModeSwitcher from "./ColorModeSwitcher";
import FeatureHistory from "./FeatureHistory";
import Instabiility from "./Instabiility";

interface MobileSidebarProps {
  isOpen: boolean;
  onClose(): void;
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({ isOpen, onClose }) => {
  const logout = useLogout();
  const location = useLocation();
  const { loggedUser, setCreateCustomerModalOpen } = useGlobalContext();
  const { document: customer } = useParams();

  const matchModule = (mod: AppModule) =>
    mod.matchs.some((match) => {
      if (match.exact) return match.path === location.pathname;
      return location.pathname.includes(match.path);
    });

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Olá, {loggedUser?.name ?? "usuário"}</DrawerHeader>

        <DrawerBody>
          <Stack divider={<StackDivider />}>
            <Fade in={!customer} unmountOnExit>
              <Button
                variant="outlined"
                w="100%"
                leftIcon={<FaPlus />}
                onClick={() => setCreateCustomerModalOpen(true)}
                mb="1rem"
              >
                Novo Cliente
              </Button>
            </Fade>

            <Stack>
              {modules.map((appModule) => (
                <Button
                  key={appModule.name}
                  variant="ghost"
                  w="100%"
                  as={Link}
                  _hover={{
                    bg: "yooga.400",
                    color: "white",
                  }}
                  leftIcon={
                    <Icon as={appModule.icon} aria-label={appModule.name} />
                  }
                  to={appModule.path}
                  justifyContent="flex-start"
                  colorScheme={matchModule(appModule) ? "yooga" : "gray"}
                >
                  {appModule.name}
                </Button>
              ))}
            </Stack>

            <Stack>
              <Button
                variant="ghost"
                w="100%"
                _hover={{
                  bg: "yooga.400",
                  color: "white",
                }}
                leftIcon={<IoMdHelpCircleOutline />}
                as={Link}
                target="_blank"
                to="https://www.notion.so/855bdad3f664488f9943847fe2e8a7dc?v=627fecec0b0345d6ad93149513c4a7bf"
                justifyContent="flex-start"
              >
                Ajuda
              </Button>

              <Button
                variant="ghost"
                w="100%"
                _hover={{
                  bg: "yooga.400",
                  color: "white",
                }}
                justifyContent="flex-start"
                leftIcon={<IoLogOutOutline />}
                onClick={logout}
              >
                Sair
              </Button>
            </Stack>

            <HStack>
              <Instabiility />

              <FeatureHistory />

              <ColorModeSwitcher />
            </HStack>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Text variant="label">{packageJSON.version}</Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileSidebar;
