export interface IDiscount {
  product_total_value?: number;
  product_value_with_discount?: number;
  cycles?: number;
  discount_value?: number;
  discount_type?: DiscountType;
}

export class Discount {
  product_total_value?: number;

  product_value_with_discount?: number;

  cycles?: number;

  discount_value?: number;

  discount_type?: DiscountType;

  constructor(data?: IDiscount) {
    if (data) {
      this.product_total_value = data.product_total_value;
      this.product_value_with_discount = data.product_value_with_discount;
      this.cycles = data.cycles;
      this.discount_value = data.discount_value;
      this.discount_type = data.discount_type;
    }
  }

  get discountTypeName(): DiscountTypeName {
    return this.discount_type === "%" ? "percentage" : "flat";
  }
}

export type DiscountType = "%" | "R$";
export type DiscountTypeName = "percentage" | "flat";
