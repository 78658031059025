import { extendTheme, ThemeOverride } from "@chakra-ui/react";
import colors from "./colors";
import styles from "./styles";
import fonts from "./foundations/fonts";

import Button from "./components/Button";
import Text from "./components/Text";

const overrides: ThemeOverride = {
  initialColorMode: "light",
  useSystemColorMode: false,
  colors,
  styles,
  fonts,
  components: {
    Button,
    Text,
  },
};
export default extendTheme(overrides);
