import {
  Stack,
  Text,
  Icon,
  Flex,
  HStack,
  SlideFade,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import {
  getSearchHistory,
  removeSearchHistoryItem,
} from "shared/helpers/searchHistory";
import { useHomeContext } from "../HomeContext";
import { MdOutlineRestore, MdOutlineClose } from "react-icons/md";
import { useState } from "react";
import { Mixpanel } from "services/mixpanel";

const SearchHistory = () => {
  const { setSearchText } = useGlobalContext();
  const { showHistory, setIsHistoryHovered, setIsBarFocused, search } =
    useHomeContext();
  const [searchHistory, setSearchHistory] = useState(getSearchHistory());
  const bg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverColor = useColorModeValue("gray.100", "gray.500");

  const onClick = (item: string) => {
    Mixpanel.track("customers-home-clicked-history", { item });
    setSearchText(item);
    search(item);
  };

  const onRemove = (item: string) => {
    removeSearchHistoryItem(item);
    setSearchHistory(searchHistory.filter((i) => i !== item));
    setIsBarFocused(true);
  };

  return (
    <Box position="absolute" top="0" left="0" w="100%">
      <SlideFade
        unmountOnExit
        offsetY="-2rem"
        in={showHistory && !!searchHistory.length}
      >
        <Stack
          onMouseOver={() => setIsHistoryHovered(true)}
          onMouseOut={() => setIsHistoryHovered(false)}
          w="100%"
          align="stretch"
          borderColor={borderColor}
          borderWidth="0 1px"
          paddingTop="0.5rem"
          bg={bg}
          gap="0"
        >
          {searchHistory.map((item, idx) => (
            <Flex
              key={idx}
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              p="0.5rem 1rem"
              align="center"
              gap="1rem"
              transition="all .3s ease"
              wrap="nowrap"
              _hover={{
                bg: hoverColor,
              }}
            >
              <HStack
                flex="1"
                gap="1rem"
                cursor="pointer"
                onClick={() => onClick(item)}
              >
                <Icon as={MdOutlineRestore} />
                <Text fontSize="0.9rem">{item}</Text>
              </HStack>

              <Icon
                as={MdOutlineClose}
                ml="auto"
                cursor="pointer"
                onClick={() => onRemove(item)}
              />
            </Flex>
          ))}
        </Stack>
      </SlideFade>
    </Box>
  );
};

export default SearchHistory;
