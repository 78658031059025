import moment, { Moment } from "moment";
import { R$ } from "shared/helpers/currency";

export interface IPagarmeTransaction {
  id?: string;
  message?: string;
  type?: string;
  success?: boolean;
  date: string;
  amount?: number;
}

export class PagarmeTransaction {
  id?: string;
  message?: string;
  type?: string;
  success?: boolean;
  date: Moment;
  amount?: number;

  constructor(data: IPagarmeTransaction) {
    this.id = data.id;
    this.message = data.message;
    this.success = data.success;

    this.date = moment(data.date);
    this.type = data.type;
    this.amount = data.amount;
  }

  get priceString(): string {
    if (!this.amount) return "";
    return R$(this.amount / 100) as string;
  }

  get typeString(): string {
    return this.type === "credit-card" ? "Cartão" : "";
  }
}
