import { HStack, Stack, StackDivider, Text } from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { R$ } from "shared/helpers/currency";

const LTV = () => {
  const { customer } = useCustomerContext();
  return (
    <HStack
      flexDirection={{ base: "column", md: "row" }}
      mb="1rem"
      gap={{ base: "0.5rem", md: "2rem" }}
      align="flex-start"
      divider={<StackDivider />}
    >
      <Stack>
        <Text variant="label">Total</Text>
        <Text fontWeight="600" fontSize={{ base: "2rem", md: "1rem" }}>
          {R$(customer.totalAmount)}
        </Text>
      </Stack>

      <HStack gap="3rem">
        <Stack>
          <Text variant="label">Total Cancelado</Text>
          <Text fontWeight="600" color="red.400">
            {R$(customer.totalCancelledAmount)}
          </Text>
        </Stack>
        <Stack>
          <Text variant="label">Total em Aberto</Text>
          <Text fontWeight="600" color="yooga.400">
            {R$(customer.totalOpenAmount)}
          </Text>
        </Stack>
        <Stack>
          <Text variant="label">Total Pago</Text>
          <Text fontWeight="600" color="green.400">
            {R$(customer.totalPaidAmount)}
          </Text>
        </Stack>
      </HStack>
    </HStack>
  );
};

export default LTV;
