import { createContext, useContext, useState } from "react";
import { Mixpanel } from "services/mixpanel";
import { Contract } from "shared/models/contract";
import ChangePaymentMethodModal from "./components/ChangePaymentMethodModal";
import ChangePlanModal from "./components/ChangePlanModal";
import CreateOrderModal from "./components/CreateOrderModal";
import OrderModal from "./components/OrderModal";
import Financial from "./Financial";

interface FinancialContextData {
  openOrderModal(n: string): void;
  openCreateOrderModal(): void;
  openChangePaymentMethodModal(): void;
  openChangePlanModal(c: Contract): void;
}

const Context = createContext<FinancialContextData>({} as FinancialContextData);

export const useFinancialContext = () => useContext(Context);

const FinancialContext = () => {
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [orderModalToken, setOrderModalToken] = useState<string>();

  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [changePlanModalData, setChangePlanModalData] = useState<Contract>();

  const [changePaymentMethodModalOpen, setChangePaymentMethodModalOpen] =
    useState(false);

  const [createOrderModalOpen, setCreateOrderModalOpen] = useState(false);

  const openChangePlanModal = (contract?: Contract) => {
    if (contract) setChangePlanModalData(contract);
    setChangePlanModalOpen(true);
  };

  const onCloseChangePlanModal = () => {
    setChangePlanModalData(undefined);
    setChangePlanModalOpen(false);
  };

  const openChangePaymentMethodModal = () =>
    setChangePaymentMethodModalOpen(true);

  const onCloseChangePaymentMethodModal = () =>
    setChangePaymentMethodModalOpen(false);

  const openCreateOrderModal = () => {
    Mixpanel.track("customers-financial-new-order-opened-modal");
    setCreateOrderModalOpen(true);
  };

  const onCloseCreateOrderModal = () => setCreateOrderModalOpen(false);

  const openOrderModal = (orderToken?: string) => {
    Mixpanel.track("customers-financial-order-opened-modal");
    if (orderToken) setOrderModalToken(orderToken);
    setOrderModalOpen(true);
  };

  const onCloseOrderModal = () => {
    setOrderModalToken(undefined);
    setOrderModalOpen(false);
  };

  const data: FinancialContextData = {
    openOrderModal,
    openCreateOrderModal,
    openChangePaymentMethodModal,
    openChangePlanModal,
  };

  return (
    <Context.Provider value={data}>
      <Financial />
      <OrderModal
        isOpen={orderModalOpen}
        onClose={onCloseOrderModal}
        token={orderModalToken}
      />
      <CreateOrderModal
        isOpen={createOrderModalOpen}
        onClose={onCloseCreateOrderModal}
      />
      <ChangePaymentMethodModal
        isOpen={changePaymentMethodModalOpen}
        onClose={onCloseChangePaymentMethodModal}
      />
      <ChangePlanModal
        isOpen={changePlanModalOpen}
        onClose={onCloseChangePlanModal}
        contract={changePlanModalData}
      />
    </Context.Provider>
  );
};

export default FinancialContext;
