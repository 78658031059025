import {
  Button,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { IoCopyOutline } from "react-icons/io5";
import { Mixpanel } from "services/mixpanel";
import { copyToClipboard } from "shared/helpers/clipboard";
import { formatDocument } from "shared/helpers/document";

import { capitalizeAllFirstLetters } from "shared/helpers/string";
import { useCustomerContext } from "../CustomerContext";
import HeaderOptions from "./HeaderOptions";

const CustomerHeader = () => {
  const { info } = useCustomerContext();

  const copyDocument = () => {
    Mixpanel.track("customers-customer-clicked-copy-document", {
      document: formatDocument(info.document),
    });
    copyToClipboard(formatDocument(info.document), "Documento copiado");
  };

  return (
    <Stack flex="1" align="flex-start">
      <HStack wrap="nowrap" justify="space-between" w="100%">
        <Skeleton isLoaded={!!info.name} minW="70%">
          <Tooltip
            label={`Customer ID: ${info.customer_id}`}
            hasArrow
            isDisabled={!info.customer_id}
            placement="top-start"
          >
            <Heading fontWeight="500" fontSize="1.4rem" minH="1em" size="md">
              {capitalizeAllFirstLetters(info.name)}
            </Heading>
          </Tooltip>
        </Skeleton>

        <HeaderOptions />
      </HStack>

      <HStack gap="0 2rem" align="stretch" alignSelf="stretch" wrap="wrap">
        <Skeleton
          isLoaded={!!info.trade_name}
          as={HStack}
          minW={!info.trade_name ? "25%" : ""}
        >
          <Text fontWeight="500">Razão social:</Text>
          <Text px="5px">{info.trade_name}</Text>
        </Skeleton>
        <Skeleton
          isLoaded={!!info.document}
          as={HStack}
          minW={!info.document ? "25%" : ""}
        >
          <Text fontWeight="500">CNPJ:</Text>
          <Button
            fontWeight="400"
            rightIcon={<IoCopyOutline />}
            variant="ghost"
            onClick={copyDocument}
            px="5px"
          >
            {formatDocument(info.document)}
          </Button>
        </Skeleton>
      </HStack>
    </Stack>
  );
};

export default CustomerHeader;
