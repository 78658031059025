import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppModule } from "shared/types/appModule";
import { useSidebarContext } from "./SidebarContext";

interface NavItemProps extends ButtonProps {
  appModule: AppModule;
}
const NavItem: React.FC<NavItemProps> = ({
  appModule: { name, icon, path, matchs },
  ...rest
}) => {
  const { isExpanded } = useSidebarContext();
  const location = useLocation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(path);
  };

  const isInModule = useMemo(
    () =>
      matchs.some((match) => {
        if (match.exact) return match.path === location.pathname;
        return location.pathname.includes(match.path);
      }),
    [location.pathname, matchs]
  );

  return (
    <Button
      w="100%"
      onClick={onClick}
      display="flex"
      justifyContent={!isExpanded ? "center" : "flex-start"}
      borderRadius="lg"
      fontWeight="500"
      variant="ghost"
      color={!isInModule ? "gray.600" : undefined}
      colorScheme={isInModule ? "yooga" : "gray"}
      leftIcon={
        icon && (
          <Icon
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )
      }
      {...rest}
    >
      {isExpanded && name}
    </Button>
  );
};

export default NavItem;
