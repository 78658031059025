import { Neighborhood, Radius } from "shared/types/delivery";
import { Address, IAddress } from "./address";

export interface IDelivery {
  id: number;
  idi: number;
  name: string;
  description: string;
  url: string;
  redirect: string;
  latitude: number;
  longitude: number;
  manual_address: number;
  has_bairro: number;
  only_schedule: number;
  only_withdrawal: number;
  picpay: string;
  pixel: string;
  zoop_seller_id: string;
  google_analytics_token: string;
  radius?: Radius[];
  neighborhoods?: Neighborhood[];
  address: IAddress | null;
}

export class Delivery {
  id: number;
  idi: number;
  name: string;
  description: string;
  redirect: string;
  latitude: number;
  longitude: number;
  manualAddress: number;
  picpay: string;
  pixel: string;
  zoopSellerId: string;
  googleAnalyticsToken: string;
  radius: Radius[] = [];
  neighborhoods: Neighborhood[] = [];
  address: Address | null;

  _url: string;

  get url() {
    return `delivery.yooga.app/${this._url}`;
  }

  _hasNeighborhood: number;

  get hasNeighborhood(): boolean {
    return this._hasNeighborhood === 0 ? false : true;
  }

  _onlyWithdrawal: number;

  get onlyWithdrawal(): boolean {
    return this._onlyWithdrawal === 0 ? false : true;
  }

  _onlySchedule: number;

  get onlySchedule(): boolean {
    return this._onlySchedule === 0 ? false : true;
  }

  set onlySchedule(b: boolean) {
    this._onlySchedule = b ? 1 : 0;
  }

  constructor(data: IDelivery) {
    this.id = data.id;
    this.idi = data.idi;
    this.name = data.name;
    this.description = data.description;
    this._url = data.url;
    this.redirect = data.redirect;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.manualAddress = data.manual_address;
    this._hasNeighborhood = data.has_bairro;
    this._onlySchedule = data.only_schedule;
    this._onlyWithdrawal = data.only_withdrawal;
    this.picpay = data.picpay;
    this.pixel = data.pixel;
    this.zoopSellerId = data.zoop_seller_id;
    this.googleAnalyticsToken = data.google_analytics_token;
    this.address = data.address?.idi ? new Address(data.address) : null;

    data.radius?.forEach((item) => this.radius.push(item));
    data.neighborhoods?.forEach((item) => this.neighborhoods.push(item));
  }

  get rawData(): IDelivery {
    return {
      id: this.id,
      idi: this.idi,
      name: this.name,
      description: this.description,
      url: this._url,
      redirect: this.redirect,
      latitude: this.latitude,
      longitude: this.longitude,
      manual_address: this.manualAddress,
      has_bairro: this._hasNeighborhood,
      only_schedule: this._onlySchedule,
      only_withdrawal: this._onlyWithdrawal,
      picpay: this.picpay,
      pixel: this.pixel,
      zoop_seller_id: this.zoopSellerId,
      google_analytics_token: this.googleAnalyticsToken,
      radius: this.radius,
      neighborhoods: this.neighborhoods,
      address: this.address?.rawData ? this.address?.rawData : null,
    };
  }
}
