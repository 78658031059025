import { BillingStatus, BillingStatusLabels, BillingStatusPriority } from "shared/types/billingStatus";

export interface IInstallationStatus {
  id: number;
  customer_id: number;
  idi: number;
  last_status: BillingStatus;
  current_status: BillingStatus;
  dry_run: number;
  created_at: string;
}

export default class InstallationStatus {
  id: number;
  customer_id: number;
  idi: number;
  last_status: BillingStatus;
  current_status: BillingStatus;
  dry_run: number;
  created_at: string;

  constructor(data: IInstallationStatus) {
    this.id = data.id;
    this.customer_id = data.customer_id;
    this.idi = data.idi;
    this.last_status = data.last_status;
    this.current_status = data.current_status;
    this.dry_run = data.dry_run;
    this.created_at = data.created_at;
  }

  get lastBillingStatusLabel(): string {
    return BillingStatusLabels[this.last_status];
  }  
  
  get currentBillingStatusLabel(): string {
    return BillingStatusLabels[this.current_status];
  }
  
  get lastBillingStatusPriority(): "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH" {
    return BillingStatusPriority[this.last_status];
  }  
  
  get currentBillingStatusPriority(): "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH" {
    return BillingStatusPriority[this.current_status];
  }
}