import {
  CircularProgress,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";
import Confirmation from "./components/Confirmation";
import Selection from "./components/Selection";
import { useChangePlanModalContext } from "./ModalContext";
import ChangePlanModalHeader from "./components/ChangePlanModalHeader";

interface ChangePlanModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ChangePlanModal: React.FC<ChangePlanModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { step, loading } = useChangePlanModalContext();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />

      <DrawerContent pos="relative">
        <DrawerCloseButton />
        <ChangePlanModalHeader />

        {step === 0 && <Selection />}
        {step === 1 && <Confirmation />}

        {loading && (
          <Flex
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="gray.100"
            zIndex="1"
            align="center"
            justify="center"
          >
            <CircularProgress isIndeterminate color="yooga.400" />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ChangePlanModal;
