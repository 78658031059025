import {
  Badge,
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { FaTrashCan } from "react-icons/fa6";
import { HiComputerDesktop } from "react-icons/hi2";
import { toast } from "react-toastify";
import { Mixpanel } from "services/mixpanel";
import { deleteProduct } from "services/product";
import { Contract } from "shared/models/contract";
import Actions from "./Actions";

const Contracts = () => {
  const iconBoxColor = useColorModeValue("gray.200", "gray.600");
  const { customer, loadCustomer } = useCustomerContext();
  const { confirm } = useGlobalContext();

  const handleRemoveProduct = (contract: Contract, productId: number) => {
    if (contract?.products.length <= 1) {
      return confirm(
        "Não foi possível remover o plano",
        "É obrigatório ter no mínimo 1 serviço no contrato!"
      );
    }

    Mixpanel.track("customers-financial-deleted-service");
    confirm("Remover o serviço do contrato", "Deseja continuar?", () =>
      deleteProduct(contract.id!, productId)
        .then(() => {
          toast.success("Serviço removido com sucesso");
          loadCustomer();
        })
        .catch(() => toast.error("Não foi possível remover o serviço"))
    );
  };

  return customer.contracts?.length ? (
    <Card>
      <CardBody as={Stack} divider={<Divider my="1.5rem !important" />}>
        {customer.contracts.map((contract) => (
          <Stack key={contract.id}>
            <Flex direction={{ base: "column", sm: "row" }}>
              <Stack mr="auto">
                <HStack>
                  <Heading size="md">
                    Contrato {contract.selectedPeriodicity}
                  </Heading>
                  {contract.periodicity === "yearly" &&
                    contract.orders.length > 1 && (
                      <Badge rounded="full" p="2px 5px" color="gray.600">
                        Parcelado em {contract.installmentsNumber} vezes
                      </Badge>
                    )}

                  {contract.status === 0 && (
                    <Badge rounded="full" p="2px 5px" colorScheme="red">
                      Contrato inativo
                    </Badge>
                  )}
                </HStack>
                <Text variant="label">
                  Renovação dia {contract.expiration_date?.format("DD/MM/YYYY")}
                </Text>
              </Stack>

              <Actions contract={contract} />
            </Flex>

            {contract.products.map(({ id, plan, priceString }) => (
              <HStack key={id}>
                <Box p="0.5rem" bg={iconBoxColor} rounded="5px">
                  <HiComputerDesktop size="1.5rem" />
                </Box>

                <Text
                  mr="auto"
                  fontWeight="500"
                  as={contract.status === 0 ? "s" : undefined}
                >
                  {plan?.name}
                </Text>
                <Text as={contract.status === 0 ? "s" : undefined}>
                  {priceString}
                </Text>
                {contract.id && plan?.id && (
                  <IconButton
                    aria-label="Remover serviço"
                    icon={<FaTrashCan />}
                    onClick={() => handleRemoveProduct(contract, id!)}
                  />
                )}
              </HStack>
            ))}
          </Stack>
        ))}
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};

export default Contracts;
