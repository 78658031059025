import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa6";
import { IoCloseCircle, IoSearchOutline } from "react-icons/io5";
import { capitalizeFirstLetter } from "shared/helpers/string";
import { ConfigDomain } from "shared/types/configs";

interface FiltersHeaderProps {
  search: string;
  setSearch(s: string): void;
  isToken: boolean;
  setIsToken(b: boolean): void;
  isBoolean: boolean;
  setIsBoolean(b: boolean): void;
  isActive: boolean;
  setIsActive(b: boolean): void;
  isNotActive: boolean;
  setIsNotActive(b: boolean): void;
  domain?: ConfigDomain;
  setDomain(c?: ConfigDomain): void;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = ({
  search,
  setSearch,
  isToken,
  setIsToken,
  isActive,
  setIsActive,
  isBoolean,
  setIsBoolean,
  isNotActive,
  setIsNotActive,
  domain,
  setDomain: setDomainFromProps,
}) => {
  const setDomain = (d?: ConfigDomain) => {
    if (!d || d === domain) return setDomainFromProps(undefined);
    setDomainFromProps(d);
  };

  return (
    <Flex direction="column" gap="1rem">
      <InputGroup maxW="40em">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Pesquisar por nome ou descrição..."
        />
        <InputRightElement>
          {!search ? (
            <Icon aria-label="pesquisar" as={IoSearchOutline} />
          ) : (
            <IconButton
              aria-label="Limpar busca"
              variant="link"
              icon={<IoCloseCircle />}
              onClick={() => setSearch("")}
            />
          )}
        </InputRightElement>
      </InputGroup>

      <HStack flexWrap={{ base: "wrap", md: "nowrap" }}>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={
            !isToken && !isBoolean && !isActive && !isNotActive && !domain
              ? "yooga"
              : "gray"
          }
          onClick={() => {
            setIsToken(false);
            setIsBoolean(false);
            setIsActive(false);
            setIsNotActive(false);
            setDomain();
          }}
        >
          Todos
        </Button>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={isToken ? "yooga" : "gray"}
          onClick={() => {
            setIsToken(!isToken);
            setIsBoolean(false);
          }}
        >
          Valor manual
        </Button>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={isBoolean ? "yooga" : "gray"}
          onClick={() => {
            setIsBoolean(!isBoolean);
            setIsToken(false);
          }}
        >
          Botão habilitar
        </Button>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={isActive ? "yooga" : "gray"}
          onClick={() => {
            setIsActive(!isActive);
            setIsNotActive(false);
          }}
        >
          Ativado
        </Button>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={isNotActive ? "yooga" : "gray"}
          onClick={() => {
            setIsNotActive(!isNotActive);
            setIsActive(false);
          }}
        >
          Desativado
        </Button>

        <Menu>
          <MenuButton
            variant="outline"
            fontWeight="400"
            colorScheme={domain ? "yooga" : "gray"}
            as={Button}
            rightIcon={<FaChevronDown />}
          >
            {domain ? capitalizeFirstLetter(domain) : "Categoria"}
          </MenuButton>
          <MenuList>
            <MenuItem
              bg={domain === "delivery" ? "yooga.100" : "white"}
              onClick={() => setDomain("delivery")}
            >
              Delivery
            </MenuItem>
            <MenuItem
              bg={domain === "relatório" ? "yooga.100" : "white"}
              onClick={() => setDomain("relatório")}
            >
              Relatórios
            </MenuItem>
            <MenuItem
              bg={domain === "impressão" ? "yooga.100" : "white"}
              onClick={() => setDomain("impressão")}
            >
              Impressão
            </MenuItem>
            <MenuItem
              bg={domain === "financeiro" ? "yooga.100" : "white"}
              onClick={() => setDomain("financeiro")}
            >
              Financeiro
            </MenuItem>
            <MenuItem
              bg={domain === "fiscal" ? "yooga.100" : "white"}
              onClick={() => setDomain("fiscal")}
            >
              Fiscal
            </MenuItem>
            <MenuItem
              bg={domain === "adquirência" ? "yooga.100" : "white"}
              onClick={() => setDomain("adquirência")}
            >
              Adquirência
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};

export default FiltersHeader;
