import CardManager from "shared/components/CardManager";
import Contracts from "./cards/Contracts";
import PaymentMethod from "./cards/PaymentMethod";
import Payments from "./cards/Payments";
import { HiComputerDesktop } from "react-icons/hi2";
import { FaWallet, FaRectangleList } from "react-icons/fa6";
import { useEffect } from "react";
import { Mixpanel } from "services/mixpanel";

const Financial = () => {
  useEffect(() => {
    Mixpanel.track("customers-financial-viewed-tab");
  }, []);

  return (
    <CardManager
      tabs={[
        { label: "Contratos", icon: HiComputerDesktop },
        { label: "Método de pagamento", icon: FaWallet },
        { label: "Pagamentos", icon: FaRectangleList },
      ]}
    >
      <Contracts />
      <PaymentMethod />
      <Payments />
    </CardManager>
  );
};

export default Financial;
