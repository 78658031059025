import {
  Badge,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useMemo } from "react";
import { getStatusColor, getStatusLabel } from "shared/helpers/orderStatus";
import { orderStatus } from "shared/models/order";
import OrderActions from "./components/OrderActions";
import OrderData from "./components/OrderData";
import OrderForm from "./components/OrderForm";
import OrderLogHistory from "./components/OrderLogHistory";
import { useOrderModalContext } from "./ModalContext";

interface OrderModalProps {
  isOpen: boolean;
  onClose(): void;
}

const OrderModal: React.FC<OrderModalProps> = ({ isOpen, onClose }) => {
  const { loading, order, editing } = useOrderModalContext();
  const { customer } = useCustomerContext();

  const { label, color } = useMemo(
    () => ({
      color: getStatusColor(order?.statusId as orderStatus),
      label: getStatusLabel(order?.statusId as orderStatus),
    }),
    [order]
  );

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />
        {order && (
          <DrawerHeader>
            <Text fontSize="0.8rem" mb="0.5rem">
              {customer.name}
            </Text>
            <HStack>
              <Heading size="md">Cobrança #{order.id}</Heading>
              <Badge bg={color} color="white">
                {label}
              </Badge>
            </HStack>
            <Text fontSize="0.8rem" color="gray.500">
              {order.token}
            </Text>
            <Text fontSize="0.8rem" color="gray.500">
              {order.description}
            </Text>

            <OrderActions />
          </DrawerHeader>
        )}

        <DrawerBody pos="relative">
          {editing ? <OrderForm /> : <OrderData />}
          <OrderLogHistory />

          {loading && (
            <Flex
              pos="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              bg="gray.100"
              zIndex="1"
              align="center"
              justify="center"
            >
              <CircularProgress isIndeterminate color="yooga.400" />
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default OrderModal;
