import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Icon,
  HStack,
  Card,
  CardBody,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { createConfig, editConfig } from "services/config";
import { Mixpanel } from "services/mixpanel";
import { Config } from "shared/models/config";
import { useAppTabContext } from "../../../AppContext";

interface EditModalProps {
  isOpen: boolean;
  onClose(): void;
  config: Config;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose: onCloseFromProps, config }) => {
  const { app, updateConfig } = useAppTabContext();

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(config.trueValue);

  const onClose = () => {
    onCloseFromProps();
    setLoading(false);
    setToken(config.trueValue);
  };

  const submit = () => {
    setLoading(true);

    Mixpanel.track("customers-app-configs-submitted-edit-value");
    // If code exists edit existing config
    if (config.code)
      editConfig(config.code, {
        codigo: config.code,
        idi: app.idi,
        nome: config.name,
        valor: token,
        updated_at: config.updatedAt!,
        created_at: config.createdAt!,
      })
        .then((response) => {
          toast.success("Configuração atualizada");
          updateConfig(response.data);
          onCloseFromProps();
        })
        .catch(() => toast.error("Falha ao atualizar configuração"))
        .finally(() => setLoading(false));
    // Else create new config, following licenciamento flow
    else
      createConfig(app.idi, config.name, token)
        .then((response) => {
          toast.success("Configuração atualizada");
          updateConfig(response.data);
          onCloseFromProps();
        })
        .catch(() => toast.error("Falha ao atualizar configuraçao"))
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    setToken(config.trueValue);
  }, [config]);

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton isDisabled={loading} />
        <ModalHeader>
          <HStack>
            <Text>{config.name}</Text>
          </HStack>
        </ModalHeader>

        <ModalBody as={Stack} gap="1rem">
          {config.description && (
            <Card bg="blue.100">
              <CardBody>
                <HStack gap="1rem">
                  <Icon as={MdInfoOutline} color="blue.500" />
                  <Text fontSize="0.9rem" fontWeight="400" color="gray.600">
                    {config.description}
                  </Text>
                </HStack>
              </CardBody>
            </Card>
          )}

          <FormControl>
            <FormLabel>Valor manual</FormLabel>
            <Textarea
              placeholder="Inserir valor da configuração"
              resize="none"
              isDisabled={loading}
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button isLoading={loading} onClick={submit} colorScheme="yooga" w="100%">
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
