import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { FaPlus } from "react-icons/fa";
import { useFinancialContext } from "../../FinancialContext";
import LTV from "./LTV";
import Orders from "./Orders";

const Payments = () => {
  const {
    customer: { contracts, contract: singleContract },
  } = useCustomerContext();
  const { openCreateOrderModal } = useFinancialContext();

  return (
    <Card>
      <CardBody as={Stack}>
        <HStack mb="1rem" align="flex-end" justify="space-between">
          <Heading size="md">Pagamentos</Heading>

          <Button
            leftIcon={<FaPlus />}
            variant="outlined"
            size="sm"
            onClick={openCreateOrderModal}
          >
            Nova Cobrança
          </Button>
        </HStack>

        <LTV />

        {contracts?.length > 1 ? (
          <Tabs size="sm" variant="enclosed" colorScheme="yooga">
            <TabList>
              {contracts.map((contract) => (
                <Tab key={contract.id}>{contract.selectedPeriodicity}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {contracts.map((contract) => (
                <TabPanel key={contract.id}>
                  <Orders list={contract.orders} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          singleContract && <Orders list={singleContract.orders} />
        )}
      </CardBody>
    </Card>
  );
};

export default Payments;
