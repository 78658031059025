import { Card, CardBody, Stack } from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import IncomingInvoiceBanner from "./components/IncomingInvoiceBanner";
import IncompleteCustomerBanner from "./components/IncompleteCustomerBanner";
import LogHistory from "./components/LogHistory";
import NoCardBanner from "./components/NoCardBanner";
import NoContractBanner from "./components/NoContractBanner";
import NoCustomerBanner from "./components/NoCustomerBanner";

const Principal = () => {
  const { info } = useCustomerContext();

  return (
    <Card>
      {info.customer_id ? (
        <CardBody as={Stack} gap="1rem">
          <IncompleteCustomerBanner />
          <NoContractBanner />
          <NoCardBanner />
          <IncomingInvoiceBanner />
          <LogHistory />
        </CardBody>
      ) : (
        <CardBody>
          <NoCustomerBanner />
        </CardBody>
      )}
    </Card>
  );
};

export default Principal;
