export type BillingStatus =
  | "DESBLOQUEADO"
  | "DESBLOQUEADO_TESTE"
  | "DESBLOQUEADO_TEMPORARIO"
  | "BLOQUEADO"
  | "BLOQUEADO_TESTE"
  | "AVISO_BOLETO_DISPONIVEL"
  | "AVISO_BOLETO_VENCE_HOJE"
  | "AVISO_BOLETO_VENCIDO"
  | "AVISO_BOLETO_BLOQUEAR"
  | "AVISO_CARTAO_CADASTRE_NOVO"
  | "AVISO_CARTAO_CADASTRE_HOJE_NOVO"
  | "AVISO_CARTAO_FALHA"
  | "AVISO_CARTAO_BLOQUEAR"
  | "AVISO_BOLETO_VENCE_AMANHA"
  | "AUTOMACAO_PAUSADA";

export const BillingStatusLabels = {
  DESBLOQUEADO: "Desbloqueado",
  DESBLOQUEADO_TESTE: "Desbloqueado / Teste",
  DESBLOQUEADO_TEMPORARIO: "Desbloqueado temporariamente",
  BLOQUEADO: "Bloqueado",
  BLOQUEADO_TESTE: "Bloqueado / Teste",
  AVISO_BOLETO_DISPONIVEL: "Aviso: boleto disponível",
  AVISO_BOLETO_VENCE_HOJE: "Aviso: boleto vence hoje",
  AVISO_BOLETO_VENCIDO: "Aviso: boleto vencido",
  AVISO_BOLETO_BLOQUEAR: "Aviso: a bloquear / Boleto",
  AVISO_CARTAO_CADASTRE_NOVO: "Aviso: cadastre novo cartão",
  AVISO_CARTAO_CADASTRE_HOJE_NOVO: "Aviso: cadastre hoje novo cartão",
  AVISO_CARTAO_FALHA: "Aviso: falha no cartão",
  AVISO_CARTAO_BLOQUEAR: "Aviso: a bloquear / Cartão",
  AVISO_BOLETO_VENCE_AMANHA: "Aviso: boleto vence amanhã",
  AUTOMACAO_PAUSADA: "Automação pausada",
};

export const BillingStatusPriority: {
  [key: string]: "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH";
} = {
  // Green
  DESBLOQUEADO: "LOW",
  DESBLOQUEADO_TESTE: "LOW",
  DESBLOQUEADO_TEMPORARIO: "LOW",
  AVISO_BOLETO_DISPONIVEL: "LOW",
  AVISO_CARTAO_CADASTRE_NOVO: "LOW",
  AUTOMACAO_PAUSADA: "LOW",

  // Yellow
  AVISO_BOLETO_VENCE_HOJE: "MEDIUM",
  AVISO_CARTAO_CADASTRE_HOJE_NOVO: "MEDIUM",
  AVISO_BOLETO_VENCE_AMANHA: "MEDIUM",

  // Orange
  AVISO_BOLETO_BLOQUEAR: "HIGH",
  AVISO_CARTAO_BLOQUEAR: "HIGH",
  AVISO_BOLETO_VENCIDO: "HIGH",
  AVISO_CARTAO_FALHA: "HIGH",

  // Red
  BLOQUEADO: "VERY_HIGH",
  BLOQUEADO_TESTE: "VERY_HIGH",
};
