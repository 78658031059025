import { FaUsers } from "react-icons/fa6";
import { AppModule } from "shared/types/appModule";

const modules: Array<AppModule> = [
  {
    name: "Customers",
    icon: FaUsers,
    path: "/",
    matchs: [{ path: "/", exact: true }, { path: "/customer" }],
  },
];

export default modules;
