import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Layout";
import { loginLoader, rootLoader } from "./loaders";

import CreatePartial from "pages/CreatePartial";
import Customer from "pages/Customer";
import Home from "pages/Home";
import Login from "pages/Login";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    loader: loginLoader,
  },
  {
    path: "/create-partial",
    element: <CreatePartial />,
    loader: rootLoader,
  },
  {
    path: "/",
    element: <Layout />,
    loader: rootLoader,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/customer/:document",
        element: <Customer />,
      },
    ],
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
