import { ChakraProvider, Stack } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import AppRouter from "routes/Router";
import theme from "theme";
import Fonts from "assets/Fonts";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/pt";
import GlobalContext from "GlobalContext";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <GlobalContext>
        <Stack h="100vh" gap="0">
          <ToastContainer
            autoClose={4000}
            pauseOnHover={false}
            position="bottom-right"
          />
          <Fonts />
          <AppRouter />
        </Stack>
      </GlobalContext>
    </ChakraProvider>
  );
}

export default App;
