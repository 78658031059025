import { Box, useRadio, UseRadioProps } from "@chakra-ui/react";

const RadioCard: React.FC<React.PropsWithChildren<UseRadioProps>> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "yooga.500",
          color: "white",
          borderColor: "yooga.500",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;
