import { HStack, IconButton, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

import CustomerHeader from "./components/CustomerHeader";
import TabManager from "./components/TabManager";
import { useEffect } from "react";
import { Mixpanel } from "services/mixpanel";

const Customer = () => {
  const navigate = useNavigate();
  const { document } = useParams();

  useEffect(() => {
    if (document) {
      Mixpanel.track("customers-customer-viewed-page", { document });
    }
  }, [document]);

  return (
    <Stack
      py="1rem"
      px={{ base: "1rem", md: "2rem", lg: "3rem" }}
      flex="1"
      margin="auto"
      w="100%"
      maxW="80em"
    >
      <HStack gap="1rem">
        <IconButton
          size="lg"
          variant="ghost"
          alignSelf="flex-start"
          icon={<IoArrowBackOutline />}
          onClick={() => navigate("/")}
          aria-label="Voltar"
          ml="-1rem"
        />
        <CustomerHeader />
      </HStack>

      <TabManager />
    </Stack>
  );
};

export default Customer;
