import { useEffect } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { FaComputer, FaRegFileLines } from "react-icons/fa6";
import { FiSettings, FiUsers } from "react-icons/fi";
import { LuFileWarning } from "react-icons/lu";
import { PiCurrencyCircleDollar } from "react-icons/pi";
import { RiHistoryFill } from "react-icons/ri";
import { Mixpanel } from "services/mixpanel";
import CardManager from "shared/components/CardManager";
import { usePermissions } from "shared/hooks/usePermissions";
import AppDetails from "./cards/AppDetails";
import Configs from "./cards/Configs";
import Delivery from "./cards/Delivery";
import Fiscal from "./cards/Fiscal";
import IntegratedPayment from "./cards/IntegratedPayment";
import Logs from "./cards/Logs";
import Users from "./cards/Users";
import DangerZone from "./components/DangerZone";

const AppTab = () => {
  const { d } = usePermissions();

  useEffect(() => {
    Mixpanel.track("customers-app-viewed-tab");
  }, []);

  return (
    <CardManager
      tabs={[
        {
          label: "Detalhes do App",
          icon: FaComputer,
          hidden: d("installation-card-details-read"),
        },
        {
          label: "Fiscal",
          icon: FaRegFileLines,
          hidden: d("installation-card-fiscal-read"),
        },
        {
          label: "Usuários",
          icon: FiUsers,
          hidden: d("installation-card-users-read"),
        },
        {
          label: "Configurações",
          icon: FiSettings,
          hidden: d("installation-card-configs-read"),
        },
        {
          label: "Delivery",
          icon: BsBoxSeam,
          hidden: d("installation-card-delivery-read"),
        },
        {
          label: "Logs",
          icon: RiHistoryFill,
          hidden: d("installation-card-log-read"),
        },
        {
          label: "Operações de Risco",
          icon: LuFileWarning,
          hidden: d("installation-card-danger_zone-read"),
        },
        {
          label: "Pagamento Integrado",
          icon: PiCurrencyCircleDollar,
          hidden: d("installation-card-integrated_payment-read"),
        },
      ]}
    >
      <AppDetails />
      <Fiscal />
      <Users />
      <Configs />
      <Delivery />
      <Logs />
      <DangerZone />
      <IntegratedPayment />
    </CardManager>
  );
};

export default AppTab;
