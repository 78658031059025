import { Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { Mixpanel } from "services/mixpanel";
import Results from "./components/Results";
import SearchBar from "./components/SearchBar";

const Home = () => {
  useEffect(() => {
    Mixpanel.track("customers-home-viewed-page");
  }, []);

  return (
    <Stack
      h="100vh"
      minW={{ lg: "740px" }}
      w={{ base: "90%", lg: "85%" }}
      m="auto"
      align="center"
      p={{ base: "2rem 1rem", lg: "4rem" }}
      gap="0"
    >
      <SearchBar />
      <Results />
    </Stack>
  );
};

export default Home;
