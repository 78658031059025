export function setStorageItem(key: string, value: string) {
  return localStorage.setItem(`@yoogaCustomers:${key}`, value);
}

export function getStorageItem(key: string) {
  return localStorage.getItem(`@yoogaCustomers:${key}`);
}

export function removeStorageItem(key: string) {
  return localStorage.removeItem(`@yoogaCustomers:${key}`);
}
