import { Config } from "shared/models/config";
import { ConfigDomain } from "shared/types/configs";

export default function filterConfigs(
  configs: Config[],
  search: string,
  isToken?: boolean,
  isBoolean?: boolean,
  isActive?: boolean,
  isNotActive?: boolean,
  domain?: ConfigDomain
): Config[] {
  let filteredConfigs: Config[] = [...configs];

  if (!!search || isToken || isBoolean || isActive || isNotActive || domain)
    filteredConfigs = filteredConfigs.filter((config) => {
      if (!!domain && config.domain !== domain) return false;

      if (
        !!search &&
        !config.name.toLowerCase().includes(search.toLowerCase()) &&
        !config.description.toLowerCase().includes(search.toLowerCase())
      )
        return false;

      if (isToken && config.type !== "token") return false;

      if (isBoolean && config.type !== "boolean") return false;

      if (
        isActive &&
        ((config.type === "boolean" && !config.booleanValue) ||
          (config.type === "token" && !config.trueValue))
      )
        return false;

      if (
        isNotActive &&
        ((config.type === "boolean" && config.booleanValue) ||
          (config.type === "token" && config.trueValue))
      )
        return false;

      return true;
    });

  return filteredConfigs;
}
