import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  Divider,
  CircularProgress,
  FormErrorMessage,
} from "@chakra-ui/react";
import MaskedInput from "react-input-mask";
import { documentMask, phoneMask } from "shared/helpers/mask";
import { useEditCustomerModalContext } from "./ModalContext";

interface EditCustomerModalProps {
  isOpen: boolean;
  onClose(): void;
}

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    errors,
    handleSubmit,
    loading,
    cnpj,
    name,
    contact_email,
    trade_name,
    state_registration,
    city_registration,
    postal_code,
    logradouro,
    numero,
    complemento,
    bairro,
    telephone,
    setCnpj,
    setName,
    setContactEmail,
    setTradeName,
    setState,
    setCity,
    setPostalCode,
    setAddress,
    setNumber,
    setComplement,
    setNeighborhood,
    setPhone,
  } = useEditCustomerModalContext();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent pos="relative">
        <DrawerCloseButton />
        <DrawerHeader>Editar Cliente</DrawerHeader>

        <DrawerBody>
          <Heading size="sm" mb="1rem">
            Dados da empresa
          </Heading>
          <Flex columnGap="5%" rowGap="1rem" wrap="wrap">
            <FormControl isRequired isInvalid={!!errors.cnpj} flexBasis="37.5%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                CPF/CNPJ
              </FormLabel>
              <Input
                as={MaskedInput}
                mask={documentMask(cnpj)}
                maskChar=""
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                type="text"
              />
              {errors.cnpj && (
                <FormErrorMessage>{errors.cnpj}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors.trade_name}
              flexBasis="57.5%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                Razão Social
              </FormLabel>
              <Input
                value={trade_name}
                onChange={(e) => setTradeName(e.target.value)}
                type="text"
              />
              {errors.trade_name && (
                <FormErrorMessage>{errors.trade_name}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.name} flexBasis="47.5%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                Nome Fantasia
              </FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
              {errors.name && (
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors.contact_email}
              flexBasis="47.5%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                E-mail
              </FormLabel>
              <Input
                value={contact_email}
                onChange={(e) => setContactEmail(e.target.value)}
                type="email"
              />
              {errors.contact_email && (
                <FormErrorMessage>{errors.contact_email}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors.telephone}
              flexBasis="47.5%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                Telefone
              </FormLabel>
              <Input
                as={MaskedInput}
                mask={phoneMask(telephone)}
                maskChar=""
                value={telephone}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
              />
              {errors.telephone && (
                <FormErrorMessage>{errors.telephone}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Divider my="2rem" />

          <Heading size="sm" mb="1rem">
            Endereço
          </Heading>
          <Flex columnGap="5%" rowGap="1rem" wrap="wrap">
            <FormControl
              isRequired
              isInvalid={!!errors.postal_code}
              flexBasis="30%"
              flexGrow="1"
              mr="70%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                CEP
              </FormLabel>
              <Input
                as={MaskedInput}
                mask="99999-999"
                maskChar=""
                value={postal_code}
                onChange={(e) => setPostalCode(e.target.value)}
                type="text"
              />
              {errors.postal_code && (
                <FormErrorMessage>{errors.postal_code}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors.logradouro}
              flexBasis="100%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                Logradouro
              </FormLabel>
              <Input
                value={logradouro}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
              />
              {errors.logradouro && (
                <FormErrorMessage>{errors.logradouro}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.numero} flexBasis="30%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                Número
              </FormLabel>
              <Input
                value={numero}
                onChange={(e) => setNumber(e.target.value)}
                type="number"
              />
              {errors.numero && (
                <FormErrorMessage>{errors.numero}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl flexBasis="65%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                Complemento
              </FormLabel>
              <Input
                value={complemento}
                onChange={(e) => setComplement(e.target.value)}
                type="text"
              />
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.bairro} flexBasis="30%">
              <FormLabel fontSize="0.8rem" color="gray.500">
                Bairro
              </FormLabel>
              <Input
                value={bairro}
                onChange={(e) => setNeighborhood(e.target.value)}
                type="text"
              />
              {errors.bairro && (
                <FormErrorMessage>{errors.bairro}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors.city_registration}
              flexBasis="30%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                Cidade
              </FormLabel>
              <Input
                value={city_registration}
                onChange={(e) => setCity(e.target.value)}
                type="text"
              />
              {errors.city_registration && (
                <FormErrorMessage>{errors.city_registration}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors.state_registration}
              flexBasis="30%"
            >
              <FormLabel fontSize="0.8rem" color="gray.500">
                UF
              </FormLabel>
              <Input
                as={MaskedInput}
                mask="aa"
                maskChar=""
                value={state_registration}
                onChange={(e) => setState(e.target.value.toUpperCase())}
                type="text"
              />
              {errors.state_registration && (
                <FormErrorMessage>{errors.state_registration}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </DrawerBody>

        <DrawerFooter>
          <Button colorScheme="yooga" onClick={handleSubmit} w="100%">
            Salvar
          </Button>
        </DrawerFooter>

        {loading && (
          <Flex
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="gray.100"
            zIndex="1"
            align="center"
            justify="center"
          >
            <CircularProgress isIndeterminate color="yooga.400" />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default EditCustomerModal;
