import {
  CardBody,
  Heading,
  Icon,
  CardHeader,
  HStack,
  Card,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  CircularProgress,
  Flex,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";
import { FiUsers } from "react-icons/fi";
import { useAppTabContext } from "../AppContext";
import {
  //  IoLogOutOutline,
  IoKeyOutline,
} from "react-icons/io5";
import { useState } from "react";
import NewPasswordModal from "../components/NewPassword";
import { AppUser } from "shared/models/appUser";
import ProtectedComponent from "shared/components/ProtectedComponent";
import { usePermissions } from "shared/hooks/usePermissions";
import ProtectedButton from "shared/components/ProtectedButton";

const Users = () => {
  const { d } = usePermissions();

  const { users, loadingUsers, openCreateTempUserModal } = useAppTabContext();

  const [newPasswordUser, setNewPasswordUser] = useState<AppUser>();

  return (
    <Card>
      <NewPasswordModal
        user={newPasswordUser}
        onClose={() => setNewPasswordUser(undefined)}
      />
      <CardHeader>
        <HStack>
          <Icon as={FiUsers} />
          <Heading size="sm">Usuários</Heading>

          <ProtectedButton
            permission="installation-card-users-user_temp-create"
            isDisabled={loadingUsers}
            leftIcon={<FaPlus />}
            onClick={openCreateTempUserModal}
            variant="filled"
            size="sm"
            py="1.5rem"
            ml="auto"
          >
            Criar usuário temporário
          </ProtectedButton>
        </HStack>
      </CardHeader>

      <CardBody>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Usuário</Th>
                <Th>Nível de permissão</Th>
                <Th>Login</Th>
                {/* <Th textAlign="center">Deslogar usuário</Th> */}
                <Th textAlign="center">Gerar nova senha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loadingUsers ? (
                <Tr>
                  <Td colSpan={5}>
                    <Flex justify="center" py="1rem">
                      <CircularProgress isIndeterminate color="yooga.400" />
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                users.map((user) => (
                  <Tr key={user.code}>
                    <Td>{user.fullName}</Td>
                    <Td>{user.accessLevelLabel}</Td>
                    <Td>{user.login}</Td>
                    {/* <Td textAlign="center">
                      <IconButton
                        rounded="full"
                        variant="ghost"
                        color="red"
                        aria-label="Deslogar usuário"
                        icon={<IoLogOutOutline />}
                      />
                    </Td> */}
                    <Td textAlign="center">
                      {user.fullName !== "Usuário Temporário" && (
                        <ProtectedComponent permission="installation-card-users-update">
                          <IconButton
                            rounded="full"
                            variant="ghost"
                            aria-label="Gerar nova senha"
                            icon={<IoKeyOutline />}
                            isDisabled={d("installation-card-users-update")}
                            onClick={() => setNewPasswordUser(user)}
                          />
                        </ProtectedComponent>
                      )}
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default Users;
