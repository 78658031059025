export interface AccessLevel {
  Int32: 0 | 25 | 50 | 75 | 100 | 125;
  Valid: boolean;
}

export const AccessLevelType = {
  0: "Administrador",
  25: "Gerente",
  50: "Vendedor + Caixa",
  75: "Vendedor",
  100: "Garçom",
  125: "Somente Delivery",
};
