import { AxiosResponse } from "axios";
import { onlyNumbers } from "shared/helpers/string";
import { apiV2 } from "./http";
import { SearchResponse } from "./interfaces/search";

// Search customers merged with apps
export const search = (
  query: string
): Promise<AxiosResponse<SearchResponse[]>> => {
  const regex = new RegExp(/^\d+([./-]\d+)*$/);

  if (regex.test(query)) query = onlyNumbers(query);
  else query = query.trim();

  return apiV2.get<SearchResponse[]>("/customers/gateway", {
    params: { query },
  });
};
