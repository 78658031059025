import { AxiosResponse } from "axios";
import { IMarketplace } from "shared/types/marketplace";
import { IMarketplacePayment } from "shared/types/marketplacePayment";
import { apiV2 } from "./http";

export function listMarketplaceByIdi(
  idi: string
): Promise<AxiosResponse<IMarketplace>> {
  return apiV2.get<IMarketplace>(`marketplace/list/${idi}`);
}

export function updateMarketplacePayment(
  payload: IMarketplacePayment
): Promise<AxiosResponse> {
  return apiV2.post(`marketplace/update`, payload);
}

export function disableMarketplacePayment(idi: string): Promise<AxiosResponse> {
  return apiV2.post(`marketplace/disable/${idi}`);
}
