export function onlyNumbers(value: string) {
  return value.replace(/\D+/g, "");
}

export function phone(value: string) {
  return value.replace(/[+ \-()]/g, "");
}

export function removeBreakLine(value: string) {
  return value.replace(/\n/g, " ");
}

export function removeSpaces(value: string) {
  return value.replace(/\s+/g, "");
}

export function capitalizeAllFirstLetters(value: string) {
  if (!value) return "";
  let arr = value.split(" ");
  arr = arr.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return arr.join(" ");
}

export function capitalizeFirstLetter(value: string) {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
}
