import { useNavigate } from "react-router-dom";
import { removeStorageItem } from "services/localStorage";

export const useLogout = () => {
  const navigate = useNavigate();
  return function () {
    removeStorageItem("token");
    removeStorageItem("user");
    navigate("/login");
  };
};
