import { IconButton, Tooltip } from "@chakra-ui/react";
import { MdOutlineWarningAmber } from "react-icons/md";
import { useInstabilityContext } from "../InstabilityContext";
import { GrStatusGood } from "react-icons/gr";

const InstabilityButton = () => {
  const { openInstabilityModal, hasActiveInstabilities, instabilities } =
    useInstabilityContext();

  return instabilities?.length ? (
    <Tooltip
      label={
        hasActiveInstabilities
          ? "Alguns serviços podem estar instáveis ou fora do ar no momento. Clique para saber mais."
          : "Nenhuma instabilidade."
      }
    >
      <IconButton
        variant="ghost"
        aria-label="Instabilidades"
        onClick={openInstabilityModal}
        colorScheme={hasActiveInstabilities ? "red" : undefined}
        icon={
          hasActiveInstabilities ? <MdOutlineWarningAmber /> : <GrStatusGood />
        }
      />
    </Tooltip>
  ) : (
    <></>
  );
};

export default InstabilityButton;
