import { Button, HStack, IconButton, Text } from "@chakra-ui/react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

interface PaginationFooterProps {
  visibleLength: number;
  page: number;
  setPage(n: number): void;
  pageCount: number;
}

const PaginationFooter: React.FC<PaginationFooterProps> = ({
  visibleLength,
  page,
  setPage,
  pageCount,
}) => {
  const getPageNumbers = () => {
    const maxPagesToShow = 7;
    const pages: (number | string)[] = [];
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;

    if (pageCount <= maxPagesToShow) {
      for (let i = 1; i <= pageCount; i++) {
        pages.push(i);
      }
    } else if (page <= maxPagesBeforeCurrentPage) {
      for (let i = 1; i <= maxPagesToShow - 2; i++) {
        pages.push(i);
      }
      pages.push("...");
      pages.push(pageCount);
    } else if (page > pageCount - maxPagesAfterCurrentPage) {
      pages.push(1);
      pages.push("...");
      for (let i = pageCount - (maxPagesToShow - 3); i <= pageCount; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      pages.push("...");
      for (
        let i = page - maxPagesBeforeCurrentPage + 1;
        i <= page + maxPagesAfterCurrentPage - 1;
        i++
      ) {
        pages.push(i);
      }
      pages.push("...");
      pages.push(pageCount);
    }

    return pages;
  };
  return (
    <HStack
      borderColor="gray.200"
      borderWidth="1px"
      justify="space-between"
      p="1rem"
      rounded="5px"
      wrap="wrap"
    >
      <Text>
        {visibleLength} {visibleLength > 1 ? "itens" : "item"}
      </Text>

      <HStack wrap={{ base: "wrap", sm: "nowrap" }}>
        <IconButton
          variant="ghost"
          rounded="full"
          aria-label="Anterior"
          icon={<FaAngleLeft />}
          onClick={() => setPage(page - 1)}
          isDisabled={page === 1}
        />

        {getPageNumbers().map((pageNumber, idx) => (
          <Button
            key={idx}
            variant={page === pageNumber ? "filled" : "ghost"}
            colorScheme="gray"
            rounded="full"
            onClick={() => typeof pageNumber === "number" && setPage(pageNumber)}
            isDisabled={typeof pageNumber !== "number"}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          variant="ghost"
          rounded="full"
          aria-label="Próximo"
          icon={<FaAngleRight />}
          onClick={() => setPage(page + 1)}
          isDisabled={page === pageCount}
        />
      </HStack>
    </HStack>
  );
};

export default PaginationFooter;
