import { useGlobalContext } from "GlobalContext";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { create } from "services/customer";
import { validateCNPJ, validateCPF } from "shared/helpers/document";
import { useDebounce } from "shared/hooks/useDebounce";
import CreateCustomerModal from "./CreateCustomerModal";
import validateCustomerForm, { IErrors } from "./validations";
import { onlyNumbers } from "shared/helpers/string";
import { checkInstallations } from "services/installation";
import { searchDocument } from "services/minhaReceita";
import { searchViaCep } from "services/zipCode";
import { Mixpanel } from "services/mixpanel";

interface CreateCustomerContextData {
  loading: boolean;
  setLoading(b: boolean): void;
  cnpj: string;
  name: string;
  contact_email: string;
  trade_name: string;
  state_registration: string;
  city_registration: string;
  postal_code: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  telephone: string;
  setCnpj(s: string): void;
  setName(s: string): void;
  setContactEmail(s: string): void;
  setTradeName(s: string): void;
  setState(s: string): void;
  setCity(s: string): void;
  setPostalCode(s: string): void;
  setAddress(s: string): void;
  setNumber(s: string): void;
  setComplement(s: string): void;
  setNeighborhood(s: string): void;
  setPhone(s: string): void;
  lockDocument: boolean;
  errors: IErrors;
  handleSubmit(): void;
  warnings: IDIWarning[];
}

interface IDIWarning {
  field: "documento" | "telefone" | "e-mail";
  idi: number;
}

const Context = createContext<CreateCustomerContextData>(
  {} as CreateCustomerContextData
);

export const useCreateCustomerModalContext = () => useContext(Context);

const CreateCustomerContext = () => {
  const navigate = useNavigate();
  const {
    confirm,
    createCustomerModalOpen,
    setCreateCustomerModalOpen,
    createCustomerModalData,
    setCreateCustomerModalData,
  } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const [cnpj, setCnpj] = useState("");
  const [name, setName] = useState("");
  const [contact_email, setContactEmail] = useState("");
  const [trade_name, setTradeName] = useState("");
  const [state_registration, setState] = useState("");
  const [city_registration, setCity] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [logradouro, setAddress] = useState("");
  const [numero, setNumber] = useState("");
  const [complemento, setComplement] = useState("");
  const [bairro, setNeighborhood] = useState("");
  const [telephone, setPhone] = useState("");

  const [lockDocument, setLockDocument] = useState(false);

  const [errors, setErrors] = useState<IErrors>({});

  const [warnings, setWarnings] = useState<IDIWarning[]>([]);

  const debouncedDocumentWarning = useDebounce((document: string) => {
    removeWarnings("documento");
    if (!document) return;
    if (
      document &&
      (validateCPF(onlyNumbers(document)) ||
        validateCNPJ(onlyNumbers(document))) &&
      document.length >= 14
    ) {
      checkInstallations({ document }).then((response) => {
        if (response.data.isPresent) {
          response.data.installations!.forEach((item) =>
            addWarning("documento", item.idi)
          );
        }
      });
    }
  }, 300);

  const debouncedPhoneWarning = useDebounce((phone: string) => {
    removeWarnings("telefone");
    if (!phone) return;
    if (phone && phone.length >= 9 && phone.length <= 16) {
      checkInstallations({ phone }).then((response) => {
        if (response.data.isPresent) {
          response.data.installations!.forEach((item) =>
            addWarning("telefone", item.idi)
          );
        }
      });
    }
  }, 300);

  const debouncedEmailWarning = useDebounce((email: string) => {
    removeWarnings("e-mail");
    if (!email) return;
    if (email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      checkInstallations({ email }).then((response) => {
        if (response.data.isPresent) {
          response.data.installations!.forEach((item) =>
            addWarning("e-mail", item.idi)
          );
        }
      });
    }
  }, 300);

  const removeWarnings = (field: "documento" | "telefone" | "e-mail") =>
    setWarnings(warnings.filter((warning) => warning.field !== field));

  const addWarning = (
    field: "documento" | "telefone" | "e-mail",
    idi: number
  ) => setWarnings([...warnings, { field, idi }]);

  const onClose = () => setCreateCustomerModalOpen(false);

  const handleSubmit = () => {
    const payload = {
      cnpj: onlyNumbers(cnpj),
      name,
      contact_email,
      trade_name,
      state_registration,
      city_registration,
      postal_code,
      logradouro,
      numero,
      bairro,
      telephone: telephone.replace(/\D/g, ""),
      complemento,
    };

    const err = validateCustomerForm(payload);

    setErrors(err);

    if (Object.values(err).length) return;

    confirm("Cadastrar novo cliente", "Deseja continuar?", () => {
      Mixpanel.track("customers-new-customer-submitted-form", {
        document: cnpj,
      });
      setLoading(true);
      create(payload)
        .then(() => {
          toast.success("Novo cliente cadastrado com sucesso");
          navigate(`customer/${onlyNumbers(cnpj)}`);
          onClose();
        })
        .catch(() => toast.error("Falha ao cadastrar novo cliente"))
        .finally(() => setLoading(false));
    });
  };

  const searchZipCode = useCallback(() => {
    if (!postal_code) return;

    if (postal_code.length === 9) {
      Mixpanel.track("customers-new-customer-searched-viacep");
      searchViaCep(postal_code)
        .then(({ data }) => {
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          setState(data.uf);
        })
        .catch(() =>
          console.error(
            `Could not load data from ViaCep for CEP ${postal_code}`
          )
        );
    }
  }, [postal_code]);

  const searchMinhaReceitInfo = useCallback(() => {
    if (!cnpj) return;
    if (
      document &&
      (validateCPF(onlyNumbers(cnpj)) || validateCNPJ(onlyNumbers(cnpj))) &&
      cnpj.length >= 14
    ) {
      Mixpanel.track("customers-new-customer-searched-minha-receita", { cnpj });
      searchDocument(cnpj)
        .then(({ data }) => {
          if (data.razao_social) setTradeName(data.razao_social);
          if (data.nome_fantasia) setName(data.nome_fantasia);
          if (data.email) setContactEmail(data.email);
          if (data.ddd_telefone_1) setPhone(data.ddd_telefone_1);
          if (data.cep)
            setPostalCode(`${data.cep.slice(0, 5)}-${data.cep.slice(-3)}`);
          if (data.logradouro)
            setAddress(
              `${data.descricao_tipo_de_logradouro} ${data.logradouro}`
            );
          if (data.numero) setNumber(data.numero);
          if (data.complemento) setComplement(data.complemento);
          if (data.bairro) setNeighborhood(data.bairro);
          if (data.municipio) setCity(data.municipio);
          if (data.uf) setState(data.uf);
        })
        .catch(() =>
          console.error(
            `Could not load data from MinhaReceita for document ${cnpj}`
          )
        );
    }
  }, [cnpj]);

  useEffect(() => {
    if (createCustomerModalOpen) {
      Mixpanel.track("customers-new-customer-opened-modal");
    }
  }, [createCustomerModalOpen]);

  useEffect(() => {
    if (createCustomerModalData) {
      setLockDocument(true);
      setName(createCustomerModalData.name);
      setTradeName(createCustomerModalData.trade_name);
      setCnpj(createCustomerModalData.document);

      setCreateCustomerModalData(undefined);
    }
  }, [createCustomerModalData, setCreateCustomerModalData]);

  useEffect(() => {
    searchZipCode();
  }, [searchZipCode]);

  useEffect(() => {
    searchMinhaReceitInfo();
  }, [searchMinhaReceitInfo]);

  useEffect(() => {
    debouncedDocumentWarning(cnpj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj]);

  useEffect(() => {
    debouncedPhoneWarning(telephone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telephone]);

  useEffect(() => {
    debouncedEmailWarning(contact_email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact_email]);

  useEffect(() => {
    if (!createCustomerModalOpen) {
      setCnpj("");
      setName("");
      setContactEmail("");
      setTradeName("");
      setState("");
      setCity("");
      setPostalCode("");
      setAddress("");
      setNumber("");
      setComplement("");
      setNeighborhood("");
      setPhone("");
      setLockDocument(false);
      setErrors({});
      setLoading(false);
    }
  }, [createCustomerModalOpen]);

  const data: CreateCustomerContextData = {
    loading,
    setLoading,
    cnpj,
    name,
    contact_email,
    trade_name,
    state_registration,
    city_registration,
    postal_code,
    logradouro,
    numero,
    complemento,
    bairro,
    telephone,
    setCnpj,
    setName,
    setContactEmail,
    setTradeName,
    setState,
    setCity,
    setPostalCode,
    setAddress,
    setNumber,
    setComplement,
    setNeighborhood,
    setPhone,
    lockDocument,
    errors,
    handleSubmit,
    warnings,
  };

  return (
    <Context.Provider value={data}>
      <CreateCustomerModal isOpen={createCustomerModalOpen} onClose={onClose} />
    </Context.Provider>
  );
};

export default CreateCustomerContext;
