import { Button, ButtonProps } from "@chakra-ui/react";
import { usePermissions } from "shared/hooks/usePermissions";
import ProtectedComponent from "./ProtectedComponent";

interface ProtectedButtonProps extends ButtonProps {
  permission: string;
}

const ProtectedButton: React.FC<ProtectedButtonProps> = ({
  permission,
  isDisabled,
  ...props
}) => {
  const { d } = usePermissions();

  return (
    <ProtectedComponent permission={permission}>
      <Button isDisabled={d(permission) || isDisabled} {...props} />
    </ProtectedComponent>
  );
};

export default ProtectedButton;
