const colors = {
  yoogaOld: {
    100: "#afdff7",
    200: "#6fc3ed",
    300: "#19A1E6",
    400: "#048cd1",
  },
  yooga: {
    "50": "#E8F6FD",
    "100": "#BFE5F8",
    "200": "#95D4F4",
    "300": "#6CC3EF",
    "400": "#42B2EB",
    "500": "#19A1E6",
    "600": "#1481B8",
    "700": "#0F618A",
    "800": "#0A405C",
    "900": "#05202E",
  },
  bg: {
    light: "#F5F5F5",
    dark: "#090D11",
  },
};

export default colors;
