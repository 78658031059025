import { 
  Drawer, 
  DrawerBody, 
  DrawerCloseButton, 
  DrawerContent, 
  DrawerHeader, 
  DrawerOverlay, 
  Table, 
  TableContainer, 
  Tbody, 
  Td, 
  Th,
  Thead, 
  Tr,
  Text,
  Card,
  CardBody,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppTabContext } from "../AppContext";
import InstallationStatus, { IInstallationStatus } from "shared/models/installationStatus";
import { getInstallationStatusHistory } from "services/app";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { MdInfoOutline } from "react-icons/md";

interface AppStatusHistoryModalProps {
  isOpen: boolean;
  onClose(): void;
}

const AppStatusHistoryModal: React.FC<AppStatusHistoryModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { app, setAppStatusHistory } = useAppTabContext();

  const [loading, setLoading] = useState(false);
  const [
    installationStatus, 
    setStatusHistory
  ] = useState<InstallationStatus[]>([]);

  const fetch = () => {
    if (!app.idi) {
      return toast.error("Não foi possível encontrar a instalação");
    }

    setLoading(true);
    getInstallationStatusHistory(app.idi)
      .then((response) => {
        setStatusHistory(response.data.map((data: IInstallationStatus) => 
          new InstallationStatus(data)
        ))
      })
      .catch((err: AxiosError<{ message: string }>) => {
        toast.error("Erro ao buscar histórico de status");
        console.error(err);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useEffect(() => {
    if (isOpen) {
      fetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, app.idi]);

  onClose = () => {
    setAppStatusHistory(false);
  };

  return (
    <Drawer
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
    <DrawerOverlay/>
    <DrawerContent>
      <DrawerCloseButton isDisabled={loading} />
      <DrawerHeader>Histórico da automação (IDI: {app.idi})</DrawerHeader>

      <Card bg="blue.100" marginBottom="18px">
        <CardBody>
          <HStack gap="1rem">
            <Icon as={MdInfoOutline} color="blue.500" />
            <Text fontSize="0.9rem" fontWeight="400" color="gray.600">
            A automação é executada de terça a sexta, às 13h, exceto feriados nacionais.
            Somente as alterações de status são salvas e exibidas.
            </Text>
          </HStack>
        </CardBody>
      </Card>

      <DrawerBody>
        {installationStatus?.length ? ( 
          <TableContainer>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th>Pré-automação</Th>
                  <Th>Pós automação</Th>
                  <Th>Data</Th>
                </Tr>
              </Thead>
              <Tbody>
                {installationStatus.map((status, index) => (
                  <Tr key={index}>
                    <Td
                      color={
                        status.lastBillingStatusPriority === "LOW"
                          ? "green.400"
                          : status.lastBillingStatusPriority === "MEDIUM"
                          ? "yellow.400"
                          : status.lastBillingStatusPriority === "HIGH"
                          ? "orange.400"
                          : "red.400"
                      }
                    >{status.lastBillingStatusLabel}</Td>
                    <Td
                      color={
                      status.currentBillingStatusPriority === "LOW"
                        ? "green.400"
                        : status.currentBillingStatusPriority === "MEDIUM"
                        ? "yellow.400"
                        : status.currentBillingStatusPriority === "HIGH"
                        ? "orange.400"
                        : "red.400"
                      }
                    >{status.currentBillingStatusLabel}</Td>
                    <Td>{moment(status.created_at).format('DD/MM/YYYY')}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Text>Nenhum status registrado pela automação.</Text>
        )}
      </DrawerBody>
    </DrawerContent>
    </Drawer>
  );
};

export default AppStatusHistoryModal;
