import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Highlight,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import { FeatureUpdate } from "shared/types/featureUpdate";
import UpdateBadge from "./UpdateBadge";

interface TemplateProps {
  update: FeatureUpdate;
}

const Template: React.FC<TemplateProps> = ({ update }) => {
  return (
    <Card>
      <CardHeader>
        <Text fontSize="sm">{update.date.format("DD/MM/YYYY")}</Text>

        {/* TITLE */}
        <Heading size="md">{update.title}</Heading>

        {/* place the badges here */}
        <HStack mt="0.5rem">
          <UpdateBadge type={update.type} />
          {update.version && (
            <UpdateBadge type="version" version={update.version} />
          )}
        </HStack>
      </CardHeader>

      {/* THE BODY CAN BE TEXT OR ANY COMPONENT */}
      <CardBody>
        <Highlight
          query={update.highlight ?? []}
          styles={{ px: "0.5", py: "0.5", bg: "yooga.50" }}
        >
          {update.content}
        </Highlight>
      </CardBody>

      {/* SUGESTION: USE THE FOOTER FOR DISPLAYING THE IMAGE */}
      {update.imageSrc && (
        <CardFooter>
          <Image src={update.imageSrc} />
        </CardFooter>
      )}
    </Card>
  );
};

export default Template;
