import { AxiosResponse } from "axios";
import { IProduct } from "shared/models/product";
import { apiV1 } from "./http";

export function load(): Promise<AxiosResponse<IProduct[]>> {
  return apiV1.get<IProduct[]>("products/list");
}

export function deleteProduct(
  contractId: number,
  productId: number
): Promise<AxiosResponse> {
  return apiV1.delete(`contracts/${contractId}/services/${productId}`);
}
