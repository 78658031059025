import {
  useColorMode,
  useColorModeValue,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

const ColorModeSwitcher = () => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("Escuro (experimental)", "Claro");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <Tooltip label={`Modo ${text}`} hasArrow>
      <IconButton
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        variant="ghost"
        aria-label={`Alterar para modo ${text}`}
      />
    </Tooltip>
  );
};

export default ColorModeSwitcher;
