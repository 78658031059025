import {
  Button,
  ButtonGroup,
  Divider,
  DrawerBody,
  Heading,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useChangePlanModalContext } from "../ModalContext";
import CurrentPlan from "./CurrentPlan";

interface ServicesProps {}

const Services: React.FC<ServicesProps> = () => {
  const {
    nextStep,
    selectedPlan,
    setSelectedPlan,
    periodicity,
    setPeriodicity,
    plan,
  } = useChangePlanModalContext();

  return (
    <DrawerBody scrollBehavior="auto">
      <Stack py="1rem" minH="100%">
        <CurrentPlan />

        <Divider my="1rem" />

        <Heading size="sm">Qual vai ser a periodicidade?</Heading>

        <ButtonGroup isAttached>
          <Button
            onClick={() => setPeriodicity("monthly")}
            colorScheme={periodicity === "monthly" ? "yooga" : "gray"}
            isDisabled={plan?.periodicity === "yearly"}
            w="6rem"
          >
            Mensal
          </Button>
          <Button
            onClick={() => setPeriodicity("yearly")}
            colorScheme={periodicity === "yearly" ? "yooga" : "gray"}
            w="6rem"
          >
            Anual
          </Button>
        </ButtonGroup>

        {plan?.periodicity === "yearly" && (
          <Text variant="label">
            Não é possível realizar troca de periodicidade de um plano anual
          </Text>
        )}

        <Divider m="0.5rem 0 1rem" />

        <Heading size="sm">Qual vai ser o novo plano?</Heading>

        <Select
          value={selectedPlan}
          onChange={(e) =>
            setSelectedPlan(
              Number(e.target.value) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
            )
          }
        >
          <option disabled value="-1">
            Selecione um plano
          </option>
          <option
            value={periodicity === "monthly" ? "1" : "5"}
            disabled={plan?.id === 1 || plan?.id! >= 5}
          >
            Básico
          </option>
          <option
            value={periodicity === "monthly" ? "2" : "6"}
            disabled={plan?.id === 2 || plan?.id! >= 6}
          >
            Essencial
          </option>
          <option
            value={periodicity === "monthly" ? "3" : "7"}
            disabled={plan?.id === 3 || plan?.id! >= 7}
          >
            Completo
          </option>
          <option
            value={periodicity === "monthly" ? "4" : "8"}
            disabled={plan?.id === 1 || plan?.id === 8}
          >
            Premium
          </option>
        </Select>

        {plan?.id! > 5 && (
          <Text variant="label">
            Não é possível realizar downsell de periodicidade de um plano anual
          </Text>
        )}

        <Button
          mt="auto"
          colorScheme="yooga"
          onClick={nextStep}
          isDisabled={selectedPlan < 0}
        >
          Continuar
        </Button>
      </Stack>
    </DrawerBody>
  );
};

export default Services;
