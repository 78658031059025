import { AxiosResponse } from "axios";
import { IOrder } from "shared/models/order";
import { apiDefault, apiV2 } from "./http";
import {
  CreateOrderRequestData,
  PatchOrderRequestData,
  VerifyMarvinResponseData,
} from "./interfaces/order";

export function load(token: string): Promise<AxiosResponse<IOrder>> {
  return apiV2.get<IOrder>(`orders/${token}`);
}

export function patch(
  payload: PatchOrderRequestData
): Promise<AxiosResponse<IOrder>> {
  return apiV2.patch<IOrder>("orders", payload);
}

export function payWithCard(token: string): Promise<AxiosResponse> {
  return apiV2.get(`pagarme/one-off/${token}`);
}

export function cancel(orderId: number): Promise<AxiosResponse> {
  return apiV2.get(`orders/cancel/${orderId}`);
}

export function generateInvoice(token: string): Promise<AxiosResponse> {
  return apiDefault.get(`payment/order/generate-invoice/${token}`);
}

export function createOrder(
  payload: CreateOrderRequestData
): Promise<AxiosResponse> {
  return apiV2.post("orders", payload);
}

export function payWithMarvin(token: string): Promise<AxiosResponse> {
  return apiV2.post(`marvin/one-off/${token}`);
}

export function checkMarvinPayment(
  orderId: number
): Promise<AxiosResponse<VerifyMarvinResponseData>> {
  return apiV2.get<VerifyMarvinResponseData>(
    `marvin/verify-capture/${orderId}`
  );
}
