import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Select,
  Card,
  CardBody,
  Icon,
  HStack,
  Text,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useGlobalContext } from "GlobalContext";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { changePaymentMethod } from "services/contract";
import { Mixpanel } from "services/mixpanel";

interface ChangePaymentMethodModalProps {
  isOpen: boolean;
  onClose(): void;
}

const ChangePaymentMethodModal: React.FC<ChangePaymentMethodModalProps> = ({
  isOpen,
  onClose: onCloseFromProps,
}) => {
  const { confirm } = useGlobalContext();

  const {
    customer: { contract },
    loadCustomer,
  } = useCustomerContext();
  const [loading, setLoading] = useState(false);

  const [method, setMethod] = useState<1 | 2>(contract?.bankId!);

  const onClose = () => {
    onCloseFromProps();
    setLoading(false);
    setMethod(contract?.bankId!);
  };

  const submit = () => {
    confirm(
      `Alterar para  ${method === 1 ? "Cartão de Crédito" : "Boleto Bancário"}`,
      "Deseja continuar?",
      async () => {
        Mixpanel.track(
          "customers-financial-payment-method-submitted-new-payment-method"
        );
        setLoading(true);

        changePaymentMethod(contract?.id!, method)
          .then(() => {
            onCloseFromProps();
            toast.success("Método de pagamento alterado");
            loadCustomer();
          })
          .catch((error: AxiosError) => {
            if (error.response?.status === 422)
              return toast.error(
                "Forma de pagamento já foi alterada para esse cliente dentro dos últimos 30 dias",
                { autoClose: 5000 }
              );

            toast.error("Falha ao alterar forma de pagamento");
          })
          .finally(() => setLoading(false));
      }
    );
  };

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton isDisabled={loading} />
        <ModalHeader>Alterar Método de Pagamento</ModalHeader>

        <ModalBody>
          <Card bg="yellow.100">
            <CardBody>
              <HStack gap="1rem">
                <Icon as={MdInfoOutline} color="yellow.500" />
                <Text fontSize="0.9rem" fontWeight="400" color="gray.600">
                  Só é possível mudar o método de pagamento de um cliente com um
                  intervalo de 30 dias desde a última alteração.
                </Text>
              </HStack>
            </CardBody>
          </Card>

          <FormControl mt="1rem">
            <FormLabel fontSize="0.8rem" color="gray.500"></FormLabel>
            <Select
              id="select-payment-method"
              value={method}
              onChange={(e) => setMethod(Number(e.target.value) as 1 | 2)}
            >
              <option value="1">Cartão de Crédito</option>
              <option value="2">Boleto Bancário</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            onClick={submit}
            isDisabled={method === contract?.bankId}
            colorScheme="yooga"
            w="100%"
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
