import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Stack,
  useRadioGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { toast } from "react-toastify";
import { updateDelivery } from "services/delivery";
import { Mixpanel } from "services/mixpanel";
import RadioCard from "shared/components/form/RadioCard";
import { IDelivery } from "shared/models/delivery";
import { useDeliveryContext } from "../DeliveryContext";

interface EditDeliveryModalProps {
  isOpen: boolean;
  onClose(): void;
}

const EditDeliveryModal: React.FC<EditDeliveryModalProps> = ({
  isOpen,
  onClose: onCloseFromProps,
}) => {
  const { delivery, updateDeliveryData } = useDeliveryContext();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(delivery?.name ?? "");
  const [description, setDescription] = useState(delivery?.description ?? "");
  const [menuLink, setMenuLink] = useState(delivery?._url ?? "");
  const [redirect, setRedirect] = useState(delivery?.redirect ?? "");
  const [pixel, setPixel] = useState(delivery?.pixel ?? "");
  const [token, setToken] = useState(delivery?.zoopSellerId ?? "");
  const [tag, setTag] = useState(delivery?.googleAnalyticsToken ?? "");
  const [latitude, setLatitude] = useState(delivery?.latitude ?? 0);
  const [longitude, setLongitude] = useState(delivery?.longitude ?? 0);

  const [deliveryType, setDeliveryType] = useState<
    "neighborhood" | "range" | "withdrawal"
  >(
    delivery?.hasNeighborhood
      ? "neighborhood"
      : delivery?.onlyWithdrawal
      ? "withdrawal"
      : "range"
  );

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: deliveryType,
    onChange: (e: "neighborhood" | "range" | "withdrawal") =>
      setDeliveryType(e),
  });
  const group = getRootProps();

  const onClose = () => {
    onCloseFromProps();
  };

  const onSubmit = () => {
    setLoading(true);
    const payload: IDelivery = {
      ...delivery!.rawData,
      name: deliveryType !== "withdrawal" ? name : delivery!.name,
      description:
        deliveryType !== "withdrawal" ? description : delivery!.description,
      url: deliveryType !== "withdrawal" ? menuLink : delivery!._url,
      redirect: deliveryType !== "withdrawal" ? redirect : delivery!.redirect,
      pixel: deliveryType !== "withdrawal" ? pixel : delivery!.pixel,
      zoop_seller_id:
        deliveryType !== "withdrawal" ? token : delivery!.zoopSellerId,
      google_analytics_token:
        deliveryType !== "withdrawal" ? tag : delivery!.googleAnalyticsToken,
      latitude: deliveryType === "range" ? latitude : 0,
      longitude: deliveryType === "range" ? longitude : 0,
      manual_address: deliveryType !== "range" ? 1 : 0,
      has_bairro: deliveryType === "neighborhood" ? 1 : 0,
      only_withdrawal: deliveryType === "withdrawal" ? 1 : 0,
    };

    Mixpanel.track("customers-app-delivery-submitted-edit");

    updateDelivery(payload)
      .then((response) => {
        toast.success("Dados do delivery atualizados");
        updateDeliveryData(payload);
      })
      .catch(() => toast.error("Falha ao atualizar dados do delivery"))
      .finally(() => setLoading(false));
  };

  // Reset Modal Data
  useEffect(() => {
    setLoading(false);
    setName(delivery?.name ?? "");
    setDescription(delivery?.description ?? "");
    setMenuLink(delivery?._url ?? "");
    setRedirect(delivery?.redirect ?? "");
    setPixel(delivery?.pixel ?? "");
    setToken(delivery?.zoopSellerId ?? "");
    setTag(delivery?.googleAnalyticsToken ?? "");
    setLatitude(delivery?.latitude ?? 0);
    setLongitude(delivery?.longitude ?? 0);
    setDeliveryType(
      delivery?.hasNeighborhood
        ? "neighborhood"
        : delivery?.onlyWithdrawal
        ? "withdrawal"
        : "range"
    );
  }, [delivery, isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton isDisabled={loading} />
        <DrawerHeader>
          <HStack align="center">
            <Icon as={BsBoxSeam} />
            <Heading size="sm">Editar informações de delivery</Heading>
          </HStack>
        </DrawerHeader>

        <DrawerBody as={Stack} justify="space-between" gap="1.5rem">
          <FormControl>
            <FormLabel>Ponto de venda</FormLabel>

            <HStack {...group}>
              <RadioCard
                isDisabled={loading}
                {...getRadioProps({ value: "neighborhood" })}
              >
                Bairros
              </RadioCard>
              <RadioCard
                isDisabled={loading}
                {...getRadioProps({ value: "range" })}
              >
                Raios
              </RadioCard>
              <RadioCard
                isDisabled={loading}
                {...getRadioProps({ value: "withdrawal" })}
              >
                Apenas Retirada
              </RadioCard>
            </HStack>
          </FormControl>

          <Fade in={deliveryType !== "withdrawal"}>
            <Flex wrap="wrap" rowGap="1rem" columnGap="4%">
              <FormControl flexBasis="100%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Nome
                </FormLabel>
                <Input
                  isDisabled={loading}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <FormControl flexBasis="100%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Descrição
                </FormLabel>
                <Input
                  isDisabled={loading}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>

              <FormControl flexBasis="100%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Link do cardápio digital
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>delivery.yooga.app/</InputLeftAddon>
                  <Input
                    isDisabled={loading}
                    value={menuLink}
                    onChange={(e) => setMenuLink(e.target.value)}
                  />
                </InputGroup>
              </FormControl>

              <FormControl flexBasis="48%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Redirecionamento
                </FormLabel>
                <Input
                  isDisabled={loading}
                  value={redirect}
                  onChange={(e) => setRedirect(e.target.value)}
                />
              </FormControl>

              <FormControl flexBasis="48%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Pixel do Facebook
                </FormLabel>
                <Input
                  isDisabled={loading}
                  value={pixel}
                  onChange={(e) => setPixel(e.target.value)}
                />
              </FormControl>

              <FormControl flexBasis="48%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Token do pagamento online
                </FormLabel>
                <Input
                  isDisabled={loading}
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
              </FormControl>

              <FormControl flexBasis="48%">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Tag do Google Analytics
                </FormLabel>
                <Input
                  isDisabled={loading}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
              </FormControl>
            </Flex>
          </Fade>

          <Fade in={deliveryType === "range"}>
            <Divider mb="1rem" />
            <HStack gap="1rem">
              <FormControl flex="1">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Latitude
                </FormLabel>
                <NumberInput
                  isDisabled={loading}
                  onChange={(value) => setLatitude(Number(value))}
                  value={latitude}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl flex="1">
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Longitude
                </FormLabel>
                <NumberInput
                  isDisabled={loading}
                  onChange={(value) => setLongitude(Number(value))}
                  value={longitude}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </HStack>
          </Fade>

          <Button
            mt="auto"
            colorScheme="yooga"
            isLoading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default EditDeliveryModal;
