import { Button, ButtonGroup } from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { Mixpanel } from "services/mixpanel";
import { checkMarvinPayment } from "services/order";
import ProtectedButton from "shared/components/ProtectedButton";
import { orderStatus } from "shared/models/order";
import { useOrderModalContext } from "../ModalContext";

const OrderActions = () => {
  const {
    editing,
    startEditing,
    loading,
    order,
    abortEditing,
    processPayment,
    cancelOrder,
    generateOrderInvoice,
    processMarvinPayment,
  } = useOrderModalContext();
  const {
    customer: { hasMarvin },
  } = useCustomerContext();
  const { confirm } = useGlobalContext();
  const [verifyMarvinLoading, setVerifyMarvinLoading] = useState(false);

  const downloadInvoice = () => {
    Mixpanel.track("customers-financial-order-clicked-download-invoice");
    window.open(order?.invoiceLink);
  };

  const handleVerifyMarvin = () => {
    setVerifyMarvinLoading(true);
    checkMarvinPayment(order?.id!)
      .then((res) => {
        let message = "";

        if (res.data.status === 201) message = "Cobrança paga";

        if (res.data.status === 200)
          message = "A cobrança foi cancelada ou expirada";

        if (res.data.status === 202)
          message = "A cobrança ainda está em processamento";

        confirm(message, res.data.response);
      })
      .catch(() => toast.error("Falha ao checar cobrança com Marvin"))
      .finally(() => setVerifyMarvinLoading(false));
  };

  return order?.statusId !== orderStatus.CANCELADO &&
    order?.statusId !== orderStatus.PAGO ? (
    <ButtonGroup size="sm" variant="filled" mt="1rem" isDisabled={loading}>
      {editing ? (
        <>
          <Button variant="outlined" onClick={abortEditing}>
            Cancelar Edição
          </Button>
        </>
      ) : (
        <>
          {!order?.invoice &&
            !order?.invoices.length &&
            order?.bankId !== 3 && (
              <Button onClick={startEditing}>Editar Cobrança</Button>
            )}

          {order?.canGenerateInvoice && (
            <Button onClick={generateOrderInvoice}>Gerar boleto</Button>
          )}

          {order?.invoiceLink && (
            <Button onClick={downloadInvoice}>Baixar boleto</Button>
          )}

          {order?.bankId === 1 && order.statusId === 1 && (
            <Button onClick={processPayment}>Retentar Cobrança</Button>
          )}

          {order?.statusId === 1 && hasMarvin && (
            <ProtectedButton
              permission="customer-marvin"
              bg="orange"
              onClick={processMarvinPayment}
            >
              Cobrar com Marvin
            </ProtectedButton>
          )}

          {order?.statusId === 1 && order?.bankId === 3 && (
            <ProtectedButton
              permission="customer-marvin"
              bg="orange"
              onClick={handleVerifyMarvin}
              isLoading={verifyMarvinLoading}
            >
              Verificar Pagamento Marvin
            </ProtectedButton>
          )}

          <Button onClick={cancelOrder}>Cancelar Cobrança</Button>
        </>
      )}
    </ButtonGroup>
  ) : (
    <></>
  );
};

export default OrderActions;
