import { useGlobalContext } from "GlobalContext";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { SearchResponse } from "services/interfaces/search";
import { Mixpanel } from "services/mixpanel";
import { search as searchCustomersAndApp } from "services/search";
import Home from "./Home";

interface HomeContextData {
  search(query: string): void;
  results: SearchResponse[];
  loading: boolean;
  failedSearch: boolean;
  setIsHistoryHovered(b: boolean): void;
  setIsBarFocused(b: boolean): void;
  showHistory: boolean;
}

const Context = createContext<HomeContextData>({} as HomeContextData);

export const useHomeContext = (): HomeContextData => useContext(Context);

const HomeContext = () => {
  const { searchText } = useGlobalContext();
  const [results, setResults] = useState<SearchResponse[]>([]);
  const [failedSearch, setFailedSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBarFocused, setIsBarFocused] = useState(false);
  const [isHistoryHovered, setIsHistoryHovered] = useState(false);

  const searchTerms: string[] = [];

  const showHistory = useMemo(
    () => !searchText && (isBarFocused || isHistoryHovered),
    [searchText, isBarFocused, isHistoryHovered]
  );

  const search = useCallback((query: string) => {
    if (searchTerms[searchTerms.length - 1] === query) return;

    setFailedSearch(false);
    setResults([]);
    if (!query) {
      searchTerms.length = 0;
      setLoading(false);
      return;
    }

    if (searchTerms[searchTerms.length - 1] === query) {
      return;
    }

    Mixpanel.track("customers-home-searched-customer");
    setLoading(true);
    searchTerms.push(query);

    searchCustomersAndApp(query)
      .then((response) => {
        if (
          !searchTerms.length ||
          searchTerms[searchTerms.length - 1] !== query
        )
          return;

        searchTerms.length = 0;
        searchTerms.push(query);

        setLoading(false);
        if (!response.data.length) return setFailedSearch(true);
        setResults(response.data);
      })
      .catch(() => {
        setFailedSearch(true);
        setLoading(false);
        toast.error("Não foi possível buscar os clientes");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useMemo<HomeContextData>(
    () => ({
      search,
      results,
      loading,
      failedSearch,
      setIsHistoryHovered,
      setIsBarFocused,
      showHistory,
    }),
    [search, results, loading, failedSearch, showHistory]
  );

  return (
    <Context.Provider value={data}>
      <Home />
    </Context.Provider>
  );
};

export default HomeContext;
