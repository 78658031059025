import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { FaCodeBranch } from "react-icons/fa6";

interface VersionBadgeProps {
  version: string;
}

const VersionBadge: React.FC<VersionBadgeProps> = ({ version }) => {
  return (
    <Tag size="sm" rounded="full">
      <TagLeftIcon as={FaCodeBranch} />
      <TagLabel>Corrigido em {version}</TagLabel>
    </Tag>
  );
};

export default VersionBadge;
