import {
  Button,
  Heading,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContractModalContext } from "../ModalContext";
import { MdOutlineDiscount } from "react-icons/md";
import { FaRegTrashCan } from "react-icons/fa6";
import { R$ } from "shared/helpers/currency";

interface AddedServicesListProps {
  showDiscount?: boolean;
  showRemove?: boolean;
  variant?: "simple" | "stripped" | "unstyled";
}

const AddedServicesList: React.FC<AddedServicesListProps> = ({
  showDiscount = false,
  showRemove = false,
  variant = "simple",
}) => {
  const { addedProducts, removePlan, setDiscountProduct } =
    useContractModalContext();

  return (
    <Stack>
      {addedProducts.length && (
        <>
          <Heading mt="2rem" mb="0.5rem" size="sm">
            Planos Adicionados
          </Heading>
          <TableContainer>
            <Table size="sm" variant={variant}>
              <Thead>
                <Tr>
                  <Th>Plano</Th>
                  <Th>Periodicidade</Th>
                  <Th textAlign={showDiscount || showRemove ? "left" : "right"}>
                    Valor
                  </Th>
                  {showDiscount && <Th></Th>}
                  {showRemove && <Th></Th>}
                </Tr>
              </Thead>
              <Tbody>
                {addedProducts.map((product) => (
                  <Tr key={product?.plan?.id}>
                    <Td>{product.plan?.name}</Td>
                    <Td>{product.plan?.selectedPeriodicity}</Td>
                    <Td
                      textAlign={showDiscount || showRemove ? "left" : "right"}
                    >
                      {product.discount ? (
                        <Stack>
                          <Text fontSize="0.8rem" as="s">
                            {R$(product.discount.product_total_value)}
                          </Text>
                          <Text fontSize="0.8rem">
                            {R$(product.discount.product_value_with_discount)}
                          </Text>
                        </Stack>
                      ) : (
                        product.plan?.priceString
                      )}
                    </Td>
                    {showDiscount && (
                      <Td>
                        <Button
                          variant="ghost"
                          colorScheme="yooga"
                          size="xs"
                          leftIcon={<MdOutlineDiscount />}
                          onClick={() => setDiscountProduct(product)}
                        >
                          Aplicar Desconto
                        </Button>
                      </Td>
                    )}
                    {showRemove && (
                      <Td>
                        <IconButton
                          aria-label="Remover"
                          colorScheme="red"
                          variant="ghost"
                          size="sm"
                          icon={<FaRegTrashCan />}
                          onClick={() => removePlan(product.plan?.id as number)}
                        />
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Stack>
  );
};

export default AddedServicesList;
