import { useGlobalContext } from "GlobalContext";

export const usePermissions = () => {
  const { permissions } = useGlobalContext();

  return {
    // Enabled / Allowed
    e: function (permission: string): boolean {
      return permissions.some((p) => p.name === permission);
    },
    // Disabled / Not Allowed
    d: function (permission: string): boolean {
      return !permissions.some((p) => p.name === permission);
    },
  };
};
