import { R$ } from "shared/helpers/currency";
import { Discount } from "./discount";
import { IPlan, Plan } from "./plan";

export interface IProduct {
  id?: number;
  name?: string;
  service?: IPlan;
  plans?: IPlan[];
  price?: string;
}

export class Product {
  id?: number;

  name?: string;

  plan?: Plan;

  discount?: Discount;

  plans: Plan[] = [];

  value: number = 0;

  constructor(data?: IProduct) {
    if (data) {
      this.id = data.id;
      this.name = data.name;

      if (data.price) this.value = Number(data.price);

      if (data.service) this.plan = new Plan(data.service);

      if (data.plans)
        data.plans.forEach((plan) => this.plans.push(new Plan(plan)));
    }
  }

  get priceString() {
    return R$(this.value);
  }
}
