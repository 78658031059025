import {
  Badge,
  Card,
  CardBody,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Order, orderStatus } from "shared/models/order";
import { IoOptions } from "react-icons/io5";
import { useFinancialContext } from "../../FinancialContext";
import { getStatusColor, getStatusLabel } from "shared/helpers/orderStatus";

interface OrderProps {
  order: Order;
}

const OrderComponent: React.FC<OrderProps> = ({ order }) => {
  const { openOrderModal } = useFinancialContext();

  const color = getStatusColor(order.statusId as orderStatus);
  const label = getStatusLabel(order.statusId as orderStatus);

  return (
    <Card
      borderLeft="1rem solid"
      borderLeftColor={color}
      flexBasis={{
        base: "100%",
        md: "calc(50% - 0.7rem)",
        lg: "calc(33.3% - 0.7rem)",
      }}
    >
      <CardBody p="0.7rem" as={Stack} justify="space-between">
        <Stack>
          <HStack justify="space-between">
            <Badge bg={color} color="white">
              {label}
            </Badge>

            <IconButton
              aria-label="Opções"
              variant="ghost"
              onClick={() => openOrderModal(order.token)}
              icon={<IoOptions size="1.2rem" />}
            />
          </HStack>

          <Heading size="sm">{order.dueDateMonth}</Heading>
        </Stack>

        <HStack justify="space-between" align="flex-end">
          <Stack>
            {order.due_date && (
              <Text fontSize="0.8rem">
                Data de vencimento: {order.due_date.format("DD/MMM")}
              </Text>
            )}
            {order.statusId === 2 && (
              <Text fontSize="0.8rem">
                Pago em: {order.complete_at?.format("DD/MMM")}
              </Text>
            )}
          </Stack>

          <Heading size="md">{order.priceString}</Heading>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default OrderComponent;
