import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  TabPanel,
  Tag,
  TagLabel,
  TagRightIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { TbListDetails } from "react-icons/tb";
import { R$ } from "shared/helpers/currency";
import { DeliveryOrder } from "shared/models/deliveryOrder";
import { DeliveryOrderStatus } from "shared/types/deliveryOrder";
import { useDeliveryContext } from "../DeliveryContext";
import PaymentDetailsModal from "./PaymentDetailsModal";

const OrderHistory = () => {
  const { orders, changeOrderStatus, statusLabels } = useDeliveryContext();
  const [detailedOrder, setDetailedOrder] = useState<DeliveryOrder>();

  const onCloseDetails = () => setDetailedOrder(undefined);

  return (
    <TabPanel>
      <PaymentDetailsModal order={detailedOrder} onClose={onCloseDetails} />
      {orders.length ? (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr h="3.5rem">
                <Th whiteSpace="break-spaces">N°. do pedido</Th>
                <Th whiteSpace="break-spaces">Nome do cliente</Th>
                <Th>Telefone</Th>
                <Th whiteSpace="break-spaces">Data e hora</Th>
                <Th whiteSpace="break-spaces">Última atualização</Th>
                <Th isNumeric>Valor</Th>
                <Th>Origem</Th>
                <Th whiteSpace="break-spaces">Status do pedido</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order) => (
                <Tr key={order.id} h="3.5rem">
                  <Td>{order.id}</Td>
                  <Td whiteSpace="break-spaces">{order.customerName}</Td>
                  <Td whiteSpace="break-spaces">{order.customerPhone}</Td>
                  <Td whiteSpace="break-spaces">
                    {order.createdAt.format("DD/MM/YYYY hh:mm")}
                  </Td>
                  <Td whiteSpace="break-spaces">
                    {order.updatedAt.format("DD/MM/YYYY hh:mm")}
                  </Td>
                  <Td isNumeric>
                    <Button
                      onClick={() => setDetailedOrder(order)}
                      size="sm"
                      variant="link"
                      rightIcon={<TbListDetails />}
                    >
                      {R$(order.price)?.replace(" ", "")}
                    </Button>
                  </Td>
                  <Td>{order.origin}</Td>
                  <Td isNumeric>
                    <Menu matchWidth>
                      <MenuButton w="100%">
                        <Tag
                          size="sm"
                          colorScheme={
                            order.status === "FINISHED"
                              ? "green"
                              : order.status === "CANCELLED"
                              ? "red"
                              : "yellow"
                          }
                        >
                          <TagLabel>{statusLabels[order.status]}</TagLabel>
                          <TagRightIcon as={FaChevronDown} />
                        </Tag>
                      </MenuButton>
                      <MenuList minW="0">
                        {Object.keys(statusLabels).map(
                          (status) =>
                            order.status !== status && (
                              <MenuItem
                                key={status}
                                onClick={() =>
                                  changeOrderStatus(
                                    order,
                                    status as DeliveryOrderStatus
                                  )
                                }
                              >
                                {statusLabels[status]}
                              </MenuItem>
                            )
                        )}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text>Nenhum pedido encontrado.</Text>
      )}
    </TabPanel>
  );
};

export default OrderHistory;
