import {
  Box,
  DrawerHeader,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useBreakpointValue,
  useSteps,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useContractModalContext } from "../ModalContext";

const ContractModalHeader = () => {
  const { step, contract, loading, setStep } = useContractModalContext();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: contract ? 2 : 3,
  });
  const orientation = useBreakpointValue({
    base: "vertical",
    sm: contract ? "horizontal" : "vertical",
    md: "horizontal",
  }) as "vertical" | "horizontal";

  const onClickServices = () => step > 0 && setStep(0);
  const onClickPaymentDetails = () => step > 1 && setStep(1);

  useEffect(() => {
    setActiveStep(loading ? 3 : step);
  }, [step, setActiveStep, loading]);

  return (
    <DrawerHeader>
      <Stepper index={activeStep} mt="1.5rem" orientation={orientation}>
        <Step>
          <StepIndicator
            cursor={step > 0 ? "pointer" : "default"}
            onClick={onClickServices}
          >
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box
            flexShrink="0"
            cursor={step > 0 ? "pointer" : "default"}
            onClick={onClickServices}
          >
            <StepTitle>Adicionar serviço</StepTitle>
            <StepDescription>Selecione os produtos</StepDescription>
          </Box>
          <StepSeparator />
        </Step>

        {!contract && (
          <Step>
            <StepIndicator
              cursor={step > 1 ? "pointer" : "default"}
              onClick={onClickPaymentDetails}
            >
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box
              flexShrink="0"
              cursor={step > 1 ? "pointer" : "default"}
              onClick={onClickPaymentDetails}
            >
              <StepTitle>Detalhes do pagamento</StepTitle>
              <StepDescription>Detalhes do contrato</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        )}

        <Step>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>
              {!contract ? "Detalhes do contrato" : "Detalhes do serviço"}
            </StepTitle>
            <StepDescription>Confirme as informações</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      </Stepper>
    </DrawerHeader>
  );
};

export default ContractModalHeader;
