import { AccessLevel, AccessLevelType } from "shared/types/accessLevel";

export interface IAppUser {
  idi: number;
  codigo: number;
  nome: string;
  sobrenome?: string;
  login: string;
  token: string;
  nivel_acesso: AccessLevel;
  usuario_del: number;
  timezone: string;
}

export class AppUser {
  idi: number;
  code: number;
  name: string;
  lastname?: string;
  login: string;
  token: string;
  accessLevel: AccessLevel;
  userDel: number;
  timezone: string;

  constructor(data: IAppUser) {
    this.idi = data.idi;
    this.code = data.codigo;
    this.name = data.nome;
    this.lastname = data.sobrenome;
    this.login = data.login;
    this.token = data.token;
    this.accessLevel = data.nivel_acesso;
    this.userDel = data.usuario_del;
    this.timezone = data.timezone;
  }

  get accessLevelLabel(): string {
    if (!this.accessLevel.Valid) return AccessLevelType[0];
    return AccessLevelType[this.accessLevel.Int32];
  }

  get fullName(): string {
    return this.lastname ? `${this.name} ${this.lastname}` : this.name;
  }

  get originalPayload(): IAppUser {
    return {
      idi: this.idi,
      codigo: this.code,
      nome: this.name,
      sobrenome: this.lastname,
      login: this.login,
      token: this.token,
      nivel_acesso: this.accessLevel,
      usuario_del: this.userDel,
      timezone: this.timezone,
    };
  }
}
