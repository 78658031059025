import {
  Button,
  chakra,
  Fade,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  useDisclosure,
} from "@chakra-ui/react";
import { FaRegUserCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";

import { yoogaPNG } from "assets/images";
import { useGlobalContext } from "GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "shared/hooks/useLogout";

import { FiMenu } from "react-icons/fi";
import packageJSON from "../../../package.json";
import ColorModeSwitcher from "./ColorModeSwitcher";
import FeatureHistory from "./FeatureHistory";
import Instabiility from "./Instabiility";
import MobileSidebar from "./MobileSidebar";

const Header = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const { loggedUser, setCreateCustomerModalOpen } = useGlobalContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const location = useLocation();

  return (
    <chakra.header w="100vw" display="flex" alignItems="center" p="1rem 2rem" zIndex="10">
      <Image
        src={yoogaPNG}
        w={{ base: "40vw", md: "10rem" }}
        mr="auto"
        onClick={() => navigate("/")}
        cursor="pointer"
      />

      <Show below="md">
        <IconButton
          variant="outlined"
          aria-label="Barra de navegação"
          icon={<FiMenu />}
          onClick={onOpen}
        />

        <MobileSidebar isOpen={isOpen} onClose={onClose} />
      </Show>

      <Show above="md">
        <Fade in={location.pathname === "/"} unmountOnExit>
          <Button
            variant="outlined"
            size="sm"
            mr="2rem"
            leftIcon={<FaPlus />}
            onClick={() => setCreateCustomerModalOpen(true)}
          >
            Novo Cliente
          </Button>
        </Fade>

        <Instabiility />

        <FeatureHistory />

        <ColorModeSwitcher />

        <Menu>
          <MenuButton variant="ghost" as={Button} leftIcon={<FaRegUserCircle />}>
            Olá, {loggedUser?.name ?? "usuário"}
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<IoMdHelpCircleOutline />}
              as={Link}
              isExternal
              href="https://www.notion.so/855bdad3f664488f9943847fe2e8a7dc?v=627fecec0b0345d6ad93149513c4a7bf"
            >
              Ajuda
            </MenuItem>
            <MenuItem icon={<IoLogOutOutline />} onClick={logout}>
              Sair
            </MenuItem>
            <MenuItem isDisabled cursor="default !important">
              {packageJSON.version}
            </MenuItem>
          </MenuList>
        </Menu>
      </Show>
    </chakra.header>
  );
};

export default Header;
