export enum PaymentTag {
  CREDIT_CARD = "credit-card",
  INVOICE = "invoice",
}

export const PaymentMethods = {
  1: "Cartão de Crédito",
  2: "Boleto Bancário",
  3: "Marvin",
};
