import { Driver, IMarketplace } from "shared/types/marketplace";

export class Marketplace {
  marketplace: IMarketplace;
  drivers: Driver[];

  constructor(marketplace: IMarketplace) {
    this.marketplace = marketplace;
    this.drivers = marketplace.drivers;
  }
}
