export const PAYMENT_TYPE = {
  UM_REAL: {
    driver: "ITAU",
    method: "PIX",
    feeValue: 0,
    feeType: "VALUE",
    transactionFeeValue: 1,
    transactionFeeType: "VALUE",
  },
  NOVENTA_CENTAVOS: {
    driver: "ITAU",
    method: "PIX",
    feeValue: 0,
    feeType: "VALUE",
    transactionFeeValue: 0.9,
    transactionFeeType: "VALUE",
  },
  SESSENTA_CENTAVOS: {
    driver: "ITAU",
    method: "PIX",
    feeValue: 0,
    feeType: "VALUE",
    transactionFeeValue: 0.6,
    transactionFeeType: "VALUE",
  },
  VINTE_CENTAVOS: {
    driver: "ITAU",
    method: "PIX",
    feeValue: 1.67,
    feeType: "PERCENTAGE",
    transactionFeeValue: 0.2,
    transactionFeeType: "VALUE",
  },
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PAYMENT_TYPE = (typeof PAYMENT_TYPE)[keyof typeof PAYMENT_TYPE];

export interface IMarketplacePayment {
  customerId: number;
  idi: number;
  marketplaceFees: PAYMENT_TYPE[];
}
