import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { IoCloseCircle, IoSearchOutline } from "react-icons/io5";
import { InstallationLogType } from "shared/types/installationLog";

interface FiltersHeaderProps {
  search: string;
  setSearch(s: string): void;
  type?: InstallationLogType;
  setType(c?: InstallationLogType): void;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = ({
  search,
  setSearch,
  type,
  setType: setDomainFromProps,
}) => {
  const setDomain = (d?: InstallationLogType) => {
    if (!d || d === type) return setDomainFromProps(undefined);
    setDomainFromProps(d);
  };

  return (
    <Flex direction="column" gap="1rem">
      <InputGroup maxW="40em">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Pesquisar pela descrição..."
        />
        <InputRightElement>
          {!search ? (
            <Icon aria-label="pesquisar" as={IoSearchOutline} />
          ) : (
            <IconButton
              aria-label="Limpar busca"
              variant="link"
              icon={<IoCloseCircle />}
              onClick={() => setSearch("")}
            />
          )}
        </InputRightElement>
      </InputGroup>

      <HStack flexWrap={{ base: "wrap", md: "nowrap" }}>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={!type ? "yooga" : "gray"}
          onClick={() => {
            setDomain();
          }}
        >
          Todos
        </Button>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={type === "Installation" ? "yooga" : "gray"}
          onClick={() => {
            setDomain("Installation");
          }}
        >
          Instalação
        </Button>
        <Button
          variant="outline"
          fontWeight="400"
          colorScheme={type === "Order" ? "yooga" : "gray"}
          onClick={() => {
            setDomain("Order");
          }}
        >
          Pedido do delivery
        </Button>
      </HStack>
    </Flex>
  );
};

export default FiltersHeader;
