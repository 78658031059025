import { IInstallationLog, InstallationLogType } from "shared/types/installationLog";

export default function filterLogs(
  logs: IInstallationLog[],
  search?: string,
  type?: InstallationLogType
): IInstallationLog[] {

  if (!!search || type) {
    logs = logs.filter((log) => {
      if (!!type && log.type !== type) return false;

      if (
        !!search &&
        !log.action?.toLowerCase().includes(search.toLowerCase()) &&
        !log.description.toLowerCase().includes(search.toLowerCase())
      )
        return false;

      return true;
    });
  }

  return logs;
}
