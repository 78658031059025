import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createPartial, searchCustomers } from "services/customer";
import { validateCNPJ, validateCPF } from "shared/helpers/document";
import { onlyNumbers } from "shared/helpers/string";

interface CreatePartialCustomerContextdata {
  name: string;
  telephone: string;
  cnpj: string;
  contact_email: string;
  hs_deal_id: string;
  yooga_customer_id: string;
  initializing: boolean;
  loading: boolean;
  submit(): void;
}

const Context = createContext<CreatePartialCustomerContextdata>(
  {} as CreatePartialCustomerContextdata
);

export const useCreatePartialCustomerContext = () => useContext(Context);

const CreatePartialCustomerContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [name] = useState(searchParams.get("name") || "");
  const [telephone] = useState(searchParams.get("phone") || "");
  const [cnpj] = useState(onlyNumbers(searchParams.get("doc") || ""));
  const [contact_email] = useState(searchParams.get("email") || "");
  const [hs_deal_id] = useState(searchParams.get("hs_deal_id") || "");
  const [yooga_customer_id] = useState(searchParams.get("uuid") || "");
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);

  const search = useCallback(() => {
    if (!cnpj || (!validateCPF(cnpj) && !validateCNPJ(cnpj))) {
      toast.error("O documento preenchido não é válido. Ajuste no CRM.");
      return navigate("/");
    }

    searchCustomers(cnpj)
      .then((response) => {
        if (response.data.some((customer) => customer.cnpj === cnpj)) {
          navigate(`/customer/${cnpj}`);
          toast.warning("Cliente já cadastrado");
        }
      })
      .catch(() => {
        toast.error("Ocorreu um erro ao pesquisar o cliente");
        navigate("/");
      })
      .finally(() => {
        setInitializing(false);
      });
  }, [cnpj, navigate]);

  const submit = useCallback(() => {
    createPartial({
      cnpj,
      contact_email,
      hs_deal_id,
      yooga_customer_id,
      telephone,
      name,
    })
      .then(() => {
        toast.success("Cliente cadastrado com sucesso");
        navigate(`/customer/${cnpj}`);
      })
      .catch(() => {
        toast.error("Ocorreu um erro ao cadastrar o cliente");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cnpj, contact_email, hs_deal_id, yooga_customer_id, telephone, name, navigate]);

  useEffect(() => {
    search();
  }, [search]);

  return (
    <Context.Provider
      value={{
        name,
        telephone,
        cnpj,
        contact_email,
        hs_deal_id,
        yooga_customer_id,
        initializing,
        loading,
        submit,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default CreatePartialCustomerContext;
