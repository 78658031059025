import { IInstallationLog, IPages } from "shared/types/installationLog";

export interface IInstallationLogResponse {
  logs: IInstallationLog[];
  installation_pages: IPages;
  order_pages: IPages;
}

export class InstallationLog {
  logs: IInstallationLog[];
  installation_pages: IPages;
  order_pages: IPages;
  last_page: number;

  constructor(data: IInstallationLogResponse) {
    this.logs = data.logs;
    this.installation_pages = data.installation_pages;
    this.order_pages = data.order_pages;

    this.last_page =
      data.installation_pages.lastPage > data.order_pages.lastPage
        ? data.installation_pages.lastPage
        : data.order_pages.lastPage;
  }
}
